import { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import { ClienteContext } from "../../../Context/ClienteContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import ComponentPagination from "../../Pagination";
import Produto from "../../../shared/Types/Produto";
import Button from "react-bootstrap/esm/Button";
import { VendaContext } from "../../../Context/VendaContext";
import { useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';

interface Props {
    produtos: Produto[]
    roteDetalhar: string
}


const TableProdutosMain = ({produtos, roteDetalhar}: Props) => {


    const [dataTable, setDataTable] = useState<Produto[]>([]);
    const {setDadosProdutoServico} = useContext(VendaContext)

    const { currentPage} = useContext(ClienteContext);

    const navigate = useNavigate();


    useEffect(() => {
        setDataTable(produtos)
}, [produtos])

function RedirectTo(item:Produto, rote:string) {
    setDadosProdutoServico(item)
    return navigate(rote);
}


    return (
        <>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Descrição Mov.</th>
                        <th>Valor un.</th>
                        <th>Qtdade Estoque</th>
                        <th>Tipo</th>

                        <th></th>
                    </tr>
                </thead>


                <tbody>
                    {dataTable?.map((item: Produto) => ( //usar index prejudica performance
                        <tr key={item.id}>

                            <td>{item?.id}</td>
                            <td>{item?.descricao}</td>
                            <td>R$ {item?.valorUnitario}</td>
                            <td>{item?.qtdadeEstoque}</td>
                            <td>{item?.tipo}</td>
                            <td><Button variant="secondary" size="sm" onClick={(event) => RedirectTo(item,roteDetalhar )}>+</Button></td>
                        


                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <ComponentPagination />
        </>
    )
}

export default TableProdutosMain