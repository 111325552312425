import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';


const api = axios.create({
    baseURL: 'http://localhost:4001' //no servidor tem que ser na 3000
});

export default api;






