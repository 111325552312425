import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useForm } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import Button from "react-bootstrap/esm/Button";
import styles from "./FormNovoServicoRampa.module.css";
import { useNavigate } from 'react-router-dom';


const FormNovoServicoRampa = () => {

    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [resultadoPesquisa, setResultadoPesquisa] = useState([]);
    const [proprietarioInfos, setProprietarioInfos] = useState({});
    const [embsSelecionadas, setEmbsSelecionadas] = useState([0]);
    const [embSelecionada, setEmbSelecionada] = useState({});
    const [modeloEmbSelecionado, setModeloEmbSelecionado] = useState("");
    const navigate = useNavigate();


    async function pesquisaProprietarios(data) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/listarProprietarios', newData)
            setResultadoPesquisa(resultado.rows);
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map(item => ({ value: item?.id, label: item?.id + '-' +item?.nome }))
            return proprietariosFormatado;
        }
    }

    function listarEmbsDoCliente() {
        const clienteSelecionado = resultadoPesquisa.filter(item => item?.id === proprietarioInfos.value)
        setEmbsSelecionadas(clienteSelecionado[0]?.Embarcacoes);

    }

    useEffect(listarEmbsDoCliente, [proprietarioInfos, resultadoPesquisa]);

    function changeEmbSelect(newEmbNome) {
        const FiltraNovoModelo = embsSelecionadas?.filter((item) => item.nome.slice(0,newEmbNome.length) === newEmbNome);//[0]?.modeloCascoEmb;
        const novoModelo = FiltraNovoModelo[0].modeloCascoEmb;
        const idEmbSelecionado = Number(FiltraNovoModelo[0].id) //embsSelecionadas?.filter((item) => item.nome === newEmbNome)[0]?.id;
        setEmbSelecionada({nome:newEmbNome,id:Number(idEmbSelecionado)});
        setModeloEmbSelecionado(novoModelo)
    }


    async function salvarDados(data) {
        const dadosForm = {...data, embarcacaoId: Number(embSelecionada.id), clienteId: Number(proprietarioInfos.value)};

        const salvar = await RequisicaoPost('/criaNovaOS',dadosForm);

        if(salvar.status === "success"){
            navigate('/servicoRampa');
        }else{
            console.log('ERRO AO SALVAR OS');
        }

        

    }

    return (
        < div className={styles.formArea}>
            <Form onSubmit={handleSubmit(salvarDados)}>
                <section>
                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Tipo Serviço</Form.Label>
                        </Col>
                        <Col>
                            <Form.Select {...register("tipoServico")}>
                                <option key={11121}>Abastecer & Descer</option>
                                <option key={232}>Abastecer & Levar</option>
                                <option key={3434}>Descer</option>
                                <option key={4}>Levar</option>
                                <option key={5}>Manutenção</option>

                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Cliente:</Form.Label>
                        </Col>
                        <Col>
                            <AsyncSelect {...register("cliente")} placeholder={"Digite nome Cliente"} cacheOptions loadOptions={pesquisaProprietarios} defaultOptions onChange={(data) => { setProprietarioInfos(data) }}  ></AsyncSelect>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Nome Embarcação</Form.Label>
                        </Col>
                        <Col>
                            <Form.Select
                                {
                                ...register(
                                    "embarcacao",
                                    {
                                        required: true,
                                        validate: value => value !== "Selecione"
                                    },

                                )
                                }
                                onChange={(event) => changeEmbSelect(event.target.value)}
                            >
                                <option>Selecione</option>
                                {embsSelecionadas?.map(item => (
                                    <option key={item.id}>{item.nome}</option>
                                ))}
                            </Form.Select>
                            {errors !== undefined && errors?.embarcacao?.type === 'validate' && <p className={styles.formError}>Selecione uma Embarcação</p>}

                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Modelo Emb:</Form.Label>
                        </Col>

                        <Col>
                            <Form.Control {...register("modeloEmb")}  value={modeloEmbSelecionado}></Form.Control>
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Combustível:</Form.Label>
                        </Col>

                        <Col>
                            <Form.Control {...register("qtadeCombustivel")} defaultValue={0}></Form.Control>
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Observação:</Form.Label>
                        </Col>

                        <Col>
                            <Form.Control {...register("observacao")} as="textarea" rows={4} ></Form.Control>
                        </Col>

                    </Form.Group>

                    <Row>
                        <Button type="submit">Salvar</Button>
                    </Row>
                </section>
            </Form>

        </div>
    )
}

export default FormNovoServicoRampa;