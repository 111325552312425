import React, { useState, useContext } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import TableEmbarcacaoMain from "../../../components/Tables/TableEmbarcacoesMain";
import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useNavigate } from 'react-router-dom';
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import { EmbarcacaoContext } from "../../../Context/EmbarcacaoContext";
import { useForm } from 'react-hook-form';


const PageEmbarcacoesMain = () => {

    const { setDadosEmbarcacao } = useContext(EmbarcacaoContext);
    const [dataTable] = useState([]);

    console.log("dataTable = ", dataTable);
    

    const navigate = useNavigate();
    const {handleSubmit, register} = useForm();


   /* async function getAllEmbarcacoes() {
        const AllEmbarcacoes = await RequisicaoGet(`/listarEmbarcacoes/?page=${currentPage}&size=50`);
        console.log('All Embarcacoes = ', AllEmbarcacoes);
        const newData = AllEmbarcacoes;
        setDataTable(AllEmbarcacoes)
    }*/

    //useEffect(() => { getAllEmbarcacoes() }, [/*currentPage*/])
    

    /*async function searchEmbs(data) {
        const newData = { ...data, data }
        console.log('new data = ', newData);
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/listarEmbarcacoesPesquisadas', newData)
            const Embs = resultado.rows;
            setResultadoPesquisaEmb(Embs); //armazena todos os resultados da pesquisa da embarcacao e deixa disponivel para preencher o form detalhar emb de acordo com a emb selecionada
            console.log('EMBS = ', Embs);
            const EmbsFormatado = Embs.map(item => ({ value: item?.id, label: item?.nome }))
            return EmbsFormatado;
        }

    }

    function detalharEmbSelecionada(data) {
        console.log('detalhar Emb - ', data);
        const embId = Number(data.value);
        console.log('resultadoPesquisaEmb =', resultadoPesquisaEmb);

        const embSelecionada = resultadoPesquisaEmb.filter(item => (item.id === embId))
        console.log('selecionado = ', embSelecionada)
        setDadosEmbarcacao(...embSelecionada);
        navigate('/detalharEmbarcacao');
    }*/

    async function pesquisarEmbarcacoes(data){
        console.log('data form = ', data);
        const resultadoPesquisa = await RequisicaoPost('/pesquisarEmbarcacaoes', data);
        console.log('Resultado pesquisa embarcacao = ', resultadoPesquisa);
        if (resultadoPesquisa.status === "success" && resultadoPesquisa.resultado.count > 0 ){
            setDadosEmbarcacao(resultadoPesquisa.resultado.rows);
            navigate('/pageResultadoPesquisaEmbarcacao');
        }
    }

    return (
        <>
            <Header />
            <h1>Embarcações Main</h1>

            <Row>
                <Col>
                    <Button href="/PageNovaEmbarcacao">Nova Embarcacão</Button>
                </Col>
              
                <Form onSubmit={handleSubmit(pesquisarEmbarcacoes)}>
                    <Col>
                        <Row>
                            Pesquisar por:
                            <Col xs={2}>
                                <Form.Select {...register("formaPesquisa")}>
                                    <option>Id Embarcação</option>
                                    <option>Id Proprietário</option>
                                    <option>Nome Embarcação</option>
                                    <option>Nome Proprietário</option>
                                </Form.Select>
                            </Col>

                            <Col xs={2}>
                                <Form.Control {...register("infoParaPesquisa")}
                                    placeholder="Digite a Pesquisa"
                                >

                                </Form.Control>
                            </Col>

                            <Col>
                                <Button type="submit">Pesquisar</Button>
                            </Col>
                        </Row>
                    </Col>
                </Form>
            </Row>

            <TableEmbarcacaoMain
             roteDetalhar="/detalharEmbarcacao"
             dados={dataTable?.ListaEmbarcacoes?.rows}
             type="main"

            />
          
            <Footer />
        </>
    )
}

export default PageEmbarcacoesMain;