import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import React, { useEffect, useState, createContext, useContext } from "react";
import { ClienteContext } from "../../../Context/ClienteContext";
import { useNavigate } from 'react-router-dom';
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET";
import TableVeiculoMain from "../../../components/Tables/TableVeiculosMain";
import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useForm } from "react-hook-form";
import Alert from 'react-bootstrap/Alert';
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";

const PageVeiculos = () => {

    const { handleSubmit, register, formState: { errors }, } = useForm();
    const navigate = useNavigate();
    const { currentPage } = useContext(ClienteContext);
    const [data, setData] = useState([]);

    const getVeiculosAtivos = async () => {

        console.log('ENTROU GET VEICULOS !!!!');
        console.log('currentPage = ', currentPage);

        try {
            //const resultado = await RequisicaoPost('/authomaticRegisterAccess','EFZ8258');

            //const tabelaEntrada = await RequisicaoGet(`/getAllVeiculos/?page=${currentPage}&size=50`);
            const tabelaEntrada = await RequisicaoGet(`/getAllPlates`);
            console.log('tabelaEntrada = ', tabelaEntrada);
            //setData(tabelaEntrada.result);
        } catch (err) {
            if (err.response) {
                if (err.response.status === 401) {
                    return navigate("/");
                }
            }
        }
    }



    useEffect(() => {
        getVeiculosAtivos();
    }, []);

    async function PesquisarVeiculo(data) {
        let placa = data.dadosPesquisa;
        console.log('placa = ', placa);
        const resultado = await RequisicaoPost('/authomaticRegisterAccess',{placa:placa});
        //const tabelaEntrada = await RequisicaoGet(`/getAllVeiculos/?page=${currentPage}&size=50`);
        
        console.log(resultado);
    }

    return (

        <>
            <Header />
            <h2>Veículos Cadastrados</h2>
            <hr />
            <Form onSubmit={handleSubmit(PesquisarVeiculo)}>
                <Form.Group as={Row} >

                    <Col sm={4}>
                        <Form.Control {...register("dadosPesquisa", { required: true, validate: value => value.length > 2 })} placeholder="Digite Placa" ></Form.Control>
                    </Col>
                    <Col>
                        <Button type="submit">Pesquisar</Button>
                    </Col>
                    {errors?.dadosPesquisa?.type === "required" ? <Alert variant={"danger"}>Campo obrigatório</Alert> : ""}
                    {errors?.dadosPesquisa?.type === "validate" ? <Alert variant={"danger"}>Digite 3 caracteres ou mais</Alert> : ""}
                </Form.Group>
            </Form>

            <hr />

            <Button onClick={() => navigate("/novoVeiculo")}>Novo Veiculo</Button>

            <TableVeiculoMain
                dados={data}
                roteDetalhar="/detalharVeiculo"
            >

            </TableVeiculoMain>


            <Footer />
        </>
    )
}

export default PageVeiculos;