import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import TableServicoRampa from "../../../components/Tables/TableServicoRampa";

const PageServicosMain = () => {


    return (
        <>
            <Header/>
            <Footer/>

        </>
    )
}

export default PageServicosMain