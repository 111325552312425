import React, {useContext} from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormEditarServicoRampa from "../../../components/Forms/Servicos/FormEditarServicoRampa";



const PageEditarServicoRampa = () => {



    return (
        <>
        <Header/>
        <FormEditarServicoRampa
            typeForm = "editar"
        />
        <Footer/>
        </>
    )
}

export default PageEditarServicoRampa;