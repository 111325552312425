import React, { useContext, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import FormNovoCliente from "../FormNovoCliente";
import { verificaCPF } from "../../../../services/validation";
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styles from './FormEditarCliente.module.css';
import Alert from 'react-bootstrap/Alert';
import { ClienteContext } from "../../../../Context/ClienteContext";
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import { useNavigate } from "react-router-dom";

const FormEditarCliente = () => {

    const { dadosCliente, setDadosCliente } = useContext(ClienteContext);
    const { register, getFieldState,  handleSubmit, formState: { isDirty, errors } } = useForm();
    const navigate = useNavigate()    


    const fieldState = getFieldState("NomeCliente");
    console.log('FIELD STATE = ', fieldState);


    var isReadyOnly = [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];


    console.log('erros validacao form editarCliente = ', errors);


    async function atualizaDados(data) {
        const dadosAtualizados = data;
        const StatusServidor = await RequisicaoPost('/atualizarCliente', dadosAtualizados);
        if(StatusServidor.status === "success") {
            navigate("/pageClientesMain")

        }

    }

    return (


        < div className={styles.formArea}>


            <Form noValidate validated={false} onSubmit={handleSubmit(atualizaDados)}>
                {errors.cpf?.type === 'validate' && <Alert className="Alert" key={'danger1'} variant={'danger'}>CPF Inválido</Alert>}
                {errors.TipoCliente?.type === 'validate' && <Alert className="Alert" key={'danger1'} variant={'danger'}>Selecione Tipo Cliente</Alert>}
                
                <section>
                    <Row className="mb-3" >
                        <Form.Group as={Col}>
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>
                                    Código:
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control  {...register("id", { required: true })} defaultValue={dadosCliente?.id} readOnly={true} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Group as={Row}>
                                <Form.Label column sm={1}>
                                    CPF:
                                </Form.Label>
                                <Col sm={9}>
                                    <Form.Control  {...register("cpf", { validate: value => verificaCPF(value) })} defaultValue={dadosCliente?.cpf} readOnly={isReadyOnly[1]} className={isReadyOnly[1] === 1 ? styles.noEditable : ``} />
                                </Col>
                            </Form.Group>
                        </Form.Group>
                    </Row>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={1}>
                            Status:
                        </Form.Label>
                        <Col xs={7}>
                            <Form.Select  {...register("status", { required: true })} defaultValue={dadosCliente?.status} readOnly={isReadyOnly[2]} className={isReadyOnly[2] === 1 ? styles.noEditable : ``}>
                                <option>Ativo</option>
                                <option>Inativo</option>
                               
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={1}>
                            Tipo:
                        </Form.Label>
                        <Col xs={7}>
                            <Form.Select  {...register("tipoCliente", { validate: value => value !== "Selecione" , required: true })} defaultValue={dadosCliente?.tipoCliente} readOnly={isReadyOnly[2]} className={isReadyOnly[2] === 1 ? styles.noEditable : ``}>
                                <option>Selecione</option>
                                <option>Aluno Wake</option>
                                <option>Convidado Mensalista</option>
                                <option>Curso Arrais</option>
                                <option>Descida Avulsa</option>
                                <option>Escritório</option>
                                <option>Mensalista</option>
                                <option>Restaurante</option>
                                <option>Visitante</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={1}>
                            Nome:
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control  {...register("nome", { required: true })} defaultValue={dadosCliente?.nome} readOnly={isReadyOnly[3]} className={isReadyOnly[3] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column="sm" lg={1}>
                            Endereço:
                        </Form.Label>
                        <Col xs={10}>
                            <Form.Control  {...register("endereco")} defaultValue={dadosCliente?.endereco} readOnly={isReadyOnly[4]} className={isReadyOnly[4] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Group as={Row}  >
                                <Form.Label column sm={2}>
                                    Bairro:
                                </Form.Label>
                                <Col xs={8}>
                                    <Form.Control  {...register("bairro")} defaultValue={dadosCliente?.bairro} readOnly={isReadyOnly[5]} className={isReadyOnly[5] === 1 ? styles.noEditable : ``}></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Group as={Row} >
                                <Form.Label column sm={1}>
                                    CEP:
                                </Form.Label>
                                <Col xs={9}>
                                    <Form.Control  {...register("cep")} defaultValue={dadosCliente?.cep} readOnly={isReadyOnly[6]} className={isReadyOnly[6] === 1 ? styles.noEditable : ``} />
                                </Col>
                            </Form.Group>
                        </Form.Group>
                    </Row>

                    <Form.Group as={Row} className="mb-3" >
                        <Form.Label column sm={1}>
                            Cidade:
                        </Form.Label>

                        <Col xs={7}>
                            <Form.Control  {...register("cidade")} defaultValue={dadosCliente?.cidade} readOnly={isReadyOnly[7]} className={isReadyOnly[7] === 1 ? styles.noEditable : ``} />
                        </Col>
                    </Form.Group>

                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Group as={Row} >
                                <Form.Label column sm={3}>
                                    Telefone:
                                </Form.Label>
                                <Col xs={8}>
                                    <Form.Control  {...register("telefone")} defaultValue={dadosCliente?.telefone} readOnly={isReadyOnly[8]} className={isReadyOnly[8] === 1 ? styles.noEditable : ``}></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Group as={Row} >
                                <Form.Label column sm={2}>
                                    Celular:
                                </Form.Label>
                                <Col xs={8}>
                                    <Form.Control  {...register("celular")} defaultValue={dadosCliente?.celular} readOnly={isReadyOnly[9]} className={isReadyOnly[9] === 1 ? styles.noEditable : ``}></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form.Group>
                    </Row>


                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={1}>
                            Email:
                        </Form.Label>
                        <Col>
                            <Form.Control  {...register("email")} defaultValue={dadosCliente?.email} readOnly={isReadyOnly[10]} className={isReadyOnly[10] === 1 ? styles.noEditable : ``}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Button type="submit">Salvar Alterações</Button>
                </section >
            </Form>
            <br/>
            <br/>

        </div>




    )
}

export default FormEditarCliente;