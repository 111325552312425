import Table from 'react-bootstrap/Table';
import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';
import { PortariaContext } from '../../../Context/PortariaContext';
import { ClienteContext } from '../../../Context/ClienteContext'
import ModalAviso from "../../../components/Modals";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";


const TableEntradasMain = (props) => {
    const navigate = useNavigate();

    const { placa, setPlaca, dadosEntrada, setDadosEntrada, IdParaExclusao, setIdParaExclusao /*, statusModal, setStatusModal*/ } = useContext(PortariaContext);
    const { currentPage, globalMsg, setGlobalMsg } = useContext(ClienteContext);

    const [statusModal,setStatusModal] = useState(false)

    console.log('props.data = ', props.dadosTabela);


    const redirectToEdit = (entradaId, { ...parametros }) => {
        console.log('parametros = ', parametros);
        setDadosEntrada(parametros);

        console.log('dados Entrada = ', dadosEntrada);

        if (dadosEntrada !== undefined && Object.keys(dadosEntrada).length > 0) {
            console.log('entrou if');
            return navigate(`/portariaEditarEntrada/${entradaId}`); //esse redirect nao permite setar os dados em setDadosEntrada

        }
    }

    function abrirModalConfirmacao(entradaId) { //abrir modal de confirmacao
        console.log('Entrou excluir entrada');
        setIdParaExclusao(entradaId)
        setStatusModal(true);
        console.log('continuou exclusao');
    }

    function fecharModal() {
        console.log('ENTROU FECHAR MODAL');
        setStatusModal(false);
    }

    async function efetuarExclusao() {
        const excluirEntrada = await RequisicaoPost('/excluirEntrada', { id: Number(IdParaExclusao) }); // se enviar o obj entrada inteiro com todas as infos da entrada da erro da FK quando for excluir- nao entendi

        setStatusModal(false);

        console.log('status exclusao = ', excluirEntrada);
        if (excluirEntrada.status === "success") {
            //getEntradas();
            setGlobalMsg([true, "Tudo certo !", "Registro deletado com sucesso", "success"]);
            return navigate('/portariaMain');

        } else {
            setGlobalMsg([true, "Opa, algo deu errado :(", "Não foi possivel realizar a operação", "danger"])
        }
    }



    return (
        <div>
            <ModalAviso
                titulo='Confirmar Exclusão'
                texto="Tem certeza que de deseja excluir esse registro ?"
                obs='Essa ação não poderá ser desfeita'
                statusModal={statusModal}
                onRequestClose={fecharModal}
                aoConfirmar={efetuarExclusao}
            ></ModalAviso>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Hora</th>
                        <th>Placa</th>
                        <th>Tipo</th>
                        <th>ClienteId</th>
                        <th>Nome</th>
                        <th>Embarcação</th>
                        <th>Veículo</th>
                        <th>Valor</th>
                        {/*<th>Forma Pgto</th>*/}
                        <th>-</th>

                    </tr>
                </thead>
                <tbody>

                    {
                        props?.dadosTabela?.map((Entrada, index) => (
                            <tr key={Entrada.id}>

                                <td>{Entrada?.data}</td>
                                <td>{Entrada?.hora}</td>
                                <td>{Entrada?.Veiculo?.placa}</td>
                                <td>{Entrada?.tipoEntrada}</td>
                                <td>{Entrada?.Cliente?.id}</td>
                                <td>{Entrada?.Cliente?.nome}</td>
                                <td>{Entrada?.Cliente?.Embarcacoes[0]?.nome}</td>
                                <td>{Entrada?.Veiculo?.modelo}</td>
                                <td>R$ {Entrada?.Pagamento?.valor}</td>
                                {/*<td>{Entrada.Pagamento.formaPgto}</td>*/}
                                <td>
                                    <Button
                                        size="sm"
                                        variant="secondary"
                                        onClick={(event) => redirectToEdit(
                                            Entrada.id,
                                            {
                                                entrada: Entrada.id,
                                                placa: Entrada.Veiculo.placa,
                                                tipo: Entrada.tipoEntrada,
                                                nome: Entrada.Cliente.nome,
                                                embarcacao: Entrada.Cliente.Embarcacoes[0]?.nome,
                                                veiculo: Entrada.Veiculo.modelo,
                                                valor: Entrada.Pagamento.valor,
                                                formaPgto: Entrada.Pagamento.formaPgto,
                                                observacao: Entrada.observacao,
                                                pagamentoId: Entrada.Pagamento.id
                                            }
                                        )}


                                    >
                                        Editar
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        onClick={() => abrirModalConfirmacao(Entrada.id)}
                                    >
                                        Excluir
                                    </Button>
                                </td>
                            </tr>
                        ))}



                </tbody>


            </Table>
        </div>
    )
}

export default TableEntradasMain;