import React from "react";
import { Route, Routes } from 'react-router-dom';
import PageServicosMain from "../pages/Servicos/PageServicosMain";
import PageServicoRampa from "../pages/Servicos/PageServicoRampa";
import PageNovoServico from "../pages/Servicos/PageNovoServico";
import PageEditarServicoRampa from "../pages/Servicos/PageEditarServicoRampa";
import PageDetalharServicoRampa from "../pages/Servicos/PageDetalharServicoRampa";



export default function ServicosRoutes () {
    return (
        <>
            <Routes>
                <Route path="/servicosMain" element={<PageServicosMain/>}/>
                <Route path="/servicoRampa" element={<PageServicoRampa/>}/>
                <Route path="/novoServicoRampa" element={<PageNovoServico/>}/>
                <Route path="/pageEditarServicoRampa" element={<PageEditarServicoRampa/>}/>
                <Route path="/pageDetalharServicoRampa" element={<PageDetalharServicoRampa/>}/>

            </Routes>

        </>
    )
}