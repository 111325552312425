import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FormNovoProdutoServico from "../../../../components/Forms/Vendas/FormNovoProdutoServico/FormNovoProdutoServico";
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import { useNavigate } from 'react-router-dom';



const PageNovoProdutoServico = () => {

    const navigate = useNavigate();

    async function salvarDados(produto:any){
        console.log('produto =', produto);

        const requisicao = await RequisicaoPost('/novoProduto',produto);
        console.log('salvarProduto = ', requisicao);

        if(requisicao.status === 'success'){
            navigate('/produtosServicosMain');
        }else{
            alert("Ocorreu um erro !")
            return;
        }
    }

    return (
        <>
            <Header/>
            <h1>Novo Produto/Serviço</h1>

            <FormNovoProdutoServico
                formType="new"
                submitFunction={salvarDados}

                />
            <Footer/>
        
        </>
    )
}

export default PageNovoProdutoServico