import React from "react";

import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormNovoServicoRampa from "../../../components/Forms/Servicos/FormNovoServicoRampa";


const PageNovoServico = () => {
    return (
        <>
            <Header/>
            <h1>Nova Ordem de Serviço</h1>

            <FormNovoServicoRampa/>

            <Footer/>
        </>
    )
}


export default PageNovoServico;