import { useContext } from "react";
import Footer from "../../../../components/Footer"
import Header from "../../../../components/Header"
import { VendaContext } from "../../../../Context/VendaContext";
import FormEditVenda from "../../../../components/Forms/Vendas/FormEditVenda";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RequisicaoDelete from "../../../../config/requisicoes/requisicaoDelete";

const PageDetalharVenda = () => {
    const navigate = useNavigate();
    function editVenda() {
        navigate('/editarVenda');
    }
    const {dadosVenda} = useContext(VendaContext) 
    console.log('vendas = ' , dadosVenda);
    
    async function excluirVenda(vendaId:number){
        const deleta = await RequisicaoDelete(`/venda/${vendaId}`)
        
        if(deleta.status === 'success'){
            navigate('/vendasMain')
        }else{
            console.log('ERRO')
        }
    }
    
    
    return(
        <>
            <Header/>
            <h1> Page Detalhar Venda</h1>
            <Button
                onClick={editVenda}
            >
                Editar Venda
            </Button>
            <Button
                variant="danger"
                onClick={() => window.confirm('Deseja realmente excluir esta Venda ?') && excluirVenda(dadosVenda?.id)}
            >
                Excluir Venda
            </Button>

            <FormEditVenda
                vendas={dadosVenda}
                formType="detail"
            />
            <Footer/>
        </>
    )
}

export default PageDetalharVenda;