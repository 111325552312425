import React, {useState, useContext,useEffect} from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { Button } from "react-bootstrap";
import RequisicaoGet from "../../../../config/requisicoes/requisicaoGET";
import Produto from "../../../../shared/Types/Produto";
import { ClienteContext } from "../../../../Context/ClienteContext";
import TableProdutosMain from "../../../../components/Tables/TableProdutosMain";
import { VendaContext } from "../../../../Context/VendaContext";

const PageProdutosServicosMain = () => {
//

    const [dataTable, setDataTable] = useState<Produto[]>([]);
    const { dadosVenda} = useContext(VendaContext);

    console.log(dadosVenda);

    async function getAllProdutos(){
        const produtos = await RequisicaoGet(`/Produtos`);
        console.log('produtos =', produtos);
        setDataTable(produtos.produtos.rows);
        console.log(`dataTable = `,dataTable);
    }

    useEffect(() => {getAllProdutos()},[]);


    return (
        <>
            <Header/>
                <h1>Produtos & Serviços</h1>

                <Button href="/novoProdutoServico">Novo Produto/Serviço</Button>

                <TableProdutosMain
                    produtos={dataTable}
                    roteDetalhar="/detalharProdutoServico"
                />

            <Footer/>

        </>
    )
}

export default PageProdutosServicosMain