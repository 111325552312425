import React, { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET"
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';
import { ClienteContext } from "../../../Context/ClienteContext";  


const TableVeiculoMain = (props) => {

    console.log('PROPS = ', props);
    const [dados, setDados] = useState([]);
    const {setDadosCliente} = useContext(ClienteContext)
    const navigate = useNavigate();


    function RedirectTo(paramters , rote){
        const newDataClient = {...paramters, isReadOnly : 1};    
        setDadosCliente(newDataClient);
        return navigate(rote);
    }

    return (

        <> 
        <hr/>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Código Veículo</th>
                        <th>Placa</th>
                        <th>Id - Proprietário</th>
                        <th>Modelo</th>
                        <th>Status</th>
                    </tr>
                </thead>

                <tbody>
                    {props.dados?.map((item, index) => (
                        <tr key={index}>
                            <td>{item?.id}</td>
                            <td>{item?.placa}</td>
                            <td>{`${item?.Cliente?.id}-${item?.Cliente?.nome}`}</td>
                            <td>{item?.modelo}</td>
                            <td>{item?.status}</td>
                            <td><Button  variant="secondary" size="sm"  onClick={(event) => RedirectTo(item,props.roteDetalhar)}>+</Button></td>

                        </tr>
                    ))
                    }
                </tbody>
            </Table>
        </>
    )


}

export default TableVeiculoMain;