import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ComponentPagination from "../../../components/Pagination";
import styles from './portariaMain.module.css'
import { PortariaContext } from "../../../Context/PortariaContext";
import { ClienteContext } from "../../../Context/ClienteContext";
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import { useForm } from 'react-hook-form';
import TableEntradasMain from "../../../components/Tables/TableEntradasMain";
import Button from "react-bootstrap/esm/Button";
import ModalAviso from "../../../components/Modals";


const PortariaMain = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [msgUsuario, setMsgUsuario] = useState('');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { placa, setPlaca, dadosEntrada, setDadosEntrada/*, statusModal, setStatusModal*/ } = useContext(PortariaContext);
    const { currentPage } = useContext(ClienteContext);

    const [isOpen, setIsOpen]= useState(true);
    const [isClosed,setIsClosed] = useState(false)

    const [statusModal,setStatusModal] = useState(false)

    const getEntradas = async () => {
        try {
            const tabelaEntrada = await RequisicaoGet(`/getAllEntradas/?page=${currentPage}&size=50`);
            const tabela = tabelaEntrada?.rows;
            setData(tabela);
        } catch (err) {
            if (err.response) {
                if (err.response.status === 401) {
                    return navigate("/");
                }
            }
        }
    }

    useEffect(() => {
        getEntradas();
    }, [currentPage]);

    function isPlateCorrect(inputPlate) {
        const regexOldFormat = /^[a-zA-Z]{3}[0-9]{4}$/;
        const regexNewFormat = /^[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/;

        if (inputPlate.match(regexOldFormat) || inputPlate.match(regexNewFormat)) {
            return true;
        } else {
            return false;
        }
    }


    const valorInput = (event) => {
        setPlaca({ ...placa, [event.target.name]: event.target.value });
    }

    async function pesquisaPlacaSubmit(data) {
        const verificaPlaca = isPlateCorrect(data.placa);
        if (verificaPlaca === false) {
            setMsgUsuario('Placa Inválida');
            return
        } else {
            setMsgUsuario('');
            await RequisicaoPost('/pesquisaPlaca', { placa: data.placa },// { //Rota BackEnd para determinar se a placa existe no banco de dados.
            ).then((response2) => {
                if (response2?.statusSearch === "success") {
                    setPlaca(response2.data);
                    setDadosEntrada(response2);
                    return navigate(`/placaCadastrada`); //ROTA FRONT
                } else {
                    setPlaca(data.placa);
                    return navigate('/cadastrarNovaPlaca')
                }
            }).catch((err) => {
                console.log('ERRO', err);
            })
        }
    }

    async function openGate(){
        //setIsOpen(true)
        console.log(`FUNCTION OPEN GATE`)
        const open = await fetch('http://192.168.1.99/?abertura-activate',
            {
                method: 'GET',
                mode: 'no-cors'

                
            }
        )
        console.log('open gate = ', open);
        fecharModal()
    }

    async function closeGate(){
        const close = await fetch('http://192.168.1.99/?fechamento-activate',
            {
                method: 'GET',
                mode: 'no-cors'
                
            }
        )
        console.log(close);
    }

    function abrirModalConfirmacao(entradaId) { //abrir modal de confirmacao
        console.log('Entrou abrir cancela');
        setStatusModal(true);
        console.log('continuou abertura cancela');
    }

    function fecharModal() {
        console.log('ENTROU FECHAR MODAL');
        setStatusModal(false);
    }

    return (
        <div>
            <Header />

            <h2>Portaria- Controle Acesso</h2>
            
             <ModalAviso
                titulo="Confirmar acionamento !!"
                texto="Verifique se não há veiculos abaixo da cancela."
                obs="Fique sempre atento."
                statusModal={statusModal}
                onRequestClose={fecharModal}
                aoConfirmar={openGate}
            ></ModalAviso> 

            <div>
            <Button variant="success" onClick={abrirModalConfirmacao}>Abrir Cancela</Button>
            <Button variant="danger" onClick={closeGate}>Fechar Cancela</Button>
            </div>

            <div className="msgUsuario">{msgUsuario === 'Placa Inválida' ? <Alert className="Alert" key={'danger'} variant={'danger'}>Placa Inválida</Alert> : ""}</div>
            <div className="formArea">
                <form onSubmit={handleSubmit(pesquisaPlacaSubmit)} className={styles.formPesquisaPlaca}>
                    <label><h3>Placa :</h3> </label>
                    <input {...register("placa", { required: true })} placeholder="Digite a Placa" onChange={valorInput}></input>
                    <button className="btn btn-primary">Pesquisar</button>
                </form>
            </div>

            <TableEntradasMain
                dadosTabela={data}
            />

            <div className='pagination'>
                <ComponentPagination></ComponentPagination>
            </div>
            <Footer />
        </div>

    )
};

export default PortariaMain;