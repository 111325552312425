import Footer from "../../../../components/Footer";
import FormVenda from "../../../../components/Forms/Vendas/FormVenda/FormVenda";
import Header from "../../../../components/Header";

const PageNovaVenda = () => {

    return (
        <>  <Header/>
            <h1>Nova Venda</h1>
            <FormVenda
                formType="new"
            />
            <Footer/>
        </>
    )
}

export default PageNovaVenda;