import React, { useContext } from "react"
import Header from "../../../../../components/Header"
import Footer from "../../../../../components/Footer"
import styles from "./PageAdicionarEmb.module.css"
import { useForm } from "react-hook-form"
import { useNavigate } from 'react-router-dom';
import { DescidaContext } from "../../../../../Context/DescidaContext"
import { requisicaoPost } from "../../../../../config/configApi"
import FormAddEmb from "../../../../../components/Forms/FormAddEmb"

const PageAdicionarEmbarcacao = () => {

    const navigate = useNavigate();
    const dadosDescidaContext = useContext(DescidaContext);

    console.log('dadosContext pageAddEmb = ', dadosDescidaContext);

    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <>
            <Header />
            <FormAddEmb
                clienteId = {dadosDescidaContext.dadosDescida.clienteId}
                clienteNome = {dadosDescidaContext.dadosDescida.nome}
                embarcacoes = {[/*dadosDescidaContext.dadosDescida.embarcacoes*/]}

            />
            <Footer />
        </>
    )
}

export default PageAdicionarEmbarcacao