import { useState, createContext } from 'react';

export const PortariaContext = createContext();

export const PortariaContextProvider = ({ children }) => {
    const [dadosEntrada, setDadosEntrada] = useState({});
    const [placa, setPlaca] = useState('');
    const [IdParaExclusao,setIdParaExclusao ] = useState(false);
    const [statusModal, setStatusModal] = useState(false);

    return (
        <PortariaContext.Provider value={ {placa, setPlaca, dadosEntrada,setDadosEntrada,IdParaExclusao,setIdParaExclusao, statusModal, setStatusModal  } }>
            {children}
        </PortariaContext.Provider>
    )
}