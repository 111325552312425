import React, { useContext, useState } from "react";
import { useForm } from 'react-hook-form';
import Footer from "../../../../../components/Footer";
import Header from "../../../../../components/Header";
import { DescidaContext } from "../../../../../Context/DescidaContext";
import styles from './PageAdicionarVeiculo.module.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import api from "../../../../../config/configApi";
import { validaPlacaVeiculo } from "../../../../../services/validation";
import Alert from 'react-bootstrap/Alert';
import RequisicaoPost from "../../../../../config/requisicoes/requisicaoPOST";

const PageAdicionarVeiculo = () => {
    const navigate = useNavigate();
    const dadosDescidaContext = useContext(DescidaContext);
    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();


    async function salvarNovoVeiculoNoBD(data) {
        const cookies = new Cookies();
        const valueToken = cookies.get('C_token');

        const dadosNovoVeiculo = {
            placa: data.placa,
            modelo: data.modelo,
            clienteId: dadosDescidaContext.dadosDescida.veiculos[0]?.clienteId
        }

        await RequisicaoPost('/CreateNewVehicle_DescidaAvulsa', dadosNovoVeiculo)
            .then((response) => {
                if (response.status === 'success') {
                    const tamanhoArrayAntigo = dadosDescidaContext.dadosDescida.veiculos.length
                    dadosDescidaContext.dadosDescida.veiculos.splice(0, tamanhoArrayAntigo); //apago todos os veiculos que estavam no array veiculos no context

                    const novaListaVeiculosBD = response.veiculosDoCliente;
                    const tamanhoNovaLista = response.veiculosDoCliente.length;

                    //Inserir lista completa atualizada dos veiculos do cliente vinda do Back no context veiculos após inserção
                    for (let i = 0; i < tamanhoNovaLista; i++) {
                        dadosDescidaContext.dadosDescida.veiculos.push(novaListaVeiculosBD[i]);
                    }
                    navigate(-1);
                }
            }
            )
    }

    return (
        <>
            <Header />

            <div className={styles.formVeiculo}>
                <h3 className={styles.titulo}>Adicionar Veículo</h3>

                <p className={styles.titulo}>Cliente:{dadosDescidaContext.dadosDescida.nome}</p>

                <form className={styles.formArea} onSubmit={handleSubmit(salvarNovoVeiculoNoBD)}>

                    <div className={styles.formLine}>
                        <label>Modelo/cor:</label>
                        <input {...register('modelo')}></input>
                    </div>

                    <div className={styles.formLine} >
                        <label>Placa:</label>
                        <input {...register('placa', { validate: value => validaPlacaVeiculo(value) })}></input>
                        {errors.placa?.type === 'validate' && <Alert className="Alert" key={'danger2'} variant={'danger'}>Placa Inválida</Alert>}

                    </div>

                    <div className={styles.formLineButton}>
                        <button>Salvar</button>

                    </div>

                </form>

            </div>

            <Footer />
        </>
    )


}

export default PageAdicionarVeiculo;