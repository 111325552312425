import Cookies from 'universal-cookie';
import ReqConfig from './req-config';

const RequisicaoDelete = async (rota, dadosParaEnviar) => {

    const cookies = new Cookies();
    const valueToken = cookies.get('C_token');

    //selecione o status app para definir o tipo de rotas que serao usadas
    //const statusApp = 'Desenvolvimento'; // ou 'Producao' 
    const statusApp = ReqConfig()//'Producao'; //  

    var rotaUtilizada = "";
    if (statusApp === "Desenvolvimento") {
        rotaUtilizada = `http://localhost:3000${rota}`;
    } else if (statusApp === "Producao") {
        rotaUtilizada = `/api${rota}`
    } else {
        console.log('Erro na requisicao post');
    }

    try {        
        if (!navigator.onLine) {
            console.log('offline');
            throw new Error('Sem Conexão !')
          }
          const req = await fetch(rotaUtilizada,
            {
                signal: AbortSignal.timeout(30000),
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${valueToken}`,
                
                },
                body: JSON.stringify( dadosParaEnviar )
                //body: dadosParaEnviar
            }
        )
        const response = await req.json(); 
        console.log('responsePut = ', response)
        return response;    
    } catch (error) {
        console.log(' error on Put requisition = ', error);
        alert("Ocorreu um erro na requisição !")
        return {erro: 'Erro Put'} ;
    }
}

export default RequisicaoDelete;