import Dropdown from 'react-bootstrap/Dropdown';

function DropDownButton(props) {
    return (
        <Dropdown>
            <Dropdown.Toggle variant={props.variant} id="dropdown-basic">
                {props.title}
            </Dropdown.Toggle>
            { props.conditionBlock === props.title ? "" :
            <Dropdown.Menu>
                {props.itens.map((item,index) => (
                            <Dropdown.Item 
                                key={index}
                                onClick={() => props.functionOnClick(props.id,Object.values(item))}
                            >
                                {Object.values(item)}
                            </Dropdown.Item>
                        )
                    )
                } 
            </Dropdown.Menu>
            }
        </Dropdown>
    
    );
}

export default DropDownButton;