import { useContext } from "react"
import { useNavigate } from "react-router-dom";
import Footer from "../../../../components/Footer"
import FormNovaMovimentacao from "../../../../components/Forms/Embarcacoes/Movimentacoes/NovaMovimentacao"
import Header from "../../../../components/Header"
import { EmbarcacaoContext } from "../../../../Context/EmbarcacaoContext"
import { Button } from "react-bootstrap"
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";

interface Props {
    onClick: () => void

}

const PageDetalharMovimentacao = ({onClick}: Props) => {

    const navigate = useNavigate();
    const dadosMovimentacao = useContext(EmbarcacaoContext)
    const movimentacao = dadosMovimentacao?.dadosEmbarcacao

    function editarMov() {
        navigate('/EditarMovimentacao')
    }

    async function deletarMov(movimentacaoId:any) { //abrir modal de confirmacao
        
        // eslint-disable-next-line no-restricted-globals
        const answer = confirm("Deseja realmente excluir essa movimentação ?")
        if (answer === true) {
            const operacaoDeletar = await RequisicaoPost('/deletarMovimentacao',{id:Number(movimentacaoId)})
            if (operacaoDeletar.status === "success"){
                navigate('/movimentacoesMain');
            }else{
                alert("Ocorreu um erro ao tentar deletar o registro.")
            }
        }
    }

    return (
        <>
            <Header />
            <h2>Detalhar Movimentação</h2>

            <Button onClick={editarMov}>Editar Movimentação</Button>

            <Button
                variant="danger"
                onClick={() => deletarMov(Number(movimentacao.id))}
            >
                Excluir
            </Button>

            <FormNovaMovimentacao
                isDisabled={true}
                formType="readOnly"
                movimentacao={movimentacao}
            />

            <Footer />
        </>
    )
}

export default PageDetalharMovimentacao