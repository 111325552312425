import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import TableEmbarcacaoMain from "../../../components/Tables/TableEmbarcacoesMain";
import { EmbarcacaoContext } from "../../../Context/EmbarcacaoContext";


const PageResultadoPesquisaEmbarcacao = () => {

    const {dadosEmbarcacao} = useContext(EmbarcacaoContext);
    console.log('dadosEmbarcacao = ', dadosEmbarcacao);
    return (
        <>
            <Header/>
            <h1>Resultado Pesquisa</h1>
            <TableEmbarcacaoMain
                dados = {dadosEmbarcacao}
                roteDetalhar= '/detalharEmbarcacao'
            />
            <Footer/>

        </>
    )

}

export default PageResultadoPesquisaEmbarcacao;