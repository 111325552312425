import React, { useEffect, useState, createContext, useContext } from "react";
import Table from 'react-bootstrap/Table';
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import api from "../../../config/configApi";
import { useForm } from 'react-hook-form';
import Alert from 'react-bootstrap/Alert';
import { DescidaContext } from "../../../Context/DescidaContext";
import { ClienteContext } from "../../../Context/ClienteContext";
import ComponentPagination from "../../../components/Pagination";
import RequisicaoGET from "../../../config/requisicoes/requisicaoGET";
import RequisicaoPOST from "../../../config/requisicoes/requisicaoPOST";
import Button from "react-bootstrap/esm/Button";

// TEM UM TYPO NO JSX - ESCREVI EMBARCACO AO INVES DE ENBARCACAO
const PageDescidaAvulsaMain = () => {
    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit } = useForm();


    const [data, setData] = useState([]);
    const [DescidasPendentes, setDescidasPendentes] = useState([]);

    const { dadosDescida, setDadosDescida } = useContext(DescidaContext)
    const { currentPage } = useContext(ClienteContext);


    const getAllDescidas = async () => {

        await RequisicaoGET(`/descidaAvulsa_main/?page=${currentPage}&size=50`).then(
            (response) => {
                setData(response.AllDescidas);
                setDescidasPendentes(response.ultimasEntradas);


            }).catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        return navigate("/");
                    }
                    console.log('err.response = ', err.response.status);
                    console.log('OCORREU UM ERRO');
                    console.log(err)
                }
            })


    }

    useEffect(() => {
        getAllDescidas();
    }, [currentPage]);


    const functionOnSubmit = async (data) => { //pesquisa CPF

        await RequisicaoPOST('/searchCpf', data).then(
            (response) => {
                if (response.statusPesquisa === 'naoLocalizado') {
                    //adicionar cpf do novo cliente no contextDescida
                    setDadosDescida({ cpf: data.registerCliente })
                    //redirecionar para nova pagina
                    return navigate('/cadastrarNovoClienteDescida')
                }

                if (response.statusPesquisa === 'Localizado') {


                    setDadosDescida(response.dadosServidor);
                    return navigate('/descidaClienteCadastrado');
                }



            }).catch((err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        return navigate("/");
                    }
                    console.log(err)
                }
            })


    }

    function verificaCPF(cpfCliente) {
        console.log('CPF A SER CHECADO = ', cpfCliente);
        const regexCPFformat = /([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/
        if (cpfCliente.match(regexCPFformat) && cpfCliente.length === 11) {
            return true;
        } else {
            return false;
        }

    }

    function redirectToDetail(data) {
        console.log('data = ' , data)
        setDadosDescida(data);
        navigate('/PageDetalharDescida');
    }

    function criaNovaDescidaViaEntrada(parametros) { //Esses parametros nao vem do context mas sim da tabela descidas pendentes, logo precisamos ajustar o context com esses dados antes de seguir para uma nova tela
        console.log('//////////////parametrosEntrada = ', parametros);

        const parametrosEntrada = parametros; //entrada associada com a descida que queremos criar
        setDadosDescida({
            cpf: parametros.Cliente?.cpf,
            nome: parametros.Cliente.nome,
            celular: parametros.Cliente.celular,
            veiculos: parametros.Veiculo,
            embarcacoes: parametros.Cliente?.Embarcacoes, //ULTIMA ALTERECAO
            clienteId: parametros.Cliente.id, //id co cliente da entrada que foi cadastrada - ainda nao sei se é duplicado
            entradaId: parametros.id

        });

        //1- verificar se a entrada possui um cpf associado
        if (parametrosEntrada.Cliente.cpf === null || parametrosEntrada.Cliente.cpf === undefined) {
            //PLACA NAO ASSOCIADA A UM CPF
            //apenas placa cadastrada na portaria, sem cpf
            //redirect para pagina inserir CPF
            console.log(' ============= \n\n ENTRADA SEM CPF  \n\n ============= ')
            return navigate('/verificaCliente');
        } else if (parametrosEntrada.Cliente.cpf !== null && parametrosEntrada.Cliente.cpf !== undefined) {
            // Descida cliente cadastrado com CPF via entrada
            console.log('dadosDescida = ', dadosDescida);
            console.log('\n\n ============= ENTRADA COM  CPF  \n\n ============= ');
            return navigate('/descidaClienteCadastradoViaEntrada');
        }



        console.log('entradaId = ', parametrosEntrada);
        //return navigate('/descidaClienteCadastrado');
        console.log('context depois =', dadosDescida);
    }










    return (
        <>
            <Header />

            <form onSubmit={handleSubmit(functionOnSubmit)}>

                <label>CPF:</label>
                <input {...register("registerCliente", { validate: value => verificaCPF(value) }, { required: true })}></input >
                {errors.registerCliente?.type === 'validate' && <Alert className="Alert" key={'danger'} variant={'danger'}>CPF Inválido</Alert>}
                <Button type="submit">Pesquisar</Button>
            </form>

            {/*console.log ('descidasPendentes = ', DescidasPendentes.length)*/}
            {DescidasPendentes?.length > 0 ?
                <>
                    <h3>Descidas Pendentes:</h3>

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Hora</th>
                                <th>Nome</th>
                                <th>Embarcação</th>
                                <th>PlacaCarro</th>
                                <th>TipoEntrada</th>
                                <th>Nova Descida</th>


                            </tr>
                        </thead>
                        <tbody>

                            {
                                DescidasPendentes?.map((descidaPendente, index) => (
                                    <tr key={descidaPendente.id}>

                                        <td>{descidaPendente?.data}</td>
                                        <td>{descidaPendente?.hora}</td>
                                        <td>{descidaPendente?.Cliente.nome}</td>
                                        <td>{descidaPendente?.Cliente.Embarcacoes[0]?.nome}</td>
                                        <td>{descidaPendente?.Veiculo?.placa}</td>
                                        <td>{descidaPendente?.Entrada?.tipoEntrada}</td>
                                        <td>
                                            <Button variant="secondary" size="sm"
                                                onClick={(event) => criaNovaDescidaViaEntrada(descidaPendente)}

                                            >
                                                Nova Descida
                                            </Button>
                                        </td>
                                    </tr>
                                ))}



                        </tbody>


                    </Table>

                </> : ""}



            <h3>Ultimas descidas</h3>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Hora</th>
                        <th>Ndescida</th>
                        <th>Nome</th>
                        <th>Embarcação</th>
                        <th>PlacaCarro</th>
                        <th>PlacaCarreta</th>
                        <th>Valor</th>
                        <th>Forma Pgto</th>
                        <th></th>

                    </tr>
                </thead>
                <tbody>



                    {
                        data.map((Descida, index) => (
                            <tr key={Descida.id}>

                                <td>{new Date(Descida?.data).toLocaleDateString()}</td>
                                <td>{Descida.hora}</td>
                                <td>{Descida.numeroDescida}</td>
                                <td>{Descida.Cliente.nome}</td>
                                <td>{Descida.Embarcaco?.nome}</td>
                                <td>{Descida.Entrada.Veiculo.placa}</td>
                                <td>{Descida.Embarcaco?.placaCarreta}</td>
                                <td>{Descida.Pagamento.valor}</td>
                                <td>{Descida.Pagamento.formaPgto}</td>
                                <td>
                                    <Button variant="secondary" size="sm"
                                        onClick={(event) => redirectToDetail(Descida)}


                                    >
                                        +
                                    </Button>
                                </td>
                            </tr>
                        ))}



                </tbody>


            </Table>

            <ComponentPagination />

            <Footer />
        </>

    )

}

export default PageDescidaAvulsaMain;