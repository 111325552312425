
import React, { useEffect, useState } from 'react';
import { Button, Table, Form } from 'react-bootstrap';

import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import AsyncSelect from 'react-select/async';


interface SaleItem {
    id?: number;
    data?: Date;
    quantidade?: number;
    descricao?: { label: string, value: string }
    valorUnitario?: number;
    valorTotal?: number;
    cliente?: string
}

interface IProps {
    vendas?: any
    formType: string

}

const FormCompra = ({ vendas, formType }: IProps) => {
    const navigate = useNavigate();

    const [items, setItems] = useState<SaleItem[]>([]);
    const [, setResultadoPesquisa] = useState([]);
    const [, setResultadoPesquisaProdutos] = useState([]);

    const [proprietarioInfos, setProprietarioInfos] = useState({});

    const [saleDate, setSaleDate] = useState('')
    const [fornecedor, setFornecedor] = useState('')

    const { handleSubmit, register, formState: { errors }, } = useForm();

    /*useEffect(() => {
        if(formType === "detail" && vendas !== undefined){
            //vendas?.map((item: SaleItem, index: number) => {console.log(index , item)})
            console.log('vendas detail = ', vendas);
            setItems(vendas?.Produtos)
            vendas?.Produtos.map((item:any) => {
                console.log('item = ',item.descricao);

                //handleAddRow( 31,item.descricao, item.valorUnitario, 1)
            })
        }
    },[vendas])*/



    async function pesquisaProprietarios(data: any) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/listarProprietarios', newData)
            setResultadoPesquisa(resultado.rows);
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map((item: { id: string; nome: string; }) => ({ value: item?.id, label: item?.id + '-' + item?.nome }))
            return proprietariosFormatado;
        }
    }

    async function pesquisaProduto(data: any) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        console.log("data = ", newData)
        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/produtoByName', newData)
            console.log("Resultado Produtos = ", resultado);
            setResultadoPesquisaProdutos(resultado.rows);
            const produtos = resultado.produtos.rows;
            const produtosFormatado = produtos.map((item: { id: string; descricao: string; }) => ({ value: item?.id, label: item?.id + '-' + item?.descricao }))
            console.log('produto-formatado = ', produtosFormatado);
            return produtosFormatado;
        }
    }


    const handleAddRow = () => {
        setItems([
            ...items,
            { id: Date.now(), quantidade: 0, descricao: { label: '', value: '' }, valorUnitario: 0, valorTotal: 0 }
        ]);
    };



    const handleInputChange = (index: number, field: keyof SaleItem, value: string | number) => {
        const newItems = items.map((item: any, i) => {
            if (i === index) {
                const updatedItem = { ...item, [field]: value };
                if (field === 'quantidade' || field === 'valorUnitario') {
                    updatedItem.valorTotal = updatedItem.quantidade * updatedItem.valorUnitario;
                }
                return updatedItem;
            }
            return item;
        });
        setItems(newItems);
    };

    async function salvarDados(products: any) {
        //dados passados do form para a function via state 
        const dadosVenda = { proprietarioInfos, products, saleDate, fornecedor }
        console.log('Dados compra = ', dadosVenda )

        const salvarVenda = await RequisicaoPost('/novaCompra', dadosVenda)

        if (salvarVenda.status === 'success') {
            console.log('SALVOU')
            navigate('/comprasMain')
        } else {
            console.log('ERRO')
        }

    }

    return (
        <div className="container mt-5">
            <Form onSubmit={handleSubmit(salvarDados)} >

                <Form.Group as={Row} className="mb-3">
                    <Col>
                        <Form.Label>Data Compra:</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control
                            type="date"
                            onChange={(e) => setSaleDate(e.target.value)

                            }
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Col xs={2}>
                        <Form.Label>Fornecedor:</Form.Label>
                    </Col>
                    <Col>
                        {/*<AsyncSelect placeholder={"Digite nome Cliente"} cacheOptions loadOptions={pesquisaProprietarios} defaultOptions onChange={(data: any) => setProprietarioInfos(data)} ></AsyncSelect>*/}
                        <Form.Select
                            onChange={(e) => setFornecedor(e.target.value)}
                        >
                            <option value="">Selecione</option>
                            <option value="Auto Posto Chaparral">Auto Posto Chaparral</option>
                        </Form.Select>
                    </Col>
                </Form.Group>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Quantidade</th>
                            <th>Produto</th>
                            <th>Valor Unitário</th>
                            <th>Valor Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    <Col xs={4}>
                                        <Form.Control
                                            type="number"
                                            value={item.quantidade}
                                            onChange={(e) =>
                                                handleInputChange(index, 'quantidade', Number(e.target.value))
                                            }
                                            min={0}
                                        />
                                    </Col>
                                </td>
                                <td>
                                    <Col xs={12}>
                                        <AsyncSelect {...register("descricao")} placeholder={"Digite Produto"} cacheOptions loadOptions={pesquisaProduto} defaultOptions onChange={(selectOption: any) => handleInputChange(index, 'descricao', selectOption)}
                                        ></AsyncSelect>
                                    </Col>
                                </td>
                                <td>
                                    <Col xs={4}>
                                        <Form.Control
                                            {...register("valorUnitario")}
                                            type="number"
                                            value={item.valorUnitario}
                                            onChange={(e) =>
                                                handleInputChange(index, 'valorUnitario', Number(e.target.value))
                                            }
                                        />
                                    </Col>
                                </td>
                                <td>{item?.valorTotal?.toFixed(2)}</td>

                            </tr>


                        ))}
                        <Button variant="secondary" onClick={handleAddRow}>
                            Adicionar Linha
                        </Button>
                    </tbody>
                </Table>

                {<Button onClick={() => salvarDados(items)}>Salvar</Button>}
                {/*<Button type='submit'>Salvar</Button>*/}
            </Form>
        </div>
    );
};

export default FormCompra;

