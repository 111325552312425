import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useForm } from "react-hook-form";
import styles from "./FormVeiculo.module.css";
//import { validaPlacaVeiculo } from "../../../../services/validation";
import AsyncSelect from 'react-select/async';
//import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';
import requisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import { validaPlacaVeiculo } from "../../../../services/validation";


const FormNovoVeiculo = (props) => {

    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [proprietarioInfos, setProprietarioInfos] = useState({});

    const navigate = useNavigate();


    var isReadOnly = [0];

    console.log('PROPS.DADOS = ', props);


    async function pesquisaProprietarios(data) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await requisicaoPost('/listarProprietarios', newData)
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map(item => ({ value: item?.id, label: item?.nome }))
            //proprietariosFormatado.push({ value: 0, label: " Adicionar Novo" })
            return proprietariosFormatado;
        }

    }

    async function salvarDados(data) {
        const formData = data;
        console.log(formData);

        console.log('proprietario infos =', proprietarioInfos);
        const clienteId = Number(proprietarioInfos.value);

        const requisicao = await requisicaoPost('/adicionarNovoVeiculo', { ...formData, clienteId });
        console.log('REQUISICAO = ', requisicao);
        navigate('/veiculosMain');


    }

    return (

        <>
            < div className={styles.formArea}>
                <Form onSubmit={handleSubmit(salvarDados)}>
                    <section>
                        <Form.Group >
                            <Row className="mb-3">
                                {/*<Form.Group key="A" as={Row}>
                                    <Form.Label column lg={3}>
                                        <strong>Proprietário: {props.dados?.Cliente?.nome}</strong>
                                    </Form.Label>

                                </Form.Group>
                                    */}
                                <Form.Group as={Row}>
                                    <Form.Label>Proprietário:</Form.Label>
                                    <AsyncSelect placeholder={"Digite nome Proprietário"} cacheOptions loadOptions={pesquisaProprietarios} defaultOptions onChange={(data) => { setProprietarioInfos(data) }} ></AsyncSelect>
                                </Form.Group>
                            </Row>


                            <Form.Group key="F" as={Col}>
                                <Form.Group as={Row}>
                                    <Form.Label sm={2}>
                                        Placa :
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control  {...register('placa', { validate: value => validaPlacaVeiculo(value) })} defaultValue={props.dados?.placa} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        {errors !== undefined && errors?.placaCarreta?.type === 'validate' && <p className={styles.formError}>Placa Inválida</p>}
                                    </Col>
                                </Form.Group>
                            </Form.Group>



                            <Form.Group as={Col}>
                                <Form.Group as={Row}>
                                    <Form.Label lg={5}>
                                        Modelo:
                                    </Form.Label>
                                    <Col sm={6}>
                                        <Form.Control  {...register('modelo')} defaultValue={props.dados?.modelo} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                    </Col>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Group as={Row}>
                                    <Form.Label lg={3}>
                                        Status:
                                    </Form.Label>
                                    <Col sm={6}>
                                        <Form.Select  {...register('status')} defaultValue={props.dados?.status} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} >
                                            <option>Ativo</option>
                                            <option>Inativo</option>
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </Form.Group>



                        <br></br>
                        <Button type="submit">Salvar</Button>
                </section>
            </Form>

        </div >
        </>
    )

}

export default FormNovoVeiculo;