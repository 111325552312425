import React from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FormNovaMovimentacao from "../../../../components/Forms/Embarcacoes/Movimentacoes/NovaMovimentacao";

const PageNovaMovimentacao = () => {
    
    return (
        <>
            <Header/>
                <h1>Nova Movimentação</h1>
                <FormNovaMovimentacao/>
            <Footer/>
        </>
    )
}

export default PageNovaMovimentacao;