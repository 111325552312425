import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormNovoVeiculo from "../../../components/Forms/Portaria/FormNovoVeiculo"
const PageNovoVeiculo = () => {

    return (
        <>
            <Header/>
                <h1>Cadastrar Novo Veiculo</h1>
                <FormNovoVeiculo/>
            <Footer/>
        </>
    )
}

export default PageNovoVeiculo;