import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import React, { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';



import { AuthContext } from '../../Context/AuthContext';

import '../../components/route'
import '../Main'
import styles from './login.module.css'
import Footer from '../../components/Footer'
import Cookies from 'universal-cookie';
import RequisicaoPost from '../../config/requisicoes/requisicaoPOST';
import { MainContext } from '../../Context/MainContext';
import { PortariaContext } from '../../Context/PortariaContext';


const Login = () => {
    const navigate = useNavigate();

    const { userAuth, setUserAuth } = useContext(AuthContext); //context é atribuido para todas as rotas
    //const {dadosMain, setDadosMain} = useContext(MainContext)
    //const {placa} = useContext(PortariaContext)

    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    })

    const valorInput = (event) => {
        setUser({ ...user, [event.target.name]: event.target.value });


    }
    const loginSubmit = async e => {
        e.preventDefault();



        console.log('ENTROU L;OGIN');
        const response = await RequisicaoPost("/login",
            {
                email: user.email,
                senha: user.password
            }
        );

        console.log('resposta login = ', response)
        //setDadosMain(response.nome)

        if (response.erro === "Sistema indisponível") {
            setStatus({
                type: 'error',
                mensagem: 'Sistema indisponível'//err.response.data.mensagem
            });
            return navigate("/login");
        }

        if (response.token !== undefined && response.token !== null) {
            //signIn(true);
            console.log('response =', response);
            const cookies = new Cookies();
            const valueToken = response.token;
            cookies.set('C_token', valueToken,
                {
                    //signed: true,
                    maxAge: 60000 * 60 * 8,
                    //httpOnly: true,
                    //secure:true //permite apenas HTTPS    
                });

            cookies.set('userInfo', response.nome,
                {
                    //signed: true,
                    maxAge: 60000 * 60 * 8,
                    //httpOnly: true,
                    //secure:true //permite apenas HTTPS    
                });
            
                cookies.set('alc', response.alc,
                {
                    //signed: true,
                    maxAge: 60000 * 60 * 8,
                    //httpOnly: true,
                    //secure:true //permite apenas HTTPS    
                });


            setStatus({
                type: 'success',
                mensagem: "Login realizado com sucesso",
                loading: false
            });
            setUserAuth(response);
            //setDadosMain(response);
            return navigate("/main");
        } else {
            setStatus({
                type: 'error',
                mensagem: 'Credenciais Incorretas'//err.response.data.mensagem
            });
            navigate("/login")

        }



    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.formArea}>
                    <img src='../../images/nauticaLogo.png' alt='logo da empresa'></img>

                    {status.type === 'error' ? <Alert key={'danger'} variant={'danger'}>{status.mensagem} </Alert> : ""}
                    {status.type === 'success' ? <Alert key={'success'} variant={'success'}>{status.mensagem}</Alert> : ""}
                    {status.loading ? <p>Validando...</p> : ""}

                    <form className={styles.formLogin} onSubmit={loginSubmit}>

                        <div className={styles.inputText}>
                            <label>Usuário</label>
                            <input type="text" name="email" placeholder="Digite o email" onChange={valorInput}></input>
                        </div>

                        <div className={styles.inputText}>
                            <label>Senha</label>
                            <input type="password" name="password" placeholder="Digite a senha" onChange={valorInput}></input>
                        </div>

                        <Button className={styles.Button} type="submit" variant="secondary">Acessar</Button>{' '}

                    </form>
                </div>
            </div>

            <Footer />
        </>

    )
}
//}

export default Login;