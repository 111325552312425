import React, {useContext} from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FormNovoProdutoServico from "../../../../components/Forms/Vendas/FormNovoProdutoServico/FormNovoProdutoServico";
import RequisicaoPut from "../../../../config/requisicoes/requisicaoPUT";
import { useNavigate } from 'react-router-dom';
import { VendaContext } from "../../../../Context/VendaContext";
const PageEditarProdutoServico = () => {

    const navigate = useNavigate();
    const { dadosProdutoServico } = useContext(VendaContext)

    console.log('dadosProdutoServico = ', dadosProdutoServico);
    async function editarDados(produto: any) {
        console.log('produto =', produto);

        const requisicao = await RequisicaoPut(`/produto/${dadosProdutoServico.id}`, produto);
        console.log('salvarProduto = ', requisicao);

        if (requisicao.status === 'success') {
            navigate('/produtosServicosMain');
        } else {
            alert("Ocorreu um erro !")
            return;
        }
    }


    return (
        <>
            <Header />
            <h1>Editar Produto</h1>
            <FormNovoProdutoServico
                formType="edit"
                submitFunction={editarDados}
                descricao={dadosProdutoServico?.descricao?.toString()}
                valorUnitario={Number(dadosProdutoServico?.valorUnitario)}
                estoque={Number(dadosProdutoServico?.qtdadeEstoque)}
            />


            <Footer />
        </>
    )
}
//}

export default PageEditarProdutoServico;

