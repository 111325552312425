import React, { useContext, useState, useEffect } from "react"
import Header from "../../../components/Header"
import Footer from "../../../components/Footer"
import TableClienteMain from "../../../components/Tables/TableClientesMain"
import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import styles from "./PageClientesMain.module.css"
import { useForm } from "react-hook-form";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET";
import { ClienteContext } from "../../../Context/ClienteContext";
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import ComponentPagination from '../../../components/Pagination'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Badge from 'react-bootstrap/Badge';

const PageClientesMain = () => {


    const [dataTable, setDataTable] = useState([]);
    const { dadosCliente, setDadosCliente, currentPage } = useContext(ClienteContext);
    const navigate = useNavigate();
    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [filtroAplicado, setFiltroAplicado] = useState(false);

    async function getAllClientes() {
        const AllClients = await RequisicaoGet(`/listarClientesCadastrados/?page=${currentPage}&size=50`)
        console.log('ALL CLIENTES = ', AllClients);
        setDataTable(AllClients);
    }


    useEffect(() => {
        getAllClientes();

    }, [currentPage]);


    async function PesquisarCliente(data) {
        console.log('pesquisar Cliente = ', data)
        console.log(data.dadosPesquisa /1 );
        console.log(typeof(data.dadosPesquisa))

        const PesquisaAPI = await RequisicaoPost('/pesquisarCliente', data);
        console.log('PESQUISA API =', PesquisaAPI);
        if (PesquisaAPI?.length > 0) {
            setDadosCliente(PesquisaAPI);
            navigate("/pageResultadoPesquisa")
        } else {
            setDadosCliente(0)
        }

    }

    async function aplicaFiltroCliente(parametrosFiltro) {
        console.log('parametrosFiltro = ', parametrosFiltro)
        const ListaClientes = await RequisicaoPost(`/filtrarClientes`, parametrosFiltro);
        //console.log('resultadoFiltro = ', dadosFiltrados);
        //const ListaClientes = {dadosFiltrados}
        setFiltroAplicado(true);
        setDataTable({ ListaClientes });
    }

    return (
        <>
            <>{console.log('dataTable = ', dataTable)}</>
            <Header />

            <h1>Clientes Cadastrados</h1>
            <hr />

            <div className={styles.line}>
                <Row className="align-items-center">
                    <Col>
                        <Button href="/pageNovoCliente">Novo Cliente</Button>
                    </Col>

                    <Col>
                        <DropdownButton
                            key={'end'}
                            id={`dropdown-button-drop-end`}
                            drop={'end'}
                            variant="Secondary"
                            title={`Filtrar`}
                        >
                            <DropdownButton title={"Status"} variant={"Secondary"}>
                                <Dropdown.Item as="button" variant={"Secondary"} onClick={() => aplicaFiltroCliente({ status: "Ativo" })}>Ativo</Dropdown.Item>
                                <Dropdown.Item as="button" variant={"Secondary"} onClick={() => aplicaFiltroCliente({ status: "Inativo" })}>Inativo</Dropdown.Item>
                            </DropdownButton>

                            <DropdownButton title={"Tipo Cliente"} variant={"Secondary"}>
                                <Dropdown.Item as="button" variant={"Secondary"} onClick={() => aplicaFiltroCliente({ tipoCliente: "Mensalista" })}>Mensalista</Dropdown.Item>
                                <Dropdown.Item as="button" variant={"Secondary"} onClick={() => aplicaFiltroCliente({ tipoCliente: "Convidado Mensalista" })}>Convidado Mensalista</Dropdown.Item>
                            </DropdownButton>


                        </DropdownButton>


                    </Col>

                    <Col xs={9}>
                        <Form onSubmit={handleSubmit(PesquisarCliente)}>
                            <Form.Group as={Row} >

                                <Col sm={7}>
                                    <Form.Control {...register("dadosPesquisa", { required: true, validate: value => value.length > 2 })} placeholder="Digite Cliente" ></Form.Control>
                                </Col>
                                <Col>
                                    <Button type="submit">Pesquisar</Button>
                                </Col>
                                {errors?.dadosPesquisa?.type === "required" ? <Alert variant={"danger"}>Campo obrigatório</Alert> : ""}
                                {dadosCliente === 0 ? <Alert variant={"danger"}>Nenhum resultado</Alert > : ""}
                                {errors?.dadosPesquisa?.type === "validate" ? <Alert variant={"danger"}>Digite 3 caracteres ou mais</Alert> : ""}
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </div>

            <hr />
            <div>
                <strong>Ativos: {dataTable?.NclientesAtivos}</strong>
                {filtroAplicado === true ? <Col xs={2}><Badge bg="warning"  >Filtros Aplicados</Badge></Col> : ''}

            </div>

            <TableClienteMain
                roteDetalhar="/pageDetalharCliente"
                dados={dataTable?.ListaClientes?.rows}
            />

            <ComponentPagination
            />

            <Footer />


        </>
    )

}

export default PageClientesMain;