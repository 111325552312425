import React , {useContext}from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { DescidaContext } from "../../../../Context/DescidaContext";
import FormDescidaAvulsaViaEntrada from "../../../../components/Forms/FormDescidaAvulsaViaEntrada";

const PageCadastrarDescidaViaEntrada = (props) => {

    const dadosDescidaContext = useContext(DescidaContext);
    console.log('dadosContextPageDescida via entrada = ', dadosDescidaContext);
    const entradaId = Number(dadosDescidaContext.dadosDescida.entradaId)


    return (
        <>
            <Header/>
            <h3>Descida Avulsa via Entrada - Cliente Cadastrado</h3>
            <FormDescidaAvulsaViaEntrada
                tipoForm = "clienteCadastradoViaEntrada"
                //contextDescida = {dadosDescidaContext}
                /*clienteId = {dadosDescidaContext.dadosDescida.clienteId}
                entradaId= {dadosDescidaContext.dadosDescida.entradaId}
                clienteCpf = {dadosDescidaContext.dadosDescida.cpf}
                clienteNome = {dadosDescidaContext.dadosDescida.nome}
                clienteCelular = {dadosDescidaContext.dadosDescida.celular}
                veiculos = {dadosDescidaContext.dadosDescida.veiculos[0]}
                embarcacoes = {dadosDescidaContext.dadosDescida.embarcacoes}
                */
                
                rotaBackEnd = {`/Create_DescidaViaEntrada/${entradaId}`}

            />
            <Footer/>
        </>


    )

}

export default PageCadastrarDescidaViaEntrada;