import React from 'react'

import styles from './Footer.module.css'

const Footer = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <div className={styles.rodape}>
            <p> Rodovia Dom Pedro I km 19,5- Paraiso De Igaratá -Igaratá-SP</p>
            <p>Todos os direitos reservados &copy;</p>
            <p>1996 - {currentYear}</p>
        </div>
    )
}

export default Footer;