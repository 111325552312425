import {Route, Routes } from 'react-router-dom';
//import PageVendasMain from '../pages/Vendas/PageVendasMain';
import PageVendasMain from '../pages/Vendas/Vendas/PageVendaMain/PageVendasMain';
import PageProdutosServicosMain from '../pages/Vendas/ProdutosServicos/PageProdutoServicoMain/PageProdutosServicosMain';
import PageNovoProdutoServico from '../pages/Vendas/ProdutosServicos/PageNovoProdutoServico';
import PageDetalharProdutoServico from '../pages/Vendas/ProdutosServicos/PageDetalharProdutoServico';
import PageEditarProdutoServico from '../pages/Vendas/ProdutosServicos/PageEditarProdutoServico';
import PageNovaVenda from '../pages/Vendas/Vendas/PageNovaVenda/PageNovaVenda';
import PageDetalharVenda from '../pages/Vendas/Vendas/PageDetalharVenda';
import PageEditarVenda from '../pages/Vendas/Vendas/PageEditarVenda';

export default function VendasRoutes(){
    return (
        <>
            <Routes>
                <Route path='/vendasMain' element={<PageVendasMain/>}/>

                <Route path='/produtosServicosMain' element={<PageProdutosServicosMain/>}/>
                <Route path='/novoProdutoServico' element={<PageNovoProdutoServico/>}/>
                <Route path='/detalharProdutoServico' element={<PageDetalharProdutoServico/>}/>
                <Route path='/editarProdutoServico' element={<PageEditarProdutoServico/>}/>

                <Route path='/novaVenda' element={<PageNovaVenda/>}/>
                <Route path='/detalharVenda' element={<PageDetalharVenda/>}/>
                <Route path='/editarVenda' element={<PageEditarVenda/>}/>


            </Routes>
        </>
    )
}