import { useState, createContext } from "react";

export const ClienteContext = createContext();

export const ClienteContextProvider = ({ children }) => {
    const [dadosCliente, setDadosCliente] = useState({});
    const [currentPage, setCurrentPage] = useState(0);
    const [globalMsg, setGlobalMsg] = useState([false,'']);

    return (
        <ClienteContext.Provider value={{
            dadosCliente,
            setDadosCliente,
            currentPage,
            setCurrentPage,
            globalMsg,
            setGlobalMsg
        }}>
            {children}
        </ClienteContext.Provider>
    )
}