import React from "react";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";



const componentRoute = (props) => {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path={props.path} element={props.element}>

                    </Route>
                </Routes>

            </Router>
        </div>
    )
}

export default componentRoute;