import {useContext} from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FormNovoChecklist from "../../../../components/Forms/Checklists/FormNovoChecklist";
import { EmbarcacaoContext } from "../../../../Context/EmbarcacaoContext";
import FormEditarEmbarcacao from "../../../../components/Forms/Embarcacoes/FormEditarEmbarcacao";


const PageEditarChecklist = () => {
    
    const { dadosEmbarcacao } = useContext(EmbarcacaoContext)
    
    return (
        <>
            <Header/>
            <h1>Editar Checklist</h1>
            <FormEditarEmbarcacao
                dados={dadosEmbarcacao?.Embarcaco}
                formType={"detalhar"}
            />

            <FormNovoChecklist
                isDisabled={false}
                formType={"editar"}
                dados={dadosEmbarcacao?.Checklist}

            />
            <Footer/>
        </>
    )
}
export default PageEditarChecklist