
import React, { useEffect, useState } from 'react';
import { Button, Table, Form, FormControl } from 'react-bootstrap';

import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import AsyncSelect from 'react-select/async';
import RequisicaoPut from '../../../../config/requisicoes/requisicaoPUT';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

interface Sale {
    id?: number;
    data?: Date;
    cliente?: string;
    produtos?: SaleItem[];
}

interface SaleItem {
    id?: number;
    //data?: Date;
    quantidade?: number;
    descricao?: { label: string, value: string } //value = produtoId
    valorUnitario?: number;
    valorTotal?: number;
    //cliente?: string
}
interface IProps {
    vendas?: any
    formType: string
}

const FormEditVenda = ({ vendas, formType }: IProps) => {
    const navigate = useNavigate();
    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [items, setItems] = useState<any[]>([]);
    const [, setResultadoPesquisa] = useState([]);
    const [, setResultadoPesquisaProdutos] = useState([]);
    const [proprietarioInfos, setProprietarioInfos] = useState({});
    const [saleDate, setSaleDate] = useState('');
    const [saleTotal, setSaleTotal] = useState(0);
    const [obsVenda, setObsVenda] = useState('');

    let readOnly: boolean[] = [];
    if (formType === 'edit') {
        readOnly = [false, false, false, false, false, false,false]
    } else if (formType === 'detail') {
        readOnly = [true, true, true, true, true, true, true]
    }

    useEffect(() => {
        let arrayProdutos: { id: number; quantidade: number; descricao: any; valorUnitario: number; valorTotal: number; }[] = [];
        console.log('vendas = ', vendas);

        let total = 0;
        vendas?.Produtos?.map((item: any, index: number) => {
            console.log('INDEX = ', index);
            console.log('item = ', item);


            let produtosVendaId = Number(item.ProdutosVenda.id);
            let produtoId = Number(item.ProdutosVenda.produtoId)
            let quantidade = Number(item.ProdutosVenda.quantidade);
            let descricao = item.descricao;
            let valorUnitario = Number(item.ProdutosVenda.valorUnitario);
            let valorTotal = Number(item.ProdutosVenda.valorTotalItens);

            console.log('PRODUTO_VENDA_ID = ', produtosVendaId);
            total = total + valorTotal
            arrayProdutos.push({ id: produtosVendaId, quantidade, descricao: { label: descricao, value: produtoId }, valorUnitario, valorTotal });
            //handleAddRow(quantidade, { label: descricao, value: item.ProdutosVenda.id }, valorUnitario, valorTotal);

        })
        console.log('vendas.data = ', vendas?.data);
        setSaleDate(vendas?.data);
        setItems(arrayProdutos);
        setSaleTotal(total)
        //console.log('arrayProdutos',arrayProdutos)
    }, [vendas]) // CONTINUAR DAQUI: SE TENHO N ITENS, ELE RENDERIZA N-1 NA LISTA DA VENDA - PROBLEMA RENDERIZACAO REACT

    useEffect(() => {
        let valorTotal = 0;
        items.map((item: any) => {
            valorTotal = valorTotal + item?.valorTotal
        })
        setSaleTotal(valorTotal)
    }, [items]);

    console.log('state items = ', items);


    async function pesquisaProprietarios(data: any) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/listarProprietarios', newData)
            setResultadoPesquisa(resultado.rows);
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map((item: { id: string; nome: string; }) => ({ value: item?.id, label: item?.id + '-' + item?.nome }))
            return proprietariosFormatado;
        }
    }

    async function pesquisaProduto(data: any) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        console.log("data = ", newData)
        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/produtoByName', newData)
            console.log("Resultado Produtos = ", resultado);
            setResultadoPesquisaProdutos(resultado.rows);
            const produtos = resultado.produtos.rows;
            const produtosFormatado = produtos.map((item: { id: string; descricao: string; }) => ({ value: item?.id, label: item?.id + '-' + item?.descricao }))
            console.log('produto-formatado = ', produtosFormatado);
            return produtosFormatado;
        }
    }

    const handleAddRow = (quantidade: number, descricao: { label: string, value: string }, valorUnitario: number, valorTotal: number) => {
        console.log('quantidade inside handleArrRow = ', quantidade);
        setItems([
            ...items,
            //{...newItem}
            //{ id: Date.now(), quantidade: 0, descricao: { label: '', value: '' }, valorUnitario: 0, valorTotal: 0 }
            { id: Date.now(), quantidade: quantidade, descricao: { label: descricao.label, value: descricao.value }, valorUnitario: valorUnitario, valorTotal: valorTotal }
        ]);
    };



    const handleInputChange = (index: number, field: keyof SaleItem, value: string | number) => {
        console.log("field =", field)
        console.log('index = ', index)
        console.log('value = ', value);
        console.log('quantidade = ', items[index].quantidade);
        let valorTotal = 0;
        const newItems = items.map((item: any, i) => {
            console.log("item HandleInput = ", item);
            valorTotal = valorTotal + item.valorTotal;
            if (i === index) {
                const updatedItem = { ...item, [field]: value };
                if (field === 'quantidade' || field === 'valorUnitario') {
                    updatedItem.quantidade = value;
                    updatedItem.valorTotal = updatedItem.quantidade * updatedItem.valorUnitario;

                }
                console.log("updatedItem = ", updatedItem);
                return updatedItem;
            }
            return item;
        });
        setItems(newItems);
        setSaleTotal(valorTotal);
    };

    async function updateDados(products: any) {
        //dados passados do form para a function via state 
        console.log("products = ", products)
        console.log("proprietarioInfos = ", proprietarioInfos)
        console.log('SaleDate = ', saleDate)
        const dadosVenda = { proprietarioInfos, products, saleDate, obsVenda }
        console.log('DADOS ATUALIZADOS = ', dadosVenda);
        const salvarVenda = await RequisicaoPut(`/venda/${vendas?.id}`, dadosVenda)

        if (salvarVenda.status === 'success') {
            console.log('SALVOU')
            navigate('/vendasMain')
        } else {
            console.log('ERRO')
        }

    }

    return (
        <div className="container mt-5">
            <Form onSubmit={handleSubmit(updateDados)} >

                <Form.Group as={Row} className="mb-3">
                    <Col>
                        <Form.Label>Data Venda:</Form.Label>
                    </Col>
                    <Col>
                        <Form.Control
                            type="date"
                            onChange={(e) => setSaleDate(e.target.value)}
                            defaultValue={vendas?.data?.slice(0, 10)}
                            disabled={readOnly[0]}
                        />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                    <Col xs={2}>
                        <Form.Label>Cliente:</Form.Label>
                    </Col>
                    <Col>
                        {/*
                        <AsyncSelect placeholder={"Digite nome Cliente"} cacheOptions loadOptions={pesquisaProprietarios} defaultOptions onChange={(data: any) => setProprietarioInfos(data)} ></AsyncSelect>
                        */}
                        <FormControl
                            //{...register("descricao")}
                            type='text'
                            defaultValue={vendas?.Cliente?.nome}
                            disabled={readOnly[1]}


                        />

                    </Col>
                </Form.Group>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Quantidade</th>
                            <th>Produto</th>
                            <th>Valor Unitário</th>
                            <th>Valor Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    <Col xs={4}>
                                        <Form.Control
                                            type="number"
                                            defaultValue={item.quantidade}
                                            onChange={(e) =>
                                                handleInputChange(index, 'quantidade', Number(e.target.value))
                                            }
                                            disabled={readOnly[2]}
                                            min={0}

                                        />
                                    </Col>
                                </td>
                                <td>
                                    <Col xs={12}>
                                        {formType === 'edit' && <AsyncSelect {...register("descricao")} placeholder={"Digite Produto"} cacheOptions loadOptions={pesquisaProduto} defaultOptions onChange={(selectOption: any) => handleInputChange(index, 'descricao', selectOption)}
                                            defaultValue={{ value: item.descricao.value, label: item.descricao.label }}
                                        ></AsyncSelect>}
                                        {formType === 'detail' && <FormControl
                                            //{...register("descricao")}
                                            type='text'
                                            defaultValue={item.descricao.label}
                                            disabled={readOnly[3]}


                                        />}
                                    </Col>
                                </td>
                                <td>
                                    <Col xs={4}>
                                        <Form.Control
                                            //{...register("valorUnitario")}
                                            type="number"
                                            defaultValue={item?.valorUnitario}
                                            onChange={(e) =>
                                                handleInputChange(index, 'valorUnitario', Number(e.target.value))
                                            }
                                            disabled={readOnly[4]}
                                        />
                                    </Col>
                                </td>
                                <td>{item?.valorTotal?.toFixed(2)}</td>

                            </tr>



                        ))}
                        <hr></hr>

                    </tbody>
                </Table>
                {formType === 'edit' && <Button variant="secondary" onClick={() => handleAddRow(0, { label: '', value: '' }, 0, 0)}>
                    Adicionar Linha
                </Button>}
                <h4>TOTAL : R$ {saleTotal}</h4>

                <h3>Observações Venda</h3>
                
                <FloatingLabel controlId="floatingTextarea2" label="Adicione informações sobre a venda">
                    <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: '100px' }}
                        disabled={readOnly[5]}
                        defaultValue={vendas?.obsVenda}
                        onChange={(e) => setObsVenda(e.target.value)}
                    />
                </FloatingLabel>

                {/*<Button onClick={() => salvarDados(items)}>Salvar</Button>*/}
                {/*<Button type='submit'>Salvar</Button>*/}
                {formType === 'edit' && <Button onClick={() => updateDados(items)}>Salvar</Button>}
            </Form>
        </div>
    );
}

export default FormEditVenda;