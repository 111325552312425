import React, {useState, useContext, useEffect} from "react";
import Table from 'react-bootstrap/Table';
import Checklist from "../../../shared/Types/Checklist";
import { useNavigate } from "react-router-dom";
import { ClienteContext } from "../../../Context/ClienteContext";
import { Button } from "react-bootstrap";
import { EmbarcacaoContext } from "../../../Context/EmbarcacaoContext";


interface Props {
    checklists: Checklist[]
}

interface Parameters {
    Checklist: {statusCheckList:string}
}


const TableCheckList = ({checklists}: Props) => {

    const navigate = useNavigate();
    const {setDadosEmbarcacao } = useContext(EmbarcacaoContext)



    const { currentPage} = useContext(ClienteContext);
    const [dataTable, setDataTable] = useState<Checklist[]>([]);
    
    console.log('dataTable = ', dataTable);

    useEffect(() => {
        setDataTable(checklists)
}, [checklists])

function RedirectTo(paramters:Checklist, rote:string) {
    
    console.log('Parameters.Checklist = ', paramters);
    console.log('rote = ', rote === "Checklist")

    let finalRote = rote;

    const newDataEmbarcacao = { ...paramters, isReadOnly: 1 };
    setDadosEmbarcacao(newDataEmbarcacao);

    if (rote !== "Checklist") {
        return navigate(finalRote)
    }

    if (rote === "Checklist") {

        if (rote === "Checklist" && paramters === null) {
            return;
        }

        console.log('paramters?.Checklist?.statusCheckList = ', paramters?.statusCheckList);
        if (paramters?.statusCheckList === "Pendente") {
            finalRote = "/PageNovoCheckList"
        }

        if (paramters?.statusCheckList === "OK") {
            finalRote = "/detalharChecklist"
        }
    }


    return navigate(finalRote);
}

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Data Entrada</th>
                    <th>Cliente</th>
                    <th>Embarcação</th>
                    <th>Status Checklist</th>
                </tr>
            </thead>

            <tbody>
                {dataTable?.map((item:Checklist)=> (
                    <tr key={item.id}>
                        <td>{new Date(item?.dataChecklist).toLocaleDateString()}</td>
                        <td>{item?.Embarcaco?.Cliente?.nome}</td>
                        <td>{item?.Embarcaco?.id + "-"+ item?.Embarcaco?.nome}</td>
                        <td onClick={(event) => RedirectTo(item, "Checklist")}>
                                <Button variant={item?.statusCheckList === "OK" ? "success" : (item?.statusCheckList === "Pendente" ? "danger" : (item?.statusCheckList === "-" ? "secondary" : ""))}>
                                    {item === null ? " - " : item?.statusCheckList}
                                </Button> 
                        </td>


                    </tr>
                ))}

            </tbody>

        </Table>
    )


    //{item?.statusCheckList}
    // 
}

export default TableCheckList