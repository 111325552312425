import { Button } from "react-bootstrap";
import Footer from "../../../../components/Footer"
import Header from "../../../../components/Header"
import TableVendas from "../../../../components/Tables/TableVendasMain";
import RequisicaoGet from "../../../../config/requisicoes/requisicaoGET";
import { useEffect, useState, useContext } from "react";
import { Venda } from "../../../../shared/Types/Venda";
import { ClienteContext } from "../../../../Context/ClienteContext";
const PageVendasMain = () => {

    const [sales , setSales] = useState<Venda[]>([])
    const { currentPage} = useContext(ClienteContext);

    async function getSales(){
        const result = await RequisicaoGet(`/vendas?page=${currentPage}&size=50`)
        console.log('result ', result.vendas.rows)
        if (!result){
            return
        }
        setSales(result.vendas.rows)
    }

    useEffect(() => {getSales()},[currentPage])



    return (
        <>
            <Header/>   
            <h1>Vendas</h1>

            <div>
                <Button href="/novaVenda">Nova Venda</Button>
                
            </div>

            <div>
                <h2>Ultimas Vendas</h2>

                <TableVendas 
                    vendas={sales} 
                    roteDetalhar={"/detalharVenda"}                />
            </div>
            <Footer/>
        </>
    )
}

export default PageVendasMain;