import React from "react";
import {Route, Routes } from 'react-router-dom';
import PageDescidaAvulsaMain from "../pages/DescidaAvulsa/Main";
import PageDescidaNovoCliente from "../pages/DescidaAvulsa/NovaDescidaAvulsa/Novo Cliente";
import PageDescidaClienteCadastrado from "../pages/DescidaAvulsa/NovaDescidaAvulsa/ClienteCadastrado";
import PageAdicionarVeiculo from "../pages/DescidaAvulsa/NovaDescidaAvulsa/ClienteCadastrado/AdicionarVeiculo";
import PageAdicionarEmbarcacao from "../pages/DescidaAvulsa/NovaDescidaAvulsa/ClienteCadastrado/AdicionarEmbarcacao";
import PageCadastrarDescidaViaEntrada from "../pages/DescidaAvulsa/NovaDescidaAvulsa/ClienteCadastradoViaEntrada";
import PageVerificaCpf from "../pages/DescidaAvulsa/VerificaCPF";
import PageDescidaNovoClienteViaEntrada from "../pages/DescidaAvulsa/NovaDescidaAvulsa/NovoClienteViaEntrada";
import PageDetalharDescidaAvulsa from "../pages/DescidaAvulsa/PageDetalharDescida";
import PageEditarDescida from "../pages/DescidaAvulsa/PageEditarDescida";

export default function DescidasRoutes() {
    return ( <>
        <Routes>
            <Route path="/descidaAvulsaMain" element={<PageDescidaAvulsaMain/>}/>
            <Route path="/cadastrarNovoClienteDescida" element={<PageDescidaNovoCliente/>} />
            <Route path="/descidaClienteCadastrado" element={<PageDescidaClienteCadastrado/>}/>
            <Route path="/adicionaNovoVeiculo/:clienteId" element={<PageAdicionarVeiculo/>} />
            <Route path="/adicionarNovaEmb" element={<PageAdicionarEmbarcacao/>}/>
            <Route path="/descidaClienteCadastradoViaEntrada" element={<PageCadastrarDescidaViaEntrada/>}/>
            <Route path="/descidaClienteNovoViaEntrada" element={<PageDescidaNovoClienteViaEntrada/>}/>
            <Route path="/verificaCliente" element={<PageVerificaCpf/>}/>
            <Route path="/PageDetalharDescida" element={<PageDetalharDescidaAvulsa/>}/>
            <Route path="/PageEditarDescida" element={<PageEditarDescida/>}/>
        </Routes>
    </>) //add esses parenteses
}