import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormNovoCliente from "../../../components/Forms/Clientes/FormNovoCliente";

const PageNovoCliente = () => {
    return (
        <>
            <Header />
            <h1>Cadastrar Novo Cliente</h1>
            <FormNovoCliente rotaBackEnd = "/cadastrarNovoCliente"/>
            <Footer />
        </>
    )
}

export default PageNovoCliente