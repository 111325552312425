import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

const MainPage = () => {

    if (navigator.onLine) {
        console.log('online');
    } else {
        console.log('offline');
    }

    return (

        <>
            <Header></Header>
            <h1>PÁGINA PRINCIPAL</h1>
            <Footer />
        </>

    )
}

export default MainPage;