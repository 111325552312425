import React, { useContext } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FormDescidaAvulsa from "../../../../components/Forms/FormDescidaAvulsa";
import { DescidaContext } from "../../../../Context/DescidaContext";
import { useNavigate } from "react-router-dom";
import api , {requisicaoPost} from "../../../../config/configApi";

const PageDescidaClienteCadastrado = () => {

    const dadosDescidaContext = useContext(DescidaContext);
    const navigate = useNavigate();

    console.log('context descida na pageDescidaClienteCadastrado= ', dadosDescidaContext);

    
    
    
    try{
    
    return (
        <>
            <Header />

            <FormDescidaAvulsa
                tipoForm='clienteCadastrado'
                contextDescida = {dadosDescidaContext}
                
            />

            <Footer />


        </>
    )
    }catch(error){
        console.log(error);
        //return navigate(-1);
    }

}

export default PageDescidaClienteCadastrado;