import React, {useContext} from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FormDescidaAvulsaViaEntrada from "../../../../components/Forms/FormDescidaAvulsaViaEntrada";
import { DescidaContext } from "../../../../Context/DescidaContext";

const PageDescidaNovoClienteViaEntrada = () => {
    const dadosDescidaContext = useContext(DescidaContext);
    console.log('dadosContextPageDescida via entrada = ', dadosDescidaContext);

    const entradaId = dadosDescidaContext.dadosDescida.entradaId;
    return (
        <>
            <Header />
            <h3>Novo Cliente - Descida Via Entrada</h3>
            <FormDescidaAvulsaViaEntrada
                tipoForm = "clienteNovoViaEntrada"
                 //contextDescida = {dadosDescidaContext}
                 /*clienteId = {dadosDescidaContext.dadosDescida.Cliente?.id}
                 entradaId= {dadosDescidaContext.dadosDescida.id}
                 clienteCpf = {dadosDescidaContext.dadosDescida?.cpf}
                 clienteNome = {dadosDescidaContext.dadosDescida?.nome}
                 clienteCelular = {dadosDescidaContext.dadosDescida?.celular}
                 veiculos = {dadosDescidaContext.dadosDescida.veiculos}
                 embarcacoes = {[]}*/
                 rotaBackEnd = {`/descidaAvulsa_CreateClienteViaEntrada/${entradaId}`}
            />
            <Footer />

        </>
    )
}

export default PageDescidaNovoClienteViaEntrada;