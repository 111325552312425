import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext, useFieldArray, Controller, useWatch, } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validaPlacaVeiculo, verificaCPF } from "../../../../services/validation";
import styles from "./FormNovoCliente.module.css"
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import SubFormVeiculosCliente from "../SubFormVeiculosCliente";
import SubFormEmbsCliente from "../SubFormEmbsCliente";
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import { ClienteContext } from "../../../../Context/ClienteContext";
import OkButton from "../../../Buttons/OkButton";

const FormNovoCliente = (props) => {

    const {dadosCliente , setDadosCliente} = useContext(ClienteContext); //usado para editar cliente com mesmo form 
    const methods = useForm();
    const { formState: { errors }, register, handleSubmit, control, reset } = methods;
    const navigate = useNavigate();

    //adicione isReadyOnly ao contex de clientes
    var isReadyOnly = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; //padrao é poder editar o campo 0 = false
    
    
    if (props.formType === 'edit'){
        isReadyOnly[0]= dadosCliente.isReadOnly;//dadosCliente.enableEdit;
    } 

    
    async function SalvarDadosForm(data) {

        const StatusServidor = await RequisicaoPost(props.rotaBackEnd,data);
        if (StatusServidor.status === "success"){
            navigate('/pageClientesMain');
            return;
        }
    }

    const watchTipoCliente = useWatch({control , name: "TipoCliente"})

    return (
        < div className={styles.formArea}>
            <FormProvider {...methods}>
                <Form noValidate validated={false} onSubmit={handleSubmit(SalvarDadosForm)}>

                    {errors.cpf?.type === 'validate' && <Alert className="Alert" key={'danger1'} variant={'danger'}>CPF Inválido</Alert>}

                    <section>
                        <h3>Cliente</h3>
                        <Row className="mb-3" >
                            {
                            props.formType === "edit" ? 
                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm={2}>
                                            Código:
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control  {...register("codigoCliente", { required: true })} defaultValue={dadosCliente.id}  readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            :
                                ""
                            }

                            <Form.Group as={Col}>
                                <Form.Group as={Row} controlId="validationCustom01">
                                    <Form.Label column sm={1}>
                                        CPF:
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control  {...register("cpf", { validate: value => verificaCPF(value)})} defaultValue={dadosCliente?.cpf} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                        {errors.cpf?.type === 'validate' && <p className={styles.formError}>CPF Inválido</p>}
                                    </Col>

                                </Form.Group>
                            </Form.Group>
                        </Row>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={1}>
                                Tipo:
                            </Form.Label>
                            <Col xs={7}>
                                <Form.Select  {...register("TipoCliente", { validate: value => value !== "Selecione", required: true })} defaultValue={dadosCliente?.tipoCliente} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}>
                                    <option>Selecione</option>
                                    <option>Aluno Wake</option>
                                    <option>Convidado Mensalista</option>
                                    <option>Curso Arrais</option>
                                    <option>Descida Avulsa</option>
                                    <option>Escritório</option>
                                    <option>Mensalista</option>
                                    <option>Restaurante</option>
                                    <option>Visitante</option>
                                </Form.Select>
                                {errors.TipoCliente?.type === 'validate' && <p className={styles.formError}>Tipo inválido</p>}

                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={1}>
                                Nome:
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control  {...register("NomeCliente", { required: true })} defaultValue={dadosCliente?.nome} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}></Form.Control>
                                {errors.NomeCliente?.type === 'required' && <p className={styles.formError}>Campo Obrigatório</p>}

                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column="sm" lg={1}>
                                Endereço:
                            </Form.Label>
                            <Col xs={10}>
                                <Form.Control  {...register("EnderecoCliente")} defaultValue={dadosCliente?.endereco} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Group as={Row}  >
                                    <Form.Label column sm={2}>
                                        Bairro:
                                    </Form.Label>
                                    <Col xs={8}>
                                        <Form.Control  {...register("BairroCliente")} defaultValue={dadosCliente?.bairro} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}></Form.Control>
                                    </Col>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Group as={Row} >
                                    <Form.Label column sm={1}>
                                        CEP:
                                    </Form.Label>
                                    <Col xs={9}>
                                        <Form.Control  {...register("CepCliente")} defaultValue={dadosCliente?.cep} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </Row>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm={1}>
                                Cidade:
                            </Form.Label>

                            <Col xs={7}>
                                <Form.Control  {...register("CidadeCliente")} defaultValue={dadosCliente?.cidade} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                            </Col>
                        </Form.Group>

                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Group as={Row} >
                                    <Form.Label column sm={3}>
                                        Telefone:
                                    </Form.Label>
                                    <Col xs={8}>
                                        <Form.Control  {...register("TelefoneCliente")} defaultValue={dadosCliente?.telefone}  readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}></Form.Control>
                                    </Col>
                                </Form.Group>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Group as={Row} >
                                    <Form.Label column sm={2}>
                                        Celular:
                                    </Form.Label>
                                    <Col xs={8}>
                                        <Form.Control  {...register("CelularCliente")} defaultValue={dadosCliente?.celular}  readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}></Form.Control>
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </Row>


                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={1}>
                                Email:
                            </Form.Label>
                            <Col>
                                <Form.Control  {...register("EmailCliente")} defaultValue={dadosCliente?.email}  readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>
                    </section>
                    <br />

                    <section>
                        <h3>Embarcações</h3>
                        

                        <SubFormEmbsCliente formType = {props.formType} erros={errors.embarcacao} embsCadastradas = {dadosCliente?.Embarcacoes}  />
                        
                    </section>
                    <br></br>

                    <section>
                        <h3>Veículos</h3>
                        {<SubFormVeiculosCliente formType = {props.formType} erros = {errors.veiculo} />}
                    </section>

                    <br />

                    { watchTipoCliente === "Mensalista" ? 
                    <section>
                        <h3>Financeiro</h3>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label sm={1}>
                                Vencimento Dia:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Select  {...register("VencimentoDia", { required: true })} defaultValue={0} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} >
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                    <option>13</option>
                                    <option>14</option>
                                    <option>15</option>
                                    <option>16</option>
                                    <option>17</option>
                                    <option>18</option>
                                    <option>19</option>
                                    <option>20</option>
                                    <option>21</option>
                                    <option>22</option>
                                    <option>23</option>
                                    <option>24</option>
                                    <option>25</option>
                                    <option>26</option>
                                    <option>27</option>
                                    <option>28</option>
                                    <option>29</option>
                                    <option>30</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </section>

                    : ""
                    }


                    {props.formType === "edit" ? "" : 
                    <Button variant="primary" size="lg" type="submit">Salvar</Button>
                    }

                   

                </Form>
            </FormProvider>
        </div>
    )
}

export default FormNovoCliente;
