import React, { useContext, useState, useEffect } from "react";
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import FormEditarDescidaAvulsa from "../../../components/Forms/FormDescidaAvulsa/FormEditarDescidaAvulsa"
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import { DescidaContext } from "../../../Context/DescidaContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import ModalAviso from "../../../components/Modals";

const PageDetalharDescidaAvulsa = () => {
    
    const navigate = useNavigate()
    const [statusModal,setStatusModal] = useState(false);
    const { dadosDescida, setGlobalMsg } = useContext(DescidaContext)

    /*
    useEffect(() => {
        setGlobalMsg([false,'']);
    },[]);
    */

    function abrirModalConfirmacao(entradaId) { //abrir modal de confirmacao
        console.log('Entrou excluir entrada');
        setStatusModal(true);
        console.log('continuou exclusao');
    }

    function fecharModal() {
        console.log('ENTROU FECHAR MODAL');
        setStatusModal(false);
    }

    function editarDescida(){   
        navigate('/PageEditarDescida');
    }

    async function deleteDescida(){
        console.log('Entrou excluir id = ', dadosDescida.id);
        const deletedDescida = await RequisicaoPost('/deleteDescida', {id:Number(dadosDescida.id)})
        setStatusModal(false);

        console.log('deletedDescida = ', deletedDescida)
        if (deletedDescida.status === "success") {
            //getEntradas();
            //setGlobalMsg([true, "Tudo certo !", "Registro deletado com sucesso", "success"]);
            //excluirOS.status = "ended";
            return navigate('/descidaAvulsaMain');

        } else {
            setGlobalMsg([true, "Opa, algo deu errado :(", "Não foi possivel realizar a operação. \n ", "danger"])
        }

    }

     

    return (
        <>
            <ModalAviso
                titulo={"Confirmar exclusão"}
                texto={"Tem certeza que deseja excluir essa descida ?"}
                statusModal={statusModal}
                onRequestClose={fecharModal}
                aoConfirmar={deleteDescida}
            ></ModalAviso>

            <Header/>
                <h1>Detalhar Descida Avulsa</h1>
                <Button onClick={editarDescida}>Editar Descida</Button>

            <Button
                variant="danger"
                onClick={abrirModalConfirmacao}
                >
                    Excluir
            </Button>
                <FormEditarDescidaAvulsa
                    tipoForm="details"
                    //contextDescida = {{...dadosDescida}}
                />
            <Footer/>
        </>
    )
}

export default PageDetalharDescidaAvulsa