import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

function AutohideToast(props) {
    const [show, setShow] = useState(false);

    // setShow(true);

    useEffect(() => {
        setShow(props?.globalMsg[0])
    }, [props.globalMsg]
    )

    return (
        <Row>
            <Col xs={6}>
                <Toast onClose={() => setShow(false)} show={show} delay={10000} autohide bg={props?.globalMsg[3]}>
                    <Toast.Header>

                        <strong className="me-auto">{props?.globalMsg[1]}</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{props?.globalMsg[2]}</Toast.Body>
                </Toast>
            </Col>
           
        </Row>
    );
}

export default AutohideToast;