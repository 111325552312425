import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import api from "../../../config/configApi";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';


import './placaCadastrada.module.css';
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import {FormEntrada2} from "../../../components/Forms/FormEntrada";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import { PortariaContext } from "../../../Context/PortariaContext";
import { ClienteContext } from "../../../Context/ClienteContext";
const PlacaCadastrada = (props) => {
    

    const {dadosEntrada} = useContext(PortariaContext);
    console.log('DADOS ENTRADA CONTEXT = ', dadosEntrada);
    const navigate = useNavigate();
    const {setGlobalMsg} = useContext(ClienteContext);
    
   /* async function openGate(){
        const open = await fetch('http://192.168.1.99/?abertura-activate',
            {
                method: 'GET',
                mode: 'no-cors'

                
            }
        )
        console.log(`STATUS OPEN GATE = `,open);
    }*/

    async function SalvarDados(data){

        console.log('-------ENTROU SALVAR DADOS');
        console.log('DADOS FORM = ' ,  data);

        //verificaTipo(data.tipoCliente);
        //openGate(); //Abrir cancela
        const response = await RequisicaoPost('/CadastrarEntrada',data);//.then(
                console.log('response Cadastrar Entrada =', response);
                
                if (response?.status === 'success'){
                    setGlobalMsg([true, "Tudo certo !", "Entrada salva com sucesso", "success"]);    
                    return navigate("/portariaMain")
                }else{
                    setGlobalMsg([true, "Ué ...", "Algo não funcionou como planejado :( ", "danger"]);    
                    return navigate("/portariaMain")
                }
    }

    return (
        <>
            <Header />

                <FormEntrada2
                    statusPlaca={"Placa Cadastrada"}
                    placa={dadosEntrada?.veiculoLocalizado?.placa}
                    tipoEntrada={dadosEntrada?.clienteLocalizado?.tipoCliente}
                    nome={dadosEntrada?.clienteLocalizado?.nome}
                    embarcacao={dadosEntrada?.embarcacoesLocalizadas[0]?.nome}
                    veiculo={dadosEntrada?.veiculoLocalizado?.modelo}
                    functionOnSubmit = {SalvarDados}
                    //pagamento={dado}
                />

            <Footer />
        </>
    )

};

export default PlacaCadastrada;