import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';

interface ProdutoServico {
    tipo?: string,
    descricao?: string,
    valorUnitario?:number,
    estoque?:number,
    formType: string,
    //submitFunction?: (data: any) => void
    submitFunction?: any
}



const FormNovoProdutoServico = ({tipo,descricao,valorUnitario,estoque,formType,submitFunction}:ProdutoServico) => {

    const { handleSubmit, register, formState: { errors }, } = useForm();
    
    let editableFields: (boolean | undefined)[] = [];

    console.log('tipo ',formType)

    if (formType === "detail"){
        editableFields = [true,true,true,true];
    } 
    
    if (formType === "new"){
        editableFields = [false,false,false,false];
    }
    
    if (formType === "edit") {
        editableFields = [true,false,false,true];
    }

    console.log(`editableFields = `, editableFields);
    /*
    async function salvarDados(produto:any){
        console.log('produto =', produto);

        const requisicao = await RequisicaoPost('/novoProduto',produto);
        console.log('salvarProduto = ', requisicao);

        if(requisicao.status === 'success'){
            navigate('/produtosServicosMain');
        }else{
            alert("Ocorreu um erro !")
            return;
        }
    }*/

    return(
        <>
           

            <Form onSubmit={handleSubmit(submitFunction)}>
                <section>
                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Tipo</Form.Label>
                        </Col>
                        <Col>
                            <Form.Select {...register("tipo")} disabled={editableFields[0]}>

                                <option key={1}>Produto</option>
                                <option key={2}>Serviço</option>

                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Descrição:</Form.Label>
                        </Col>
                        <Col>
                            <Form.Control {...register("descricao")} defaultValue={descricao} disabled={editableFields[1]}></Form.Control>
                        </Col>
                    </Form.Group>

                   

                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Valor:</Form.Label>
                        </Col>

                        <Col>
                            <Form.Control {...register("valorUnitario")} defaultValue={valorUnitario} type="number" disabled={editableFields[2]} step="0.0001"></Form.Control>
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3">
                        <Col>
                            <Form.Label>Estoque:</Form.Label>
                        </Col>

                        <Col>
                            <Form.Control {...register("qtdadeEstoque")} defaultValue={estoque} type="number" disabled={editableFields[3] } min={0}></Form.Control>
                        </Col>

                    </Form.Group>

                    {formType === "edit" || formType === "new"?
                        <Row>
                            <Button type="submit">Salvar</Button>
                        </Row> 
                
                        : ""
                    }

                </section>
            </Form>

        </>
    )
}

export default FormNovoProdutoServico;