import React, { useContext } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { DescidaContext } from "../../../../Context/DescidaContext";
import { FormDescidaAvulsaNovoCliente } from "../../../../components/Forms/FormDescidaAvulsa";
import api from "../../../../config/configApi";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";


const PageDescidaNovoCliente = () => {

    const dadosDescidaContext = useContext(DescidaContext);
    console.log('dadosDescidaContext = ', dadosDescidaContext );

    const navigate = useNavigate();

    function replaceString(label){
        return label.replace(",",".");
   }

    async function salvarDados(data) { 

        console.log('dados do formulario = ', data);
        data.valor = replaceString(data.valor);

        console.log('data.valor = ', data);

        await RequisicaoPost('/CreateClient_Descida', data)
        .then((response) => {
            if(response.status === 'success'){
                return navigate("/descidaAvulsaMain")
            }else{
                console.log('ERRO AO SALVAR DESCIDA')
                return navigate("/descidaAvulsaMain")
            }
            console.log(response.status)
        } 
        )
        


    }


    return (
        <>
            <Header/>
            <h3>Cadastrar Novo Cliente - Descida Avulsa</h3>
            <FormDescidaAvulsaNovoCliente 
                cpf={dadosDescidaContext.dadosDescida.cpf}
                functionOnSubmit = {salvarDados}
                tipoForm = "EntradaNovoCliente"
            />
            <Footer/>
        </>
    )
}

export default PageDescidaNovoCliente;