import React, { useContext } from "react";
import { DescidaContext } from "../../../Context/DescidaContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form"
import styles from "./FormAddEmb.module.css"
import { validaPlacaVeiculo } from "../../../services/validation";
import Alert from 'react-bootstrap/Alert';

const FormAddEmb = (props) => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { dadosDescida, setDadosDescida } = useContext(DescidaContext)

    console.log('dados context dentro do form add emb = ', dadosDescida);


    async function salvarNovaEmbNoBD(data) {

        const dadosNovaEmb = {
            placa: data.placaCarreta,
            modelo: data.modelo,
            tipo: data.tipo,
            clienteId: props.clienteId /*dadosDescidaContext.dadosDescida.veiculos[0]?.clienteId || dadosDescidaContext.dadosDescida.Cliente.id */
        }

        const novaEmb = await RequisicaoPost('/CreateNewEmb_DescidaAvulsa', dadosNovaEmb);
        console.log('lista embs retornada pela API = ', novaEmb);
        console.log('props.embs = ', props.embs);
        if (novaEmb.status === 'success') {

            if (props.embarcacoes !== undefined && props.embarcacoes !== null && props.embarcacoes.length > 0) {

                const tamanhoArrayAntigo = props.embarcacoes.length
                props.embarcacoes.splice(0, tamanhoArrayAntigo); //apago todos as embs que estavam no array embarcacoes no context
            }

            const novaListaEmbsBD = novaEmb?.embsDoCliente; //array que foi devolvido pelo backend
            const tamanhoNovaLista = novaEmb?.embsDoCliente.length;

            console.log('lista retornada do BD = ', novaListaEmbsBD);

            //Inserir lista completa atualizada das embs do cliente vinda do Back no context descida.embs após inserção
            for (let i = 0; i < tamanhoNovaLista; i++) {
                props.embarcacoes.push(novaListaEmbsBD[i]);
            }

            setDadosDescida({ ...dadosDescida, embarcacoes: novaListaEmbsBD })
            navigate(-1);
        }

    }

    return (
        <>
            <div className={styles.formVeiculo}>
                <h3 className={styles.titulo}>Adicionar Nova Embarcação</h3>

                <p className={styles.titulo}>Cliente : {props.clienteNome}</p>

                <form className={styles.formArea} onSubmit={handleSubmit(salvarNovaEmbNoBD)}>

                    {errors.placaCarreta?.type === 'validate' && <Alert className="Alert" key={'danger3'} variant={'danger'}>Placa Inválida</Alert>}
                    {errors.tipo?.type === 'validate' && <Alert className="Alert" key={'danger3'} variant={'danger'}>Selecione Tipo</Alert>}
                    
                    <div className={styles.formLine}>
                        <label>Tipo:</label>
                        <select {...register('tipo',{ validate: value => (value !== 'Selecione') })}>
                            <option>Selecione</option>
                            <option>Jet-Ski</option>
                            <option>Barco Pesca</option>
                            <option>Lancha</option>
                        </select>
                    </div>


                    <div className={styles.formLine}>
                        <label>Embarcação:</label>
                        <input {...register('modelo')}></input>
                    </div>

                    <div className={styles.formLine} >
                        <label>Placa Carreta:</label>
                        <input {...register('placaCarreta', { validate: value => validaPlacaVeiculo(value) })}></input>


                    </div>

                    <div className={styles.formLineButton}>
                        <button>Salvar</button>

                    </div>

                </form>

            </div>

        </>
    )

}

export default FormAddEmb;