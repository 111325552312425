import { useState, createContext } from 'react';

export const CompraContext = createContext();

export const CompraContextProvider = ({children}) => {
    const [dadosCompra, setDadosCompra] = useState([]);
    const [dadosProdutoServico, setDadosProdutoServico] = useState([]);

    return (
        <CompraContext.Provider value={{dadosCompra,setDadosCompra,dadosProdutoServico,setDadosProdutoServico}}>
            {children}
        </CompraContext.Provider>
    )
}