import { useContext } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import { VendaContext } from "../../../../Context/VendaContext";
import FormNovoProdutoServico from "../../../../components/Forms/Vendas/FormNovoProdutoServico/FormNovoProdutoServico";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RequisicaoDelete from "../../../../config/requisicoes/requisicaoDelete";


const PageDetalharProdutoServico = () => {

    const { dadosProdutoServico } = useContext(VendaContext)
    const tipo = dadosProdutoServico?.tipo?.toString()

    const navigate = useNavigate();

    function editarProduto(){
        navigate('/editarProdutoServico')
    }

    async function deletarProduto(produtoId:any) {
            // eslint-disable-next-line no-restricted-globals
            const doubleCkeck = confirm("Deseja realmente excluir esse produto ?")
            
            if (doubleCkeck === true){
                const operacaoDeletar = await RequisicaoDelete(`/produto/${produtoId}`)
            
                if (operacaoDeletar.status === "success") {
                    navigate('/produtosServicosMain');
        
                } else {
                    alert("Ocorreu um erro. Tente Novamente !");
                }
            
            }else{
                return
            }


            
        }
    



    return (
        <>
            <Header />
            <h1>Detalhar Produto/Serviço</h1>

            <Button onClick={editarProduto}>Editar Produto</Button>

            <Button
                onClick={() => deletarProduto(Number(dadosProdutoServico.id))}
                variant="danger"
            >
                Excluir
            </Button>

            <FormNovoProdutoServico
                tipo={tipo}
                formType="detail"
                descricao={dadosProdutoServico?.descricao?.toString()}
                valorUnitario={Number(dadosProdutoServico?.valorUnitario)}
                estoque={Number(dadosProdutoServico?.qtdadeEstoque)}
            />
            <Footer />
        </>
    )
}

export default PageDetalharProdutoServico;