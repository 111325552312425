import React, { useContext, useState, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { ClienteContext } from "../../../Context/ClienteContext";
import Button from 'react-bootstrap/Button';
import FormNovoCliente from "../../../components/Forms/Clientes/FormNovoCliente";
import { useNavigate } from "react-router-dom";
import ModalAviso from "../../../components/Modals";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";



const PageDetalharCliente = () => {

    const {dadosCliente , setGlobalMsg} = useContext(ClienteContext);
    const navigate = useNavigate()    
    const [statusModal,setStatusModal] = useState(false);


    useEffect(() => {
        setGlobalMsg([false,'']);
    },[]);

    function editClient() {
        navigate('/pageEditarCliente');
    }

    async function excluirCliente(){
        console.log('Entrou excluir id = ', dadosCliente.id);
        const IdOSparaExclusao = Number(dadosCliente.id);
        const excluirCliente = await RequisicaoPost('/excluirCliente', {id:IdOSparaExclusao})
        setStatusModal(false);

        if (excluirCliente.status === "success") {
            //getEntradas();
            setGlobalMsg([true, "Tudo certo !", "Registro deletado com sucesso", "success"]);
            return navigate('/pageClientesMain');

        } else {
            setGlobalMsg([true, "Opa, algo deu errado :(", "Não foi possivel realizar a operação \n Dica: Antes de excluir um cliente verifique se não existem Entradas, Embarcações ou Descidas associadas a esse cliente.", "danger"])
        }

    }

    function abrirModalConfirmacao(entradaId) { //abrir modal de confirmacao
        console.log('Entrou excluir entrada');
        //setIdParaExclusao(entradaId)
        setStatusModal(true);
        console.log('continuou exclusao');
    }

    function fecharModal() {
        console.log('ENTROU FECHAR MODAL');
        setStatusModal(false);
    }
//
    return (

        <>
            <ModalAviso
                statusModal={statusModal}
                onRequestClose={fecharModal}
                aoConfirmar={excluirCliente}
                titulo='Confirmar Exclusão'
                texto="Tem certeza que de deseja excluir essa OS ?"
                obs='Essa ação não poderá ser desfeita'
            ></ModalAviso>
            <Header/>
            <hr/>
            <Button onClick={() => {editClient()}}>
                Editar Cliente
            </Button>

            <Button onClick={() => {abrirModalConfirmacao()}} variant="danger">
                Excluir Cliente</Button>
            <hr/>
            <FormNovoCliente
                formType='edit'
            />

            <Footer/>
        </>
    )
}

export default PageDetalharCliente;