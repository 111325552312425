import React, { useContext } from "react";
import { useForm, useFormContext, useFieldArray, Controller, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styles from "./SubFormVeiculosCliente.module.css"
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { validaPlacaVeiculo } from '../../../../services/validation';
import { ClienteContext } from "../../../../Context/ClienteContext";

const SubFormVeiculosCliente = (props) => {

    //const methods = useForm();
    const { formState: { errors } } = useForm({
        defaultValues: {
            veiculo: [{ Modelo: "", Placa: "" }]
        }
    });

    const dadosCliente = useContext(ClienteContext);
    const veiculosCadastrados = dadosCliente.dadosCliente.Veiculos;
    console.log('-------------------veiculos cadastrados = ', veiculosCadastrados);



    const methods = useFormContext();

    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        //nao colocar control aqui quando estiver usando formContext se nao nao reseta os campos
        name: "veiculo",
    });

    var isReadyOnly = [0];


    if (veiculosCadastrados?.length > 0) {
        isReadyOnly=[1];
        for (let k = 0; k < veiculosCadastrados.length; k++) {
            if (fields?.length < veiculosCadastrados?.length) {
                fields.push(veiculosCadastrados[k]);
            }
        }
    }

    return (
        <>


            {fields.map((item, index) => {
                return (
                    <Row className="mb-3" key={item.id}>

                        <Form.Group as={Row} key={item.id} className={styles.row}>
                            <strong>Código Veículo: {item.id}</strong>
                            <Row>
                                <Col>
                                    <Form.Label>Modelo:</Form.Label>
                                    <Form.Control {...methods.register(`veiculo.${index}.Modelo`, { required: true })} defaultValue={item?.modelo} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />

                                </Col>

                                <Col>
                                    <Form.Label >Placa:</Form.Label>
                                    <Form.Control {...methods.register(`veiculo.${index}.Placa`, { validate: value => validaPlacaVeiculo(value), required: true })} defaultValue={item?.placa} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}  />
                                    {/*props.erros[index]?.Placa.type === 'validate' && <p className={styles.formError}>Placa Inválida</p>*/}
                                    {props.erros !== undefined && props.erros[index]?.Placa?.type === 'validate' && <p className={styles.formError}>Placa Inválida</p>}
                                </Col>

                                {veiculosCadastrados?.length > 0 ? "" :
                                    <Col>
                                        <Button variant="outline-danger" size="sm" type="button" onClick={() => { remove(index); console.log('index = ', index) }}>x</Button>
                                    </Col>
                                }
                            </Row>
                        </Form.Group>
                        <hr />



                    </Row>


                )
            })}

            {props.formType === "edit" ? "" :
            //veiculosCadastrados?.length > 0 ? "" :

                <section>
                    <Button variant="secondary"
                        type="button"
                        onClick={() => {
                            append({ Modelo: null, Placa: null });
                        }}
                    >
                        + Novo Veículo
                    </Button>
                    <br />

                </section>
            }
        </>


    )




}




export default SubFormVeiculosCliente