import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PortariaMain from "../pages/Portaria/Main";
import PlacaCadastrada from "../pages/Portaria/PlacaCadastrada";
import CadastrarNovaPlaca from "../pages/Portaria/CadastrarNovaPlaca";
import RelatorioEntradas from "../pages/Portaria/RelatorioEntradas";
import FinanceiroPortaria from "../pages/Portaria/Financeiro";
import PageEditarEntrada from "../pages/Portaria/EditarEntrada";
import PageVeiculosMain from "../pages/Portaria/PageVeiculos"
import PageDetalharVeiculo from "../pages/Portaria/PageDetalharVeiculo";
import PageEditarVeiculo from "../pages/Portaria/PageEditarVeiculo";
import PageNovoVeiculo from "../pages/Portaria/PageNovoVeiculo";

export default function PortariaRoutes() {

    return (
        <div>
            <Routes>
                <Route path="/portariaMain" element={<PortariaMain />} />
                <Route path="/placaCadastrada" element={<PlacaCadastrada />} />
                <Route path='/cadastrarNovaPlaca' element={<CadastrarNovaPlaca />}></Route>
                <Route path="/relatoriosEntradas" element={<RelatorioEntradas />}></Route>
                <Route path="/portariaFinanceiro" element={<FinanceiroPortaria />}></Route>
                <Route path="/portariaEditarEntrada/:entradaId" element={<PageEditarEntrada />}></Route>
                <Route path="/veiculosMain" element={<PageVeiculosMain/>}></Route>
                <Route path="/detalharVeiculo" element={<PageDetalharVeiculo/>}></Route>
                <Route path="/editarVeiculo" element={<PageEditarVeiculo/>}></Route>
                <Route path="/novoVeiculo" element={<PageNovoVeiculo/>}></Route>

            </Routes>
        </div>
    )
}