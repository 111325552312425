import {useContext} from "react";
import Header from "../../../../components/Header";
import FormNovoChecklist from "../../../../components/Forms/Checklists/FormNovoChecklist"
import Footer from "../../../../components/Footer";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { EmbarcacaoContext } from "../../../../Context/EmbarcacaoContext";


const PageNovoChecklist = () => {
    
    const { dadosEmbarcacao } = useContext(EmbarcacaoContext)

    return (
        <>
        <Header/>
        <h1>Novo CheckList</h1>
        <section>
                <h3>Dados Embarcação</h3>

                <Form>

                <Form.Group as={Col}>
                            <Form.Label xs={12}>
                                Proprietário:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control 
                                    disabled
                                    defaultValue={dadosEmbarcacao?.Embarcaco?.Cliente?.nome}> 
                                </Form.Control>
                            </Col>
                        </Form.Group>

                    <Row className="mb-3">
                    

                        <Form.Group as={Col}>
                            <Form.Label xs={5}>
                                Código Emb:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control 
                                    disabled
                                    defaultValue={dadosEmbarcacao?.Embarcaco?.id}> 
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                Data Entrada:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control 
                                disabled
                                defaultValue={new Date(dadosEmbarcacao?.Embarcaco?.dataEntradaEmb).toLocaleDateString()}
                                ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3" >
                        <Form.Group as={Col}>
                            <Form.Label >
                                Tipo Emb:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control 
                                disabled
                                defaultValue={dadosEmbarcacao?.Embarcaco?.tipo}
                                ></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                N Inscrição:
                            </Form.Label>
                            <Col xs={6}>
                                <Form.Control
                                 disabled
                                 defaultValue={dadosEmbarcacao?.Embarcaco?.NinscricaoEmb}
                                 ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3" >
                        <Form.Group as={Col}>
                            <Form.Label >
                                Nome Emb:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control 
                                disabled
                                defaultValue={dadosEmbarcacao?.Embarcaco?.nome}
                                ></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                N Série Casco:
                            </Form.Label>
                            <Col xs={6}>
                                <Form.Control 
                                disabled
                                defaultValue={dadosEmbarcacao.Embarcaco?.NserieCascoEmb}
                                ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3" >
                        <Form.Group as={Col}>
                            <Form.Label >
                                Modelo Emb:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control 
                                disabled
                                defaultValue={dadosEmbarcacao.Embarcaco?.modeloCascoEmb}
                                ></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>
                                Motor:
                            </Form.Label>
                            <Col xs={6}>
                                <Form.Control 
                                disabled
                                defaultValue={dadosEmbarcacao.Embarcaco?.motorEmbarcacao}
                                ></Form.Control>
                            </Col>
                        </Form.Group>
                    </Row>

                </Form>
            </section>
        
        <FormNovoChecklist
            //embarcacaoId={Number(dadosEmbarcacao.id)}
            isDisabled={false}
            formType="editar"
            dados={dadosEmbarcacao?.Checklist}

        />
        <Footer/>
        </>
    )
}

export default PageNovoChecklist