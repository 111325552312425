import React, { useContext, useState, useEffect } from "react"
import { ClienteContext } from "../../../Context/ClienteContext"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import TableClienteMain from "../../../components/Tables/TableClientesMain"

const PageResultadoPesquisa = () => {

    const { dadosCliente, setDadosCliente } = useContext(ClienteContext);



    return (
        <>
            <Header />
            <h1>Resultado Pesquisa</h1>

            <TableClienteMain
                roteDetalhar="/pageDetalharCliente"
                dados={dadosCliente}
            />

            <Footer />
        </>
    )
}

export default PageResultadoPesquisa;