import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import styled from "styled-components";

const StyledDiv = styled.div`
    width: 70%;
    margin: 16px auto;
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #f8f9fa;
    border-radius: 16px;
`

interface IProps {
    label?: string
    placeholder: string
    buttonName: string
    searchFunction: (param: any) => void

}



const InLineSearchForm = ({ label, placeholder, buttonName, searchFunction }: IProps) => {

    return (
        <>
            <br />


            <StyledDiv>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>{label}</Form.Label>
                        <Form.Control type="email" placeholder={placeholder} onChange={(e) => searchFunction(e.target.value)} />
                    </Form.Group>

                </Form >
            </StyledDiv>
        </>
    )
}

export default InLineSearchForm;


