import { useState, createContext } from 'react';

export const DescidaContext = createContext();

export const DescidaContextProvider = ({children}) => {
    const [dadosDescida, setDadosDescida] = useState({});

    return (
        <DescidaContext.Provider value={{dadosDescida,setDadosDescida}}>
            {children}
        </DescidaContext.Provider>
    )
}