import { useState } from "react"
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "./FormNovoChecklist.module.scss"
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import { useNavigate } from "react-router-dom";
import OkButton from "../../../Buttons/OkButton";

interface FormChecklistProps {
    isDisabled: boolean,
    formType: string,
    dados?: any
    submitFunction?: (data: any) => void
}

const FormNovoChecklist = ({ isDisabled, formType, dados, submitFunction }: FormChecklistProps) => {

    const dadosEmbarcacao = dados;

    const { handleSubmit, register, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const [isDisabled2, setIsDisabled2] = useState(false);
    const [saveBtn, setSaveBtn] = useState('Salvar')
    //const [formData, setFormData] = useState({})//

    function beforeSubmit(data: any) {
        setSaveBtn("Carregando...");
        setIsDisabled2(true);
        console.log('dataForm = ', data);
        //setFormData(data);
        saveData(data);
    }

    async function saveData(data: object) {
        console.log('Entrou save Date')
        

        const salvar = await RequisicaoPost("/updateNovoChecklist", { ...data, embarcacaoId: Number(dadosEmbarcacao.embarcacaoId), statusCheckList: 'OK', checklistId: Number(dadosEmbarcacao?.id) });
        
        if (salvar.status === "success") {
            console.log('Salvou CheckList');
            return navigate('/movimentacoesMain');
        } else {
            
            alert("Ocorreu um erro. Verifique sua conexão e tente novamente !")
            setSaveBtn("Salvar");
            setIsDisabled2(false);
            console.log('salvar = ', salvar);
            return;
        }
    }

    return (
        <>
            <hr />
            <section>
                <h2>Checklist Recebimento de Embarcação</h2>
                <Form onSubmit={handleSubmit(beforeSubmit)}>


                    <Form.Group as={Col} xs={6}>
                        <Form.Group as={Row} >
                            <Form.Label >
                                Embarcação com documento original ?
                            </Form.Label>
                            <Col xs={7}>
                                <Form.Select
                                    {...register("documentos", { validate: value => value !== "Selecione", required: true })}
                                    disabled={isDisabled}
                                >
                                    <option>Selecione</option>
                                    <option>Sim </option>
                                    <option>Não</option>
                                    <option selected={dadosEmbarcacao?.documentos}>{dadosEmbarcacao?.documentos}</option>
                                </Form.Select>

                                <p className="teste">Documentos na embarcação:</p>
                                <Col xs={12}>
                                    <Form.Control
                                        placeholder="Descreva documentos na embarcação"
                                        {...register("tipoDocumentos", { required: true })}
                                        disabled={isDisabled}
                                        defaultValue={dadosEmbarcacao?.tipoDocumentos}

                                    />
                                </Col>
                            </Col>
                        </Form.Group>
                    </Form.Group>



                    <p>Data Checklist:</p>
                    <Col xs={3}>
                        <Form.Control
                            placeholder="Data"
                            {...register("dataChecklist", { required: true })}
                            type="date"
                            disabled={isDisabled}
                            defaultValue={dadosEmbarcacao?.dataChecklist}

                        />
                    </Col>

                    <h3>1. Estado Conservação da Embarcação</h3>

                    <ul>


                        <h4>1.1 Casco/Motor</h4>
                        <li className="mb-3">

                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label >
                                        Casco/Motor apresentam algum risco, mancha ou dano aparente ?
                                    </Form.Label>
                                    <Col xs={7}>
                                        <Form.Select
                                            {...register("danosCascoEmotor", { validate: value => value !== "Selecione", required: true })}
                                            disabled={isDisabled}
                                        >
                                            <option>Selecione</option>
                                            <option>Sim </option>
                                            <option>Não</option>
                                            <option selected={dadosEmbarcacao?.danosCascoEmotor}>{dadosEmbarcacao?.danosCascoEmotor}</option>
                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                            <Form.Control
                                placeholder="Descreva estado casco e motor"
                                {...register("estadoCascoEmotor", { required: true })}
                                disabled={isDisabled}
                                defaultValue={dadosEmbarcacao?.estadoCascoEmotor}

                            />
                        </li>

                        <li className="mb-3">
                            <p>Lado esquerdo:</p>
                            <Form.Control
                                placeholder="Descreva estado lado esquerdo casco"
                                {...register("cascoEsquerdo", { required: true })}
                                disabled={isDisabled}
                                defaultValue={dadosEmbarcacao?.cascoEsquerdo}

                            />
                        </li>

                        <li>
                            <p>Lado direito:</p>
                            <Form.Control placeholder="Descreva estado lado direito casco"
                                {...register("cascoDireito", { required: true })}
                                disabled={isDisabled}
                                defaultValue={dadosEmbarcacao?.cascoDireito}

                            />
                        </li>

                        <li>
                            <p>Popa:</p>
                            <Form.Control placeholder="Descreva estado da proa do casco"
                                {...register("cascoPopa", { required: true })}
                                disabled={isDisabled}
                                defaultValue={dadosEmbarcacao?.cascoPopa}

                            />
                        </li>

                        <li>
                            <p>Proa:</p>
                            <Form.Control
                                placeholder="Descreva estado da proa do casco"
                                {...register("cascoProa", { required: true })}
                                disabled={isDisabled}
                                defaultValue={dadosEmbarcacao?.cascoProa}

                            />
                        </li>

                        <li>
                            <p>Estado Motor:</p>
                            <Form.Control
                                placeholder="Descreva estado do Motor"
                                {...register("estadoMotor", { required: true })}
                                disabled={isDisabled}
                                defaultValue={dadosEmbarcacao?.estadoMotor}

                            />
                        </li>

                        <li>
                            <p>Horas de Uso Motor:</p>
                            <Form.Control
                                type="number"
                                placeholder="Horas de Uso Motor (Apenas Números)"
                                {...register("horasMotor", { validate: value => !isNaN(value), required: true })}
                                disabled={isDisabled}
                                defaultValue={dadosEmbarcacao?.horasMotor}
                            />

                        </li>
                    </ul>

                    <ul>
                        <h4>1.2 Interior/Estofamento</h4>
                        <li>
                            <p>Estofamento:</p>
                            <Form.Control
                                placeholder="Descreva estado do estofamento"
                                {...register("estadoEstofamento", { required: true })}
                                disabled={isDisabled}
                                defaultValue={dadosEmbarcacao?.estadoEstofamento}
                            />
                        </li>

                        <li className="mb-3">
                            <p>Capota:</p>
                            <Form.Control
                                placeholder="Descreva estado da capota"
                                {...register("estadoCapota", { required: true })}
                                disabled={isDisabled}
                                defaultValue={dadosEmbarcacao?.estadoCapota}
                            />
                        </li>

                    </ul>

                    <ul>
                        <h4>1.3 Tapetes/EVA</h4>
                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label >
                                        Tipo:
                                    </Form.Label>
                                    <Col xs={7}>
                                        <Form.Select
                                            {...register("tipoTapete", { validate: value => value !== "Selecione", required: true })}
                                            disabled={isDisabled}

                                        >
                                            <option>Selecione</option>
                                            <option>Teka </option>
                                            <option>EVA</option>
                                            <option>Tapete Marítimo</option>
                                            <option>Não Possui</option>
                                            <option selected={dadosEmbarcacao?.tipoTapete}>{dadosEmbarcacao?.tipoTapete}</option>

                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Form.Group>

                        </li>
                        <li>

                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Tapete ou teka danificados  ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("estadoTapete", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.estadoTapete}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>

                        </li>
                    </ul>

                    <h3>2. Acessórios</h3>
                    <ul>
                        <h4>2.1 Carreta </h4>
                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Placa Carreta:
                                    </Form.Label>
                                    <Col xs={6}>
                                        <Form.Control disabled
                                            {...register("placaCarreta", { required: false })}
                                            defaultValue={dadosEmbarcacao?.placaCarreta}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Engate Danificado ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("estadoEngate", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.estadoEngate}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Carreta apresenta sinais de ferrugem/maresia ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("estadoCarreta", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.estadoCarreta}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Pneus ou rodas furados ou danificados ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("estadoPneus", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.estadoPneus}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Possui cinta de amarração/catracas?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("cintasAmarracao", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.cintasAmarracao}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Rodas e Rolamentos em bom estado de conservação ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("estadoRolamentos", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.estadoRolamentos}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>


                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Lanternas quebradas ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("estadoLanternas", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.estadoLanternas}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Conector de energia danificado ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("estadoConector", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.estadoConector}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>


                        <h4>2.2 Lona de Cobertura</h4>
                        <li>

                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label >
                                        Possui lona de cobertura ?
                                    </Form.Label>
                                    <Col xs={7}>
                                        <Form.Select
                                            {...register("lonaCobertura", { validate: value => value !== "Selecione", required: true })}
                                            disabled={isDisabled}
                                        >
                                            <option>Selecione</option>
                                            <option>Sim, possui lona </option>
                                            <option>Não, não possui lona</option>
                                            <option selected={dadosEmbarcacao?.lonaCobertura}>{dadosEmbarcacao?.lonaCobertura}</option>

                                        </Form.Select>
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Estado conservação da lona ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("estadoLona", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.estadoLona}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <h4>2.3 Acessórios de Segurança</h4>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Coletes Salva Vida (Laranja)- Quantidade ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("coletesSalvaVidas", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.coletesSalvaVidas}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Outros Coletes - Quantidade / cor / tamanho ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("outrosColetes", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.outrosColetes}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Extintor de Incêndio ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("extintor", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.extintor}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Bóia Circular ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("boiaCircular", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.boiaCircular}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Bomba de porão funcionando ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("bombaPorao", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.bombaPorao}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>


                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Automático Bomba de porão funcionando ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("automaticoBomba", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.automaticoBomba}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <h4>2.4 Outros acessórios </h4>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Bujão ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("bujao", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.bujao}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Chave da Embarcação ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("chave", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.chave}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Luz de navegação popa ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("luzPopa", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.luzPopa}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Luz de navegação targa ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("luzTarga", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.luzTarga}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Defensas ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            placeholder="Discriminar quantidade"
                                            {...register("defensas", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.defensas}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Skis ou Pranca de WakeBoard ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("wake", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.wake}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Possui churrasqueira ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("churrasqueira", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.churrasqueira}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Possui grelha de churrasqueira ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("grelha", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.grelha}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Baterias ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("baterias", { required: true })}
                                            placeholder="Discriminar marca, anperagem e quantidade"
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.baterias}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Ancora ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("ancora", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.ancora}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>


                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Corda/corrente para ancora ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("correnteAncora", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.correnteAncora}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Rádio AM/FM  ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("radioAMFM", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.radioAMFM}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Rádio VHF  ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("radioVHF", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.radioVHF}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Sonar ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("sonar", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.sonar}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <Form.Group as={Col} xs={6}>
                                <Form.Group as={Row} >
                                    <Form.Label>
                                        Possui motor elétrico ?
                                    </Form.Label>
                                    <Col xs={12}>
                                        <Form.Control
                                            {...register("motorEletrico", { required: true })}
                                            disabled={isDisabled}
                                            defaultValue={dadosEmbarcacao?.motorEletrico}
                                        />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </li>

                        <li>
                            <p>Outros acessórios:</p>
                            <FloatingLabel controlId="floatingTextarea2" label="Descreva acessórios adicionais">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    style={{ height: '100px' }}
                                    {...register("acessoriosAdicionais", { required: true })}
                                    disabled={isDisabled}
                                    defaultValue={dadosEmbarcacao?.acessoriosAdicionais}
                                />
                            </FloatingLabel>
                        </li>

                        <h3>3) Observações Importantes</h3>
                        <li>

                            <FloatingLabel controlId="floatingTextarea2" label="digite">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Leave a comment here"
                                    style={{ height: '100px' }}
                                    {...register("obsAdicionais", { required: true })}
                                    disabled={isDisabled}
                                    defaultValue={dadosEmbarcacao?.obsAdicionais}
                                />
                            </FloatingLabel>
                        </li>
                    </ul>

                    <Form.Group as={Col} xs={6}>
                        <Form.Group as={Row} >
                            <Form.Label >
                                Funcionário:
                            </Form.Label>
                            <Col xs={7}>
                                <Form.Select
                                    {...register("funcionario", { validate: value => value !== "Selecione", required: true })}
                                    disabled={isDisabled}
                                >
                                    <option>Selecione</option>
                                    <option>Eusébio Pereira de Souza </option>
                                    <option>Matheus Biazolli </option>
                                    <option>Giovanni Silva </option>
                                    <option selected={dadosEmbarcacao?.funcionario}>{dadosEmbarcacao?.funcionario}</option>


                                </Form.Select>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                      {/*<OkButton
                        type="submit"
                        beforeSubmit={beforeSubmit}
                        isDisabled={isDisabled2}
                    >
                        {saveBtn + '123'}
    </OkButton>*/}

                    {isDisabled === true ?
                        "" :
                        <Button id="buttonSub" type="submit" disabled={isDisabled2}>{saveBtn}</Button>
                    }
                </Form>
            </section>
        </>
    )
}
export default FormNovoChecklist