import React, { useState, useContext, useEffect } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import FormEditarVeiculo from "../../../components/Forms/Portaria/FormEditarVeiculo";
import { ClienteContext } from "../../../Context/ClienteContext";
import ModalAviso from "../../../components/Modals";
import ToastMsg from "../../../components/Toast";


const PageDetalharVeiculo = () => {

    useEffect(() => {
        setGlobalMsg([false,'']);
    },[]);

    //const {dadosPortaria} = useContext(PortariaContext)
    const {dadosCliente,globalMsg, setGlobalMsg} = useContext(ClienteContext)
    const [statusModal,setStatusModal] = useState(false);

    const navigate = useNavigate()    
    //console.log('dadosEmbarcacao = ', dadosPortaria);
    console.log('dados cliente PAGE DETALHAR VEICULO= ', dadosCliente);

    function editarVeiculo(){   
        navigate('/editarVeiculo');
    }

    async function excluirVeiculo(){
        const veiculoId = Number(dadosCliente.id)
        const deletaVeiculo = await RequisicaoPost('/excluirVeiculo',{id:veiculoId});
        
        setStatusModal(false);
        if (deletaVeiculo.status === "success"){
            setGlobalMsg([true,"Tudo certo !","Registro deletado com sucesso","success"]);
            navigate('/veiculosMain');
        }else{
        setGlobalMsg([true,"Opa, algo deu errado :(","Não foi possivel realizar a operação","danger"])

        }

    }

    function fecharModal() {
        console.log('ENTROU FECHAR MODAL');
        setStatusModal(false);
    }

   

    return(
        <>

              <ModalAviso
                statusModal = {statusModal}
                onRequestClose={fecharModal}
                efetuarExclusao = {excluirVeiculo}
            ></ModalAviso>

            <Header/>
          

            <h1>Detalhes Veiculo</h1>
            <Button onClick={editarVeiculo}>Editar Veículo</Button>
            <Button variant="danger" onClick={() => setStatusModal(true)}>Excluir Veículo</Button>
      
            <FormEditarVeiculo
                dados = {dadosCliente}
                formType={"detalhar"}
            />

            <Footer/>
        </>
    )
}

export default PageDetalharVeiculo;