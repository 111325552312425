import React from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormEditarCliente from "../../../components/Forms/Clientes/FormEditarCliente";

const PageEditarCliente = () => {


    return (
        <>
            <Header />
            <h1>Page Editar Cliente</h1>
            <FormEditarCliente/>
            <Footer />
        </>
    )
}

export default PageEditarCliente;