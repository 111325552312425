import { useState, useContext } from "react";
import { useForm } from 'react-hook-form';
import styles from './FormDescidaAvulsaViaEntrada.module.css';
import { verificaCPF } from "../../../services/validation";
import { validaPlacaVeiculo } from "../../../services/validation";
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom';
import { DescidaContext } from "../../../Context/DescidaContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form'

//APENAS COPIE, FALTA AJUSTAR PARA FUNCIONAR NO CADASTRO DECIDA AVULSA VIA ENTRADA
const FormDescidaAvulsaViaEntrada = (props) => {


    const dadosDescidaContext = useContext(DescidaContext);

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm();
    const [placaEmb, setPlacaEmb] = useState({});
    const [isDisabled2, setIsDisabled2] = useState(false);
    const [saveBtn, setSaveBtn] = useState('Salvar')


    if (props.embarcacoes !== undefined && props.embarcacoes.length > 0) {
        setPlacaEmb({
            placaCarreta: props?.embarcacoes[0]?.placaCarreta || "",
            embarcacaoId: props?.embarcacoes[0].id || ""
        })
    }

    const [placaVeiculo, setPlacaVeiculo] = useState({
        placa: props?.veiculos?.placa,
        veiculoId: props.veiculos?.id
    });


    var isReadyOnly = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; //padrao é poder editar o campo 0 = false

    if (props.tipoForm === "clienteCadastradoViaEntrada") {
        isReadyOnly = [1, 1, 1, 1, 0, 1, 1, 0, 0, 0];
    } else if (props.tipoForm === "clienteNovoViaEntrada") {
        isReadyOnly = [1, 0, 1, 1, 0, 1, 0, 0, 0, 0]
    }



    function onChangeVeiculo(inputSelecionado) {
        const indexVeiculo = inputSelecionado.slice(0, 1);
        const arrayVeiculos = props.veiculos; //na verdade veiculos é um obj e nao um array mas como é apenas 1 nao deve ter problema

        setPlacaVeiculo(
            {
                placa: arrayVeiculos[Number(indexVeiculo)].placa,
                veiculoId: arrayVeiculos[Number(indexVeiculo)].id
            }
        )
    }

    function onChangeEmbarcacao(inputSelecionado) {
        const indexEmb = inputSelecionado.slice(0, 1);
        const arrayEmbs = dadosDescidaContext.dadosDescida.embarcacoes;

        setPlacaEmb(
            {
                placaCarreta: arrayEmbs[Number(indexEmb)].placaCarreta,
                embarcacaoId: arrayEmbs[Number(indexEmb)].id
            }
        );

    }



    function redirectToNovaEmbarcacao() {
        return navigate('/adicionarNovaEmb');
    }

    function replaceString(label){
        return label.replace(",",".");
   }

   function beforeSubmit(data) {
    setSaveBtn("Carregando...");
    setIsDisabled2(true);
    console.log('dataForm = ', data);
    //setFormData(data);
    salvarDescida(data);
}


    async function salvarDescida(dados) { //esta executando, indevidamente, essa funcao ao carregar a pagina
        dados.valor = replaceString(dados.valor);
        const dadosForm = {
            ...dados,
            placaEmb,
            placaVeiculo,
            clienteId: dadosDescidaContext.dadosDescida.clienteId
        }


        //PROBLEMA ESTA AQUI
        // USO O MESMO FORM PARA DESCIDA VIA ENTRADA DE DOIS TIPOS DISTINTOS DE CLIENTES: COM CPF CADASTRADO E SEM CPF
        const StatusServidor = await RequisicaoPost(props.rotaBackEnd, dadosForm);

        if (StatusServidor.status === 'success') {
            navigate('/descidaAvulsaMain');
        }
    }

    return (
        <>
            <div className={styles.formArea}>
                <Form className={styles.formDescida} onSubmit={handleSubmit(beforeSubmit)}>
                    <section>
                        {errors.cpf?.type === 'validate' && <Alert className="Alert" key={'danger1'} variant={'danger'}>CPF Inválido</Alert>}
                        {errors.placaVeiculo?.type === 'validate' && <Alert className="Alert" key={'danger2'} variant={'danger'}>Placa Veículo Inválida</Alert>}
                        {errors.placaCarreta?.type === 'validate' && <Alert className="Alert" key={'danger3'} variant={'danger'}>Placa Carreta Inválida</Alert>}
                        {errors.embarcacao?.type === 'validate' && <Alert className="Alert" key={'danger3'} variant={'danger'}>Selecione Embarcação</Alert>}



                        <Form.Group as={Row} className="mb-3">
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>
                                    CPF:
                                </Form.Label>
                                <Col sm={3}>
                                    <Form.Control className={styles.noEditable}  {...register("cpf", { validate: value => verificaCPF(value), required: true })} defaultValue={dadosDescidaContext.dadosDescida.cpf} readOnly={isReadyOnly[0]}></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Veículo:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control {...register("veiculo")} onChange={(event) => onChangeVeiculo(event.target.value)} defaultValue={dadosDescidaContext.dadosDescida?.veiculos?.modelo} readOnly={isReadyOnly[2]} className={isReadyOnly[2] === 1 ? styles.noEditable : ``} >
                                    {/*dadosDescidaContext?.dadosDescida?.veiculos?.map((veiculo, index) => (
                                       <option key={index}>{index + ' - ' + veiculo?.modelo}</option>
                                   ))*/}
                                </Form.Control>
                            </Col>


                        </Form.Group>


                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Placa Veículo:</Form.Label>
                            <Col xs={4}>
                                <Form.Control {...register("placaVeiculo", { validate: value => validaPlacaVeiculo(value) })} readOnly={isReadyOnly[3]} className={isReadyOnly[3] === 1 ? styles.noEditable : ``} value={dadosDescidaContext?.dadosDescida?.veiculos?.placa}></Form.Control>
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Embarcação:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Select {...register("embarcacao", { validate: value => value !== "Selecione"})} onChange={(event) => onChangeEmbarcacao(event.target.value)} >
                                    <option>Selecione</option>
                                    {dadosDescidaContext.dadosDescida.embarcacoes?.map((emb, index) => (
                                        <option /*onChange={onChangeEmbarcacao()}*/ key={index}>{index + ' - ' + emb?.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Button onClick={redirectToNovaEmbarcacao}> + </Button>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                PlacaCarreta:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control  {...register("placaCarreta", { validate: value => validaPlacaVeiculo(value) })} readOnly={isReadyOnly[5]} className={isReadyOnly[5] === 1 ? styles.noEditable : ``} value={placaEmb.placaCarreta || dadosDescidaContext.dadosDescida?.embarcacoes[0]?.placaCarreta}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Nome:
                            </Form.Label>
                            <Col xs={8}>
                                <Form.Control type="text" {...register("nome", { required: true })} defaultValue={dadosDescidaContext.dadosDescida.nome} readOnly={isReadyOnly[1]} className={isReadyOnly[1] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Celular:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control type="text" {...register("celular")} defaultValue={dadosDescidaContext.dadosDescida.celular} readOnly={isReadyOnly[6]} className={isReadyOnly[6] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>

                  

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Nº Descida:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control type="text" {...register("nDescida")}  className={isReadyOnly[7] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Valor:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control defaultValue={250} {...register("valor")}   className={isReadyOnly[8] === 1 ? styles.noEditable : ``} ></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Pagamento:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Select type="text" {...register("pagamento", { validate: value => value !== "Selecione" })}   >
                                    <option>Selecione</option>
                                    <option>Cartão Crédito</option>
                                    <option>Cartão Débito</option>
                                    <option>Dinheiro</option>
                                    <option>Em Aberto</option>
                                    <option>Isento</option>
                                    <option>PIX</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>


                        <Form.Group>
                            <Col xs={6}>
                                <Button className={styles.buttonFormEntrada} type="submit" disabled={isDisabled2}>{saveBtn}</Button>
                            </Col>
                        </Form.Group>

                    </section>
                </Form>
            </div>

        </>
    )

}

export default FormDescidaAvulsaViaEntrada;

