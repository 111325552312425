import {Route, Routes } from 'react-router-dom';
import PageComprasMain from '../pages/Compras/PageComprasMain';
import PageNovaCompra from '../pages/Compras/PageNovaCompra';

export default function ComprasRoutes(){
    return (
        <>
        <Routes>
            <Route path='/comprasMain' element={<PageComprasMain/>}/>
            <Route path='/novaCompra' element={<PageNovaCompra/>}/>
        </Routes>
        </>
    )
}