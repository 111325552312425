import React, { useState } from "react";
import api from "../../../config/configApi";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { useForm, ErrorMessage } from 'react-hook-form';
import styles from './FinanceiroPortaria.module.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Table from 'react-bootstrap/Table';
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";




const FinanceiroPortaria = () => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const navigate = useNavigate();

    const cookies = new Cookies();
    const valueToken = cookies.get('C_token');

    const [data, setData] = useState([]);

    async function pesquisaData(data) {
        console.log(data)

         RequisicaoPost('/buildReport', data).then((response) => {
                console.log(response);
                if (response.status === "success") {
                    //return navigate("/portariaMain")
                    console.log('RETURNOU = '  , response.result);
                    setData(response.result);
                }
            }
            )

    };

   

    var valorRecebido = 0;
    var qtadadeEntradas = 0;
    data.map((item) => {
        valorRecebido += item.valor_total;
        qtadadeEntradas += item.n_total;
    });
    const saldoDia = valorRecebido;//valorTotalRecebido[valorTotalRecebido.length - 1];
    const qtdadeDia = qtadadeEntradas;




    return (
        <>
            <Header />
            <h1>Resumo Entradas</h1>

            <form onSubmit={handleSubmit(pesquisaData)} className={styles.formArea}>

                <h3>Digite uma data:</h3>
                <div className={styles.formLine}>
                    <label>Data</label>
                    <input {...register("data")} className={styles.formInput} type="date" />
                </div>

                <div className={styles.formButton}>
                    <button >Pesquisar</button>
                </div>


            </form>

            <Table striped bordered hover>

                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Forma Pagamento</th>
                        <th>Quantidade</th>
                        <th>Valor Total</th>
                    </tr>

                </thead>

                <tbody>
                    {data.map((elementoArray) => (
                        <tr key={elementoArray.formaPgto + elementoArray.n_total}>
                            <td>{elementoArray.data}</td>
                            <td>{elementoArray.formaPgto}</td>
                            <td>{elementoArray.n_total}</td>
                            <td>{elementoArray.valor_total}</td>
                        </tr>

                    ))
                    }

                </tbody>

            </Table>
            <h3> Valor total: {saldoDia} </h3>
            <h3>Quantidade Entradas dia: {qtdadeDia}</h3>



            <Footer />
        </>
    );
}

export default FinanceiroPortaria;