import { useContext } from "react";
import Footer from "../../../../components/Footer";
import FormEditVenda from "../../../../components/Forms/Vendas/FormEditVenda";
import Header from "../../../../components/Header";
import { VendaContext } from "../../../../Context/VendaContext";

const PageEditarVenda = () => {

    const {dadosVenda} = useContext(VendaContext)
    return(
        <>
            <Header/>
            <h1> Page Editar Venda</h1>
            <FormEditVenda
                formType="edit"
                vendas={dadosVenda}
            />
            <Footer/>
        </>
    )
}

export default PageEditarVenda;