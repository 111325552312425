import React from "react";
import FormNovaEmbarcacao from "../../../components/Forms/Embarcacoes/FormNovaEmbarcacao";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";


const PageNovaEmbarcacao = () => {

    return (
        <>
            <Header/>
            <h1>Cadastrar Embarcação</h1>
            <FormNovaEmbarcacao />
            <Footer/>
        </>
    )
}

export default PageNovaEmbarcacao;