import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Button from "react-bootstrap/esm/Button";
import TableServicoRampa from "../../../components/Tables/TableServicoRampa";
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET";
import Badge from 'react-bootstrap/Badge';
import { ClienteContext } from "../../../Context/ClienteContext";

const PageServicoRampa = () => {

    const [ dataTable, setDataTable ] = useState();
    const [totalEmbsNaAgua, setTotalEmbsNaAgua] = useState();
    const [embsNaFila, setEmbsNaFila] = useState();
    const [jetsNaAgua,setJetsNaAgua] = useState();
    const [emAtendimento, setEmAtendimento] = useState();
    const [subir,setSubir] = useState();
    const [estoqueGasolina, setEstoqueGasolina] = useState();
    const {currentPage} = useContext(ClienteContext)


    
    async function getAllOS() {
        const dados = await RequisicaoGet(`/getAllOS/?page=${currentPage}&size=10`);
        console.log('dados -= ', dados);

        //setDataTable(dados.AllOS.rows);
        setTotalEmbsNaAgua(dados.qtdadeTotalEmbsNaAgua);
        setEmbsNaFila(dados.qtdadeEmbsNaFila);
        setJetsNaAgua(dados.qtdadeJetsNaAgua);
        setEmAtendimento(dados.qtdadeEmbsAtendimento);
        setSubir(dados.qtdadeEmbsSubir)
        setEstoqueGasolina(dados.qtdadeGaloesGas.qtdadeEstoque)
        //return dados.AllOS.rows;
    }


    

    useEffect(() => {
        getAllOS()
    }
        , []
    )

    
    

    return (
        <>
            <Header />
            <h1>Serviço de Rampa</h1>
            <Button href="/novoServicoRampa">Nova OS</Button>
            <h2>Fila de Atendimento</h2>
            <h4>
            <Badge bg="primary">Na Água: {totalEmbsNaAgua} <br/> B: {totalEmbsNaAgua - jetsNaAgua} / J:{jetsNaAgua}</Badge> 
            <Badge bg="danger">   Na fila: {embsNaFila}  </Badge>
            <Badge bg="warning">Em Atendimento: {emAtendimento}</Badge>
            <Badge bg="success">Subir: {subir}</Badge>
            

            </h4> 

            <h5>Estoque Gasolina: {estoqueGasolina/20} galões</h5>
            <TableServicoRampa
                //dados={dataTable}
                roteDetalhar = "/pageDetalharServicoRampa"
                atualizarDados = {getAllOS}
                type="main"
            />
            <Footer />
        </>
    )
}

export default PageServicoRampa;