import { useState, createContext } from 'react';
import RequisicaoPost from '../config/requisicoes/requisicaoPOST';

export const VendaContext = createContext();

export const VendaContextProvider = ({children}) => {
    const [dadosVenda, setDadosVenda] = useState([]);
    const [dadosProdutoServico, setDadosProdutoServico] = useState([]);

    return (
        <VendaContext.Provider value={{dadosVenda,setDadosVenda,dadosProdutoServico,setDadosProdutoServico}}>
            {children}
        </VendaContext.Provider>
    )
}