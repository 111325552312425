import React, { useContext, useEffect, useState } from "react"
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import FormEditarServicoRampa from "../../../components/Forms/Servicos/FormEditarServicoRampa";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import ModalAviso from "../../../components/Modals";
import { ClienteContext } from "../../../Context/ClienteContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";

const PageDetalharServicoRampa = () => {

    const navigate = useNavigate()    
    const [statusModal,setStatusModal] = useState(false);
    const {dadosCliente , setGlobalMsg} = useContext(ClienteContext);

    useEffect(() => {
        setGlobalMsg([false,'']);
    },[]);
 
//

    function editOS() {
        navigate('/pageEditarServicoRampa');
    }

    async function excluirOS(){
        console.log('Entrou excluir id = ', dadosCliente.id);
        const excluirOS = await RequisicaoPost('/excluirOS', {id:Number(dadosCliente.id)})
        setStatusModal(false);

        if (excluirOS.status === "success") {
            //getEntradas();
            setGlobalMsg([true, "Tudo certo !", "Registro deletado com sucesso", "success"]);
            //excluirOS.status = "ended";
            return navigate('/servicoRampa');

        } else {
            setGlobalMsg([true, "Opa, algo deu errado :(", "Não foi possivel realizar a operação. \n ", "danger"])
        }

    }

    function abrirModalConfirmacao(OSId) { //abrir modal de confirmacao
        console.log('Entrou excluir OS');
        //setIdParaExclusao(entradaId)
        setStatusModal(true);
        console.log('continuou exclusao');
    }

    function fecharModal() {
        console.log('ENTROU FECHAR MODAL');
        setStatusModal(false);
    }

    return (
        <>
              <ModalAviso
                 titulo='Confirmar Exclusão'
                 texto="Tem certeza que de deseja excluir essa OS ?"
                 obs='Essa ação não poderá ser desfeita'
                statusModal={statusModal}
                onRequestClose={fecharModal}
                aoConfirmar={excluirOS}
            ></ModalAviso>
            <Header />
            <h1>Detalhes OS</h1>
            <Button onClick={() => { editOS()}}>
                Editar OS
            </Button>

            <Button onClick={() => {abrirModalConfirmacao()}} variant="danger">
                Excluir OS</Button>
            <hr />
            <FormEditarServicoRampa
                typeForm="detalhar"
            />

            <Footer />
        </>
    )
}

export default PageDetalharServicoRampa;