import { Button } from "react-bootstrap"
import Footer from "../../../components/Footer"
import Header from "../../../components/Header"
import TableCompras from "../../../components/Tables/TableComprasMain"
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET"
import { useState, useContext, useEffect } from "react"
import { ClienteContext } from "../../../Context/ClienteContext"

const PageComprasMain = () => {

    const [buys, setBuys] = useState<[]>([])
    const { currentPage} = useContext(ClienteContext);

    async function getBuys(){
        const result = await RequisicaoGet('/compras')
        console.log('result ', result)
        if (!result){
            return
        }
        setBuys(result.compras.rows)
    }

    useEffect(() => {getBuys()},[currentPage])

    return (
        <>
            <Header/>
            <h1>Compras</h1>

            <Button variant="primary" href="/novaCompra">Nova Compra</Button>

            <TableCompras
                compras = {buys}
                roteDetalhar='/detalharCompra'
            />
            <Footer/>
        </>
    )
}

export default PageComprasMain