import { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import { ClienteContext } from "../../../Context/ClienteContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import ComponentPagination from "../../Pagination";
import Button from "react-bootstrap/esm/Button";
import { CompraContext } from "../../../Context/CompraContext";
import { useNavigate } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import { Compra } from "../../../shared/Types/Compra";

interface Props {
    compras: Compra[] 
    roteDetalhar: string
}

interface Parameters {
    Compra: Compra
}

const TableCompras = ({ compras, roteDetalhar }: Props) => {
    console.log('RoteDetalhar = ', roteDetalhar);

    //const {setDadosVenda } = useContext(VendaContext)
    const { setDadosCompra } = useContext(CompraContext)


    const { currentPage} = useContext(ClienteContext);
    const [filtrosAplicados, setFiltrosAplicados] = useState({});
    const [dataTable, setDataTable] = useState<Compra[]>([]);

    const navigate = useNavigate();


    useEffect(() => {
            setDataTable(compras)
    }, [compras])


    function atualizaFiltros(novofiltro: object) {
        setFiltrosAplicados(filtrosAplicados => ({ ...filtrosAplicados, ...novofiltro }));
    }

    async function aplicarFiltro() {
        const dadosFiltrados = await RequisicaoPost(`/getAllEmbarcacoesFilter/?page=${currentPage}&size=50`, filtrosAplicados);
        console.log('dados Filtrados = ', dadosFiltrados);
        setDataTable(dadosFiltrados?.listaEmbarcacoesFiltradas?.rows);
    }

    function RedirectTo(paramters:any, rote:string) {
        console.log('Parametros Venda = ', paramters);
        console.log('rote = ', rote);

        let finalRote = rote;

        const newDataVenda = { ...paramters, isReadOnly: 1 };
        setDadosCompra(newDataVenda);

        
        console.log(`FINAL ROTE =`, finalRote);
        return navigate(finalRote);
    }


    //{new Date(item?.data).toLocaleDateString()}
    //console.log(`dataTable tsx = `, dataTable)
    return (
        <>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Código Compra</th>
                        <th>Data</th>
                        <th>Fornecedor</th>
                       
                    
                        <th></th>
                    </tr>
                </thead>


                <tbody>
                    {dataTable?.map((item:Compra) => ( //usar index prejudica performance
                        <tr key={item.id}>
                            <td>{item?.id}</td>
                            <td>{item?.data?.toString().slice(0,10)}</td>
                            <td>{item?.fornecedor}</td>
                            <td><Button variant="secondary" size="sm" onClick={(event) => RedirectTo(item,roteDetalhar )}>+</Button></td>
                            

                        </tr>
                    ))
                    }
                </tbody>
            </Table>

            <ComponentPagination />
        </>
    )
}

export default TableCompras