import { useContext } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import FormNovoChecklist from "../../../../components/Forms/Checklists/FormNovoChecklist";
import { EmbarcacaoContext } from "../../../../Context/EmbarcacaoContext";
import FormEditarEmbarcacao from "../../../../components/Forms/Embarcacoes/FormEditarEmbarcacao";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


const PageDetalharChecklist = () => {
    
    const navigate = useNavigate()
    const { dadosEmbarcacao } = useContext(EmbarcacaoContext)

    console.log('dados emb detalhar = ', dadosEmbarcacao);

    const dados = {
        Cliente: dadosEmbarcacao?.Embarcaco?.Cliente,
        ...dadosEmbarcacao.Embarcaco
    }

    console.log('dadosFormatados = ', dados);
    
    return (
        <>
            <Header />
            <h1>Detalhar Checklist</h1>
            <Button onClick={() => navigate('/editarChecklist')}
            >
                Editar Ckecklist
            </Button>
            
            <FormEditarEmbarcacao
                dados={dadosEmbarcacao?.Embarcaco}
                formType={"detalhar"}
                //checklistData
            />

            <FormNovoChecklist
                isDisabled={true}
                formType="detalhar"
                dados={dadosEmbarcacao?.Checklist}
                submitFunction={() => {}}
            />
            <Footer />
        </>
    )
}

export default PageDetalharChecklist