import React, { useEffect, useState } from 'react';
// Importa a modal do react-modal
import Modal from 'react-modal';
import Button from "react-bootstrap/esm/Button";


const customStyles = {
    content: {
      width: '60%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '0',
      transform: 'translate(-50%, -50%)',
    },
  };


// Código necessário para os recursos de acessibilidade
Modal.setAppElement('#root');

function ModalAviso(props) {
    // Hook que demonstra se a modal está aberta ou não
    const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(props.statusModal)
    }, [props.statusModal])


    // Função que abre a modal
    function abrirModal() {
        setIsOpen(true);
    }



    // Função que fecha a modal
    function fecharModal() {
        setIsOpen(false);
    }
    // onRequestClose={props.fecharModal}
    // Código JSX necessário para criar uma modal simples que abre e fecha
    return (
        <div>

            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                contentLabel="Modal de exemplo"
            >
                <h2>{props.titulo}</h2>
                <div>{props.texto} </div>
                <div>
                    <i>{props.obs}</i>
                </div>
                <Button onClick={props.aoConfirmar} variant="danger">Confirmar</Button>
                <Button onClick={props.onRequestClose}>Cancelar</Button>
            </Modal>
        </div>
    );
}

export default ModalAviso;


