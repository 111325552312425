import React, {useContext} from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { ClienteContext } from "../../../Context/ClienteContext";
import FormEditarVeiculo from "../../../components/Forms/Portaria/FormEditarVeiculo";


const PageEditarVeiculo = () => {
    const {dadosCliente} = useContext(ClienteContext)

    return (
        <>
            <Header/>

            <h1>Editar Veículo</h1>

            <FormEditarVeiculo
                dados={dadosCliente}
                typeForm = "editar"
            />


            <Footer/>
        </>
    )

}

export default PageEditarVeiculo;