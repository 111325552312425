import React, { useEffect, useState, useContext } from "react";
import Table from 'react-bootstrap/Table';
import RequisicaoGet from "../../../config/requisicoes/requisicaoGET"
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';
import { ClienteContext } from "../../../Context/ClienteContext";

const TableClienteMain = (props) => {

    const [dados, setDados] = useState([]);
    const Cliente = useContext(ClienteContext)
    const navigate = useNavigate();


    function RedirectTo(paramters , rote){
        const newDataClient = {...paramters, isReadOnly : 1};    
        Cliente.setDadosCliente(newDataClient);
        return navigate(rote);
    }

    return (

        <> 
        <hr/>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Nome</th>
                        <th>Tipo</th>
                        <th>Embarcação</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {props.dados?.map((item, index) => (
                        <tr key={index}>
                            <td>{item?.id}</td>
                            <td>{item?.nome}</td>
                            <td>{item?.tipoCliente}</td>
                            <td>{item?.Embarcacoes[0]?.nome}</td>
                            <td>{item?.status}</td>
                            <td><Button  variant="secondary" size="sm"  onClick={(event) => RedirectTo(item,props.roteDetalhar)}>+</Button></td>

                        </tr>
                    ))
                    }
                </tbody>
            </Table>
        </>
    )


}

export default TableClienteMain;