import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageClientesMain from "../pages/Clientes/PageClientesMain";
import PageNovoCliente from "../pages/Clientes/PageNovoCliente";
import PageDetalharCliente from "../pages/Clientes/PageDetalharCliente";
import PageEditarCliente from "../pages/Clientes/PageEditarCliente";
import PageResultadoPesquisa from "../pages/Clientes/PageResultadoPesquisa";
import MainPage from '../pages/Main';

export default function ClientesRoutes() {
    return (
        <>
            <Routes>
                <Route path="/main" element={<MainPage />} />

                <Route path="/pageClientesMain" element={<PageClientesMain />} />
                <Route path="/pageNovoCliente" element={<PageNovoCliente />} />
                <Route path="/pageEditarCliente" element={<PageEditarCliente />} />
                <Route path="/pageDetalharCliente" element={<PageDetalharCliente />} />
                <Route path="/pageResultadoPesquisa" element={<PageResultadoPesquisa />} />
            </Routes>
        </>
    )
}