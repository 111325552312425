import {useState, useEffect, useContext} from 'react'
import Header from "../../../../components/Header"
import Footer from "../../../../components/Footer"
import TableCheckList from "../../../../components/Tables/TableChecklistsMain"
import Checklist from "../../../../shared/Types/Checklist"
import RequisicaoGet from '../../../../config/requisicoes/requisicaoGET'
import { ClienteContext } from '../../../../Context/ClienteContext'

const PageChecklistMain = () => {

    const [dataTable, setDataTable] = useState<Checklist[]>([]);
    const { currentPage, setCurrentPage } = useContext(ClienteContext);


    async function getAllChecklists(){
        const checklists = await RequisicaoGet(`/listarChecklists/?page=${currentPage}&size=50`);
        console.log('checklists =', checklists);
        setDataTable(checklists.checklists.rows);
        console.log(`dataTable = `,dataTable);
    }

    useEffect(() => {getAllChecklists()},[]);



    return (
        <>
            <Header/>
            <h1>ChekLists Embarcações</h1>
            <TableCheckList
                checklists={dataTable}
            />
            <Footer/>
        </>
    )
}

export default PageChecklistMain