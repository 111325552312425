import Footer from "../../../components/Footer"
import FormEditarDescidaAvulsa from "../../../components/Forms/FormDescidaAvulsa/FormEditarDescidaAvulsa"
import Header from "../../../components/Header"


const PageEditarDescida = () => {

    return (
        <>
            <Header/>
            <h1>Editar Descida</h1>
            <FormEditarDescidaAvulsa
                tipoForm="Editar"
            />
            <Footer/>
        </>
    )
}

export default PageEditarDescida