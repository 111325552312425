import React, {useContext} from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import {useForm} from 'react-hook-form'
import { verificaCPF } from "../../../services/validation";
import Alert from 'react-bootstrap/Alert';
import { DescidaContext } from "../../../Context/DescidaContext";
import { useNavigate } from 'react-router-dom';
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";


const PageVerificaCpf = () => {
    
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();
    const navigate = useNavigate();
    
    const dadosDescidaContext = useContext(DescidaContext);
    const { dadosDescida , setDadosDescida } = useContext(DescidaContext)

    
    console.log('dados descidaContext PAGEverifica CPF = ', dadosDescidaContext);

    async function functionOnSubmit(data){
        console.log('ENTROU VERIFICA CPF');
        const entradaId = dadosDescidaContext.dadosDescida.entradaId;
        console.log('----------------- EntradaId = ', entradaId)
        const resultadoPesquisa = await RequisicaoPost(`/descidaAvulsa_verifica/${entradaId}`,data);

        console.log('RESPOSTA DO SERVIDOR = ', resultadoPesquisa);
        
        if (resultadoPesquisa.statusPesquisa === "Localizado"){
            console.log('//////////////\nCPF LOCALIZADO\n///////////////////');
            //alterar infos do contextDescida com as informacoes fornecidas pelo backEnd a partir da pesquisa do cpf- eliminamos o cliente sem cpf da entrada e substituimos pelo cliente previamente cadastrado com cpf
            dadosDescida.clienteId = resultadoPesquisa?.clienteId;
            dadosDescida.cpf = resultadoPesquisa?.cpf; 
            dadosDescida.nome = resultadoPesquisa?.nome;
            dadosDescida.embarcacoes = resultadoPesquisa?.embarcacoes;
            dadosDescida.celular = resultadoPesquisa?.celular;
            navigate('/descidaClienteCadastradoViaEntrada'); //CPF É CADASTRADO, PRECISAMOS LIGAR O CPF A PLACA CADASTRADA (CLIENTE ANTIGO COM NOVO CARRO)
            
        }else { //resultadoPesquisa.statusPesquisa === "Não Localizado"

            console.log('//////////////\nCPF NAO LOCALIZADO\n////////////////');
            //setDadosDescida({...dadosDescida, cpf: data.registerCliente});
            dadosDescida.cpf = data.registerCliente
            
            console.log('\n-----dadosDescidaContext = ', dadosDescida);
            navigate('/descidaClienteNovoViaEntrada')
        }
    }


    return (
        <>
            <Header/>

            <form onSubmit={handleSubmit(functionOnSubmit)}>

                <label>CPF:</label>
                <input {...register("registerCliente", { validate: value => verificaCPF(value) }, { required: true })}></input >
                {errors.registerCliente?.type === 'validate' && <Alert className="Alert" key={'danger'} variant={'danger'}>CPF Inválido</Alert>}
                <button>Salvar</button>
            </form>


            <Footer/>
        </>
    )
}

export default PageVerificaCpf;