import React , {useState, useContext} from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import styles from "./RelatoriosEntrada.module.css";
import { useForm  } from 'react-hook-form';
import Alert from 'react-bootstrap/Alert';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import api from "../../../config/configApi";
import Table from 'react-bootstrap/Table';
import Pagination from "../../../components/Pagination";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";
import Button from "react-bootstrap/esm/Button";
import TableEntradasMain from "../../../components/Tables/TableEntradasMain";
import { PortariaContext } from '../../../Context/PortariaContext';


const RelatorioEntradas = () => {

    const { register, formState: { errors }, handleSubmit } = useForm();
    const navigate = useNavigate();
    const [metodoPesquisa, setMetodoPesquisa] = useState('Data')
    const [data,setData] = useState([]);
    const {statusModal, setStatusModal } = useContext(PortariaContext);



    function isPlateCorrect(inputPlate, fieldType){
        const regexOldFormat = /^[a-zA-Z]{3}[0-9]{4}$/;
        const regexNewFormat = /^[a-zA-Z]{3}[0-9][A-Za-z0-9][0-9]{2}$/;
        console.log('input = ' , inputPlate === undefined);
        
        if(inputPlate === undefined || inputPlate === ""){
            console.log('RETORNA FALSO')
            return false;
        }

        if(fieldType !== "Placa"){

            return true;
        }

        if (inputPlate === "" || inputPlate === undefined){
            return false;
        }

     

        if (inputPlate.match(regexOldFormat) || inputPlate.match(regexNewFormat)) {
            return true;
        }else{
            console.log('false');
            return false;
        }
        
    }   

    async function PesquisarEntrada(data) {
        console.log('ENTROU PESQUISAR ENTRADA !!!')
        console.log('data = ', data);
        console.log('validacao placa = ' ,isPlateCorrect(data.placa))

        const cookies = new Cookies();
        const valueToken = cookies.get('C_token');
        //event.preventDefault();

        await RequisicaoPost('/relatoriosEntradas', data

         ).then((response) => {

            console.log('resposta servidor = ' , response);
            if (response?.result?.length === 0){
                setData("Nenhum registro localizado !")

            }else{

                setData(response?.result?.rows || response?.result);
            }
            
        }
        )
    }



    function tipoDeTexto(tipoPesquisaSelecionada){
        if (tipoPesquisaSelecionada === "Data"){
            return "date";
        }else if (tipoPesquisaSelecionada === "Placa" || tipoPesquisaSelecionada === "Nome"){
            return "text";
        }else {
            return "text";
        }

    }


    return (
        <>
            
            <Header />
            <form onSubmit={handleSubmit(PesquisarEntrada)} className={styles.formArea}>
             
                <h3>Relatórios Entrada:</h3>

                <div className={styles.formLine}>
                    <label>Pesquisar por :</label>
                    <select {...register("formaDePesquisa")} className={styles.formInput} type="text" onChange={e => setMetodoPesquisa(e.target.value)}>
                       <option>Data</option>
                       <option>Placa</option>
                       <option>Nome</option>
                       <option>idCliente</option>

                    </select>

                </div>
               
                <div className={styles.formLine}>
                    <label>{metodoPesquisa}:</label>
                    <input
                        {...register("dadoParaPesquisa",{ validate: value => isPlateCorrect(value,metodoPesquisa) === true || 'Placa Inválida'})}
                        className={styles.formInput}
                        type={tipoDeTexto(metodoPesquisa)}

                    />
                    <div className={styles.errorWarning}>
                    {errors.dadoParaPesquisa?.type === 'validate' && <Alert className="Alert" key={'danger'} variant={'danger'}>Campo Inválido</Alert>}
                    {console.log("errors = ", errors)}
                    </div>
                </div>

                <div className={styles.formButton}>
                    <button type="submit" >Pesquisar</button>
                </div>
            </form>

            <div>
                <h3>Entradas Localizadas: </h3>
                {data?.length === 0 ? <Alert className="Alert" key={'danger'} variant={'danger'}>Nenhum registro localizado!</Alert> : "" }
                
                <TableEntradasMain
                    dadosTabela={data}
                />
                 
            </div>

            <Footer />
        </>

    )
}

export default RelatorioEntradas;

