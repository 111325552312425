import React , {useState, useEffect, useContext} from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { FormEntrada2 } from "../../../components/Forms/FormEntrada";
import api from "../../../config/configApi";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { PortariaContext } from "../../../Context/PortariaContext";
import RequisicaoPost from "../../../config/requisicoes/requisicaoPOST";



export default function PageEditarEntrada(props){

    const  dadosEntradaContext  = useContext(PortariaContext); //se o context for um objeto, nao colocar chaves na const - 
    const navigate = useNavigate();
    const cookies = new Cookies();
    const valueToken = cookies.get('C_token');

    const [data, setData] = useState([]);

    //dados do form são passados da pagina principal para essa através de portariaEntradaContext
    console.log('DADOS ENTRADA CONTEXT = ', dadosEntradaContext);

    const editEntrada = async (data) => {
        console.log('dados [PARA EDITAR ] ===== ' , dadosEntradaContext);
        const paymentId = dadosEntradaContext.dadosEntrada.pagamentoId; 
        
        const updatedFormData = {
            valor: data.valor,
            formaPgto: data.pagamento,
            observacao: data.observacao

        }
        console.log('updatedDFata = ' , updatedFormData);


        await RequisicaoPost(`/UpdateEntradaPayment/${paymentId}`,updatedFormData ).then(
            (response) => {
            console.log('response = ', response);//[0].Cliente.Embarcacoes[0].nome);
            if(response.status === "success"){
                return navigate("/portariaMain")
            }
        }).catch((err) => {
            if (err.response) {
                if (err.response.status === 401) {
                    return navigate("/");
                }
                console.log('err.response = ', err.response.status);
                console.log('OCORREU UM ERRO');
                console.log(err)
            }
        })


    }

 


    return (
        <>
            <Header />

            <h1>Editar Entrada</h1>

            <FormEntrada2
                functionOnSubmit={editEntrada}
                statusPlaca={'Editar Entrada'}
                placa= {dadosEntradaContext.dadosEntrada.placa}
                tipo={dadosEntradaContext.dadosEntrada.tipo}
                nome={dadosEntradaContext.dadosEntrada.nome}
                embarcacao={dadosEntradaContext.dadosEntrada.embarcacao}
                veiculo={dadosEntradaContext.dadosEntrada.veiculo}
                valor={Number(dadosEntradaContext.dadosEntrada.valor)}
                formaPgto={dadosEntradaContext.dadosEntrada.formaPgto}
                observacao={dadosEntradaContext.dadosEntrada.observacao}

            />


            <Footer/>
        </>

    )
}
