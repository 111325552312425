import { useState, useContext, useEffect } from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import TableMovimentacoes from "../../../../components/Tables/TableMovimentacoes";
import { Button } from "react-bootstrap";
import { ClienteContext } from "../../../../Context/ClienteContext";
import RequisicaoGet from "../../../../config/requisicoes/requisicaoGET";
import Movimentacao from "../../../../shared/Types/Movimentacao";
import Form from 'react-bootstrap/Form';
import InLineSearchForm from "../../../../components/Forms/inLineSearchForm";
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";


const PageMovimentacoesMain = () => {
    const [dataTable, setDataTable] = useState<Movimentacao[]>([]);
    const { currentPage } = useContext(ClienteContext);


    async function getAllMovimentacoes(): Promise<Movimentacao[]> {
        const movimentacoes = await RequisicaoGet(`/listarMovimentacoes/?page=${currentPage}&size=50`);
        console.log('mov = ', movimentacoes)
        setDataTable(movimentacoes.movimentacoes.rows);
        return movimentacoes.movimentacoes.rows
    }

    useEffect(() => { pesquisarMovimentacoes('') }, [currentPage]);


    async function pesquisarMovimentacoes(dadosPesquisa: string): Promise<Movimentacao[]> {
        if(dadosPesquisa.length < 3){ dadosPesquisa = '' }

        console.log('dados pesquisa = ', dadosPesquisa)
        const movimentacoes = await RequisicaoPost(`/listarMovimentacoes/?page=${currentPage}&size=50`, {dadosPesquisa});
        console.log('movimentacoes pesquisadas = ', movimentacoes)
        setDataTable(movimentacoes.movimentacoes.rows);
        return movimentacoes.movimentacoes.rows

    }

    return (
        <>
            <Header />
            <h1>Movimentações Garagem</h1>

            {/*<Button onClick={setInicial}>SetInicial</Button><br></br>*/}

            <Button href="/novaMovimentacao">Adicionar Movimentação</Button>
            <br />

            <InLineSearchForm
                placeholder="Pesquisar movimentação"
                buttonName="Pesquisar"
                searchFunction={pesquisarMovimentacoes}
            />

            <TableMovimentacoes
                movimentacoes={dataTable}
                roteDetalhar='/detalharMovimentacao'
                pesquisarMovimentacoes={pesquisarMovimentacoes}
            />
            <Footer />
        </>
    )
}

export default PageMovimentacoesMain;