import React, { useState, useContext } from "react";
import { useForm } from 'react-hook-form';
import styles from './FormEditarDescidaAvulsa.module.css';
import { verificaCPF } from "../../../../services/validation";
import { validaPlacaVeiculo } from "../../../../services/validation";
import { useNavigate } from 'react-router-dom';
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form'
import { DescidaContext } from "../../../../Context/DescidaContext";


const FormEditarDescidaAvulsa = (props) => { 

    const navigate = useNavigate();
    const { dadosDescida } = useContext(DescidaContext);
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();
    const [placaEmb, setPlacaEmb] = useState({
        placaCarreta: props?.contextDescida?.dadosDescida?.embarcacoes[0]?.placaCarreta,
        embarcacaoId: props?.contextDescida?.dadosDescida?.embarcacoes[0]?.id
    });
    const [placaVeiculo, setPlacaVeiculo] = useState({
        placa: props?.contextDescida?.dadosDescida?.veiculos[0]?.placa || "",
        veiculoId: props?.contextDescida?.dadosDescida?.veiculos[0]?.id || ""
    });


    var isReadOnly = []; //padrao é poder editar o campo
    if (props.tipoForm === 'Editar') {
        isReadOnly = [1, 1, 1, 1, 1, 1, 1, 0, 0, 0];
    } else if (props.tipoForm === 'details') {
        isReadOnly = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]

    }

    function onChangeVeiculo(inputSelecionado) {
        const indexVeiculo = inputSelecionado?.slice(0, 1);
        const arrayVeiculos = props?.contextDescida?.dadosDescida?.veiculos;

        setPlacaVeiculo(
            {
                placa: arrayVeiculos[Number(indexVeiculo)]?.placa,
                veiculoId: arrayVeiculos[Number(indexVeiculo)]?.id
            }
        )
        console.log(' \\\\\\\\\\\\\\\ \n\nstate placa veiculo ', placaVeiculo, '\\\\\\\\\\\\\\\ \n\nstate placa veiculo ')
    }

    function onChangeEmbarcacao(inputSelecionado) {
        console.log('ENTROU ONCHANGE')
        console.log('inputSelecionado = ', inputSelecionado);
        const indexEmb = inputSelecionado?.slice(0, 1);
        const arrayEmbs = props?.contextDescida?.dadosDescida?.embarcacoes;
        console.log('indexEmb = ', indexEmb);

        setPlacaEmb(
            {
                placaCarreta: arrayEmbs[Number(indexEmb)]?.placaCarreta,
                embarcacaoId: arrayEmbs[Number(indexEmb)]?.id
            }
        );

    }

    async function updateDescida(dados) {
        const updatedData = {
            descidaId: Number(dadosDescida.id),
            numeroDescida: dados.nDescida,
            pagamentoId: Number(dadosDescida.Pagamento.id),
            valor: Number(dados.valor),
            formaPgto: dados.pagamento

        }

        const StatusServidor = await RequisicaoPost(`/updateDescida2`, updatedData);

        if (StatusServidor.status === 200) {
            navigate('/descidaAvulsaMain');
        } else {
            navigate('/descidaAvulsaMain');
        }
    }

    return (
        <>
            <div className={styles.formArea}>
                <Form className={styles.formDescida} onSubmit={handleSubmit(updateDescida)}>
                    <section>

                        <Form.Group as={Row} className="mb-3">

                            <Form.Group as={Col} className="mb-3">
                                <Col xs={1}>
                                    <Form.Label>Data:</Form.Label>
                                </Col>

                                <Col xs={3}>
                                    <Form.Control {...register("data")} defaultValue={dadosDescida?.data?.slice(0, 10)} disabled={isReadOnly[0]} type="date"></Form.Control>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3">
                                <Col xs={2}>
                                    <Form.Label>Hora:</Form.Label>
                                </Col>

                                <Col xs={3}>
                                    <Form.Control {...register("hora")} defaultValue={dadosDescida?.hora} disabled={isReadOnly[1]} type="time"></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Group as={Row}>
                                <Form.Label column sm={2}>
                                    CPF:
                                </Form.Label>
                                <Col sm={3}>
                                    <Form.Control {...register("cpf", { validate: value => verificaCPF(value), required: true })} defaultValue={dadosDescida?.Cliente?.cpf} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''}></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Veículo:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Select {...register("veiculo")} onChange={(event) => onChangeVeiculo(event?.target?.value)} readOnly={isReadOnly[1]} className={isReadOnly[1] === 1 ? styles.noEditable : ''}  >
                                    <option>{dadosDescida.Entrada?.Veiculo?.modelo}</option>
                                    {props?.contextDescida?.dadosDescida?.veiculos?.map((veiculo, index) => (
                                        <option key={index}>{index + ' - ' + veiculo?.modelo}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            {/*props.tipoForm !== 'details' ?
                                <Col>
                                    <Button className={styles.button} onClick={redirectToNovoVeiculo} > + </Button>
                                </Col>
                                :
                                " "
                            */}
                        </Form.Group>


                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Placa Veículo:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control type="text" {...register("placaVeiculo", { validate: value => validaPlacaVeiculo(value) })} value={placaVeiculo.placa || dadosDescida?.Entrada?.Veiculo?.placa} readOnly={isReadOnly[2]} className={isReadOnly[2] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Embarcação:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Select {...register("embarcacao")} onChange={(event) => onChangeEmbarcacao(event.target.value)} readOnly={isReadOnly[3]} className={isReadOnly[3] === 1 ? styles.noEditable : ``} >
                                    <option>{dadosDescida.Embarcaco?.nome}</option>
                                    {dadosDescida?.embarcacoes?.map((emb, index) => (
                                        <option onChange={onChangeEmbarcacao()} key={index}>{index + ' - ' + emb?.nome}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            {/*props.tipoForm !== 'details' ?
                                <Col>
                                    <Button onClick={redirectToNovaEmbarcacao}> + </Button>
                                </Col>
                                :
                                " "
                            */}
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                PlacaCarreta:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control  {...register("placaCarreta", { validate: value => validaPlacaVeiculo(value) })} className={isReadOnly[4] === 1 ? styles.noEditable : ``} value={placaEmb?.placaCarreta || props?.contextDescida?.dadosDescida?.embarcacoes[0]?.placaCarreta || dadosDescida?.Embarcaco?.placaCarreta}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Nome:
                            </Form.Label>
                            <Col xs={8}>
                                <Form.Control type="text" {...register("nome", { required: true })} defaultValue={dadosDescida?.Cliente?.nome} readOnly={isReadOnly[5]} className={isReadOnly[5] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>


                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Celular:
                            </Form.Label>
                            <Col xs={4}>
                                <Form.Control type="text" {...register("celular", { required: true })} defaultValue={dadosDescida?.Cliente?.celular} readOnly={isReadOnly[6]} className={isReadOnly[6] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Nº Descida:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control type="text" {...register("nDescida")} defaultValue={dadosDescida?.numeroDescida} className={isReadOnly[7] === 1 ? styles.noEditable : ``}></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Valor:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Control type="text" {...register("valor")} defaultValue={dadosDescida?.Pagamento?.valor || 250} readOnly={isReadOnly[8]} className={isReadOnly[8] === 1 ? styles.noEditable : ``} ></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Pagamento:
                            </Form.Label>
                            <Col xs={3}>
                                <Form.Select type="text" {...register("pagamento", { validate: value => value !== "Selecione" })} disabled={isReadOnly[8]} className={isReadOnly[8] === 1 ? styles.noEditable : ``}>
                                    <option>{dadosDescida?.Pagamento?.formaPgto}</option>
                                    <option>Cartão Crédito</option>
                                    <option>Cartão Débito</option>
                                    <option>Dinheiro</option>
                                    <option>Em Aberto</option>
                                    <option>Isento</option>
                                    <option>PIX</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        {props.tipoForm !== 'details' ?

                            <Form.Group>
                                <Col xs={6}>
                                    <Button className={styles.buttonFormEntrada} type="submit" >Salvar Alterações</Button>
                                </Col>
                            </Form.Group>
                            : ""
                        }
                    </section>
                </Form>
            </div>

        </>
    )

}

export default FormEditarDescidaAvulsa
