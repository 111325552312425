import { createContext, useState } from "react";



export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [userAuth, setUserAuth] = useState({})

    return (
        <AuthContext.Provider value={{
            authenticated,
            setAuthenticated,
            userAuth,
            setUserAuth
        }}>
            {children}
        </AuthContext.Provider>
    )
}





 /*function AuthProvider({ children }) {
        const [authenticated, setAuthenticated] = useState(false);
        
        
        useEffect(() => {
                const getLogin = async () => {
                    const cookies = new Cookies();
                    const token = cookies.get('C_token');
                    if(token){
                        api.defaults.headers.Authorization = `Bearer ${(token)}`
                        setAuthenticated(true);
                    };
                }
                getLogin();
        },[]);


           

       

        async function signIn(situacaoUsuario){
            setAuthenticated(true);
        }

        function handleLogout(){
            const cookies = new Cookies();

            setAuthenticated(false);
            //localStorage.removeItem('token');
            cookies.remove('C_token');
            api.defaults.headers.Authorization = undefined;

        }
    

        return (
            <Context.Provider value={{authenticated, signIn, handleLogout}}>
                {children}
            </Context.Provider>
        );
    }

export  {Context , AuthProvider} ;
*/