import  {useContext} from "react";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import { EmbarcacaoContext } from "../../../../Context/EmbarcacaoContext";
import FormEditarMovimentacao from "../../../../components/Forms/Embarcacoes/Movimentacoes/FormEditarMovimentacao";

const PageEditarMovimentacao = () => {

    const dadosMovimentacao = useContext(EmbarcacaoContext)
    const movimentacao = dadosMovimentacao?.dadosEmbarcacao

    return (
        <>
            <Header/>
            <h2>Editar Movimentação</h2>
            <FormEditarMovimentacao
                formType="edit"
                movimentacao={movimentacao}
            />

            <Footer/>
        </>
    )
}

export default PageEditarMovimentacao