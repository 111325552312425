import React, { useContext } from "react";
import { useForm, useFormContext, useFieldArray } from 'react-hook-form';
import styles from "./SubFormEmbsCliente.module.css";
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { validaPlacaVeiculo } from "../../../../services/validation";
import { ClienteContext } from "../../../../Context/ClienteContext";


const SubFormEmbsCliente = (props) => {

    const { formState: { errors } } = useForm();
    const methods = useFormContext();

    const Cliente = useContext(ClienteContext);
    const embsCadastradas = Cliente.dadosCliente.Embarcacoes;

    //console.log('EMBARCACOES = ', embsCadastradas);

    const {
        fields,
        append,
        remove,
    } = useFieldArray({
        //nao colocar control aqui quando estiver usando formContext se nao nao reseta os campos
        name: "embarcacao",
    });

    var isReadyOnly = [0];





    if (embsCadastradas?.length > 0) { //essa condicao garante que o cliente ja seja cadastrado no sistema portanto é edicao e nao adicao 
        //console.log('fields = ', fields);
        isReadyOnly = [1];

        for (let k = 0; k < embsCadastradas.length; k++) {
            if (fields?.length < embsCadastradas?.length) {

                fields.push(embsCadastradas[k]);
            }
        }
    }



    return (
        <>


            {fields.map((item, index) => {
                return (
                    <>
                        {console.log(`cod emb = `, item.id)}
                        <Form.Group key={index}>
                            <Row className="mb-3">
                                <Form.Group key="A" as={Row}>
                                    <Form.Label column lg={3}>
                                        <strong>Código Embarcação: {item?.id}</strong>
                                    </Form.Label>

                                    {embsCadastradas?.length > 0 ? "" :
                                        <Col>
                                            <Button variant="outline-danger" size="sm" type="button" onClick={() => { remove(index) }}>x</Button>
                                        </Col>
                                    }
                                </Form.Group>

                                <Form.Group key="B" as={Col} xs={6}>
                                    <Form.Group as={Row} >
                                        <Form.Label >
                                            Tipo:
                                        </Form.Label>
                                        <Col xs={7}>
                                            <Form.Select  {...methods.register(`embarcacao.${index}.tipoEmbarcacao`, { validate: value => value !== "Selecione", required: true })} defaultValue={item?.tipo} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}>
                                                <option>Selecione</option>
                                                <option>Jet-Ski</option>
                                                <option>Barco Pesca</option>
                                                <option>Lancha</option>
                                            </Form.Select>
                                            {props.erros !== undefined && props.erros[index]?.tipoEmbarcacao?.type === 'validate' && <p className={styles.formError}>Selecione tipo</p>}


                                            {/*props.erros.TipoEmbarcacao__1?.type === 'validate' && <p className={styles.formError}>Tipo inválido</p>*/}

                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group key="C" as={Col} xs={4}>
                                    <Form.Group as={Row} >
                                        <Form.Label sm={4}>
                                            Tamanho (Pés):
                                        </Form.Label>
                                        <Col xs={8}>
                                            <Form.Control  {...methods.register(`embarcacao.${index}.tamanhoEmbarcacao`)} defaultValue={item?.tamanho} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} type="number"></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group key="D" as={Col}>
                                    <Form.Group as={Row} >
                                        <Form.Label sm={1}>
                                            Data Entrada:
                                        </Form.Label>
                                        <Col xs={9}>
                                            <Form.Control  {...methods.register(`embarcacao.${index}.dataEntradaEmb`, { required: true })} defaultValue={item?.dataEntradaEmb?.slice(0, 10)} type="date" readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``}></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                                <Form.Group key="E"as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label sm={2}>
                                            Nome:
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control  {...methods.register(`embarcacao.${index}.nomeEmbarcacao`)} defaultValue={item?.nome} type="text" readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group key="F" as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label sm={2}>
                                            Placa Carreta:
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control  {...methods.register(`embarcacao.${index}.placaCarretaEmb`, { validate: value => validaPlacaVeiculo(value) })} defaultValue={item?.placaCarreta} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                            {props.erros !== undefined && props.erros[index]?.placaCarretaEmb?.type === 'validate' && <p className={styles.formError}>Placa Inválida</p>}
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Motor:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...methods.register(`embarcacao.${index}.motorEmbarcacao`)} defaultValue={item?.motorEmbarcacao} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Modelo Casco:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...methods.register(`embarcacao.${index}.modeloCascoEmb`,)} defaultValue={item?.modeloCascoEmb} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Nº Série Motor:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...methods.register(`embarcacao.${index}.NserieMotorEmb`)} defaultValue={item?.NserieMotorEmb} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Nº Série Casco:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...methods.register(`embarcacao.${index}.NserieCascoEmb`)} defaultValue={item?.NserieCascoEmb} readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label sm={2}>
                                    Valor Mensal:
                                </Form.Label>
                                <Col sm={8}>
                                    <Form.Control  {...methods.register(`embarcacao.${index}.valorMensalEmb`, { required: true })} defaultValue={item?.valorMensalidade} placeholder="R$" readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ``} type="number"></Form.Control>
                                </Col>
                            </Form.Group>
                            <hr />
                        </Form.Group>

                        <Form.Group as={Col}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label sm={2}>
                                            Contrato:
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Select  {...methods.register(`embarcacao.${index}.tipoContrato`, { required: true })} defaultValue={item?.tipoContrato} placeholder="R$" readOnly={isReadyOnly[0]} className={isReadyOnly[0] === 1 ? styles.noEditable : ''} type="number">
                                                <option>Mensal</option>
                                                <option>Avulso</option>
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                    </>
                )
            })
            }

            {props.formType === "edit" ? "" :
                <section key={"2121"}>
                    <Button variant="secondary"
                        type="button"
                        onClick={() => {
                            append({ Modelo: null, Placa: null });
                        }}
                    >
                        + Nova Embarcação
                    </Button>
                    <br />

                </section>
            }




        </>
    )

}

export default SubFormEmbsCliente;