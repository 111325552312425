import React, { useEffect, useState, useContext } from "react";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useForm } from "react-hook-form";
import styles from "./FormEditarVeiculo.module.css";
import AsyncSelect from 'react-select/async';
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from 'react-router-dom';
import requisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import { validaPlacaVeiculo } from "../../../../services/validation";
import { ClienteContext } from "../../../../Context/ClienteContext";
import ModalAviso from "../../../Modals";

const FormEditarVeiculo = (props) => {

    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [proprietarioInfos, setProprietarioInfos] = useState({value: props.dados?.Cliente?.id});
    const navigate = useNavigate();
    const { dadosCliente } = useContext(ClienteContext)
    var isReadOnly = [0];

    const [statusModal, setStatusModal] = useState(false);
    const [modeChangeProprietario, setModeChangeProprietario] = useState(false);

    console.log('dadosCliente dentro FORM = ', dadosCliente);



    if (props.formType === "detalhar") {
        isReadOnly[0] = 1;
    }

    async function pesquisaProprietarios(data) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await requisicaoPost('/listarProprietarios', newData)
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map(item => ({ value: item?.id, label: item?.id + `-` + item?.nome }))
            //proprietariosFormatado.push({ value: 0, label: " Adicionar Novo" })
            console.log('proprietarios formatados = ', proprietariosFormatado);
            return proprietariosFormatado;
        }

    }

    async function atualizarVeiculo(data) {
        console.log('ENTROU ATUALIZA FORM');
        console.log('NOVO PROPRIETARIO = ', proprietarioInfos);

        const formData = data;
        console.log(formData);

        const veiculoId = Number(dadosCliente.id);
        const dadosVeiculo = { veiculoId, proprietario: proprietarioInfos , ...data, antigoProprietarioId: Number(props.dados?.Cliente?.id)}
        console.log('dadosVeiculo = ', dadosVeiculo);

        const requisicao = await requisicaoPost('/editarVeiculo', dadosVeiculo);
        console.log('REQUISICAO = ', requisicao);
        navigate('/veiculosMain');
    }

    var isReadOnly = [1,0,0];

    return (

        <>
            < div className={styles.formArea}>
                <Form onSubmit={handleSubmit(atualizarVeiculo)}>
                    <section>
                        <Form.Group >
                            <Row className="mb-3">

                                {modeChangeProprietario !== true ? 
                                <Form.Group key="A" as={Row}>
                                    <Form.Label column lg={1}>
                                        Proprietário:
                                    </Form.Label>
                                    <Col>
                                        <Form.Control defaultValue={props.dados?.Cliente?.nome} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''}></Form.Control>
                                    </Col>
                                    <Col>
                                    {props.formType === "detalhar" ? "" :
                                        <Button size="sm" variant="outline-secondary" onClick={() => { setModeChangeProprietario(true) }}>Alterar</Button>
                                    }
                                    </Col>
                                </Form.Group>
                                :
                                ""
                                }

                                {modeChangeProprietario === true ?
                                 <Form.Group as={Row}>
                                    <Form.Label><strong>Novo Proprietário:</strong></Form.Label>
                                    <AsyncSelect  placeholder={"Pesquisar Novo Proprietário"} cacheOptions loadOptions={pesquisaProprietarios} defaultOptions onChange={(data) => { setProprietarioInfos(data) }} >
                                    </AsyncSelect>
                                </Form.Group>
                                :
                                ""
                                }
                            </Row>


                            <Form.Group key="F" as={Col}>
                                <Form.Group as={Row}>
                                    <Form.Label sm={2}>
                                        Placa :
                                    </Form.Label>
                                    <Col sm={2}>
                                        <Form.Control  {...register('placa', { validate: value => validaPlacaVeiculo(value) })} defaultValue={props.dados?.placa} readOnly={isReadOnly[1]} className={isReadOnly[1] === 1 ? styles.noEditable : ''} />
                                        {errors !== undefined && errors?.placaCarreta?.type === 'validate' && <p className={styles.formError}>Placa Inválida</p>}
                                    </Col>
                                </Form.Group>
                            </Form.Group>



                            <Form.Group as={Col}>
                                <Form.Group as={Row}>
                                    <Form.Label lg={5}>
                                        Modelo:
                                    </Form.Label>
                                    <Col sm={4}>
                                        <Form.Control  {...register('modelo',)} defaultValue={props.dados?.modelo} readOnly={isReadOnly[2]} className={isReadOnly[2] === 1 ? styles.noEditable : ''} />
                                    </Col>
                                </Form.Group>
                            </Form.Group>
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Group as={Row}>
                                <Form.Label lg={3}>
                                    Status:
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Select  {...register('status')} defaultValue={props.dados?.status} readOnly={isReadOnly[3]} className={isReadOnly[3] === 1 ? styles.noEditable : ''} >
                                        <option>{props?.dados?.status}</option>
                                        <option>Ativo</option>
                                        <option>Inativo</option>
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        {props.typeForm === "editar" ?

                            <Button type="submit" >Salvar</Button>
                            :
                            ""
                        }


                    </section>

                </Form>
            </div>
        </>
    )

}

export default FormEditarVeiculo;