import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PortariaContextProvider } from './Context/PortariaContext';
import { DescidaContextProvider } from './Context/DescidaContext';
import { ClienteContextProvider } from './Context/ClienteContext';
import { EmbarcacaoContextProvider } from './Context/EmbarcacaoContext';
import { AuthContextProvider } from './Context/AuthContext';
import { VendaContextProvider } from './Context/VendaContext';
import { CompraContextProvider } from './Context/CompraContext';

import { BrowserRouter } from 'react-router-dom';
import PortariaRoutes from './routes/portaria.routes';
import DescidasRoutes from './routes/descidas.routes';
import ClientesRoutes from './routes/clientes.routes';
import EmbarcacoesRoutes from './routes/embarcacoes.routes';
import ServicosRoutes from './routes/servicos.routes';
import AuthRoutes from './routes/auth.routes';
import VendasRoutes from './routes/vendas.routes';
import ComprasRoutes from './routes/compras.routes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <BrowserRouter /*basename='/#'*/>


      <AuthContextProvider>
        <AuthRoutes />
        <ClienteContextProvider>
          <ClientesRoutes />

          <PortariaContextProvider>
            <PortariaRoutes />
          </PortariaContextProvider>

          <DescidaContextProvider>
            <DescidasRoutes />
          </DescidaContextProvider>

          <EmbarcacaoContextProvider>
            <EmbarcacoesRoutes>
            </EmbarcacoesRoutes>
          </EmbarcacaoContextProvider>

          <ServicosRoutes></ServicosRoutes>
          
          <VendaContextProvider>
            <VendasRoutes>
            </VendasRoutes>
          </VendaContextProvider>

          <CompraContextProvider>
            <ComprasRoutes></ComprasRoutes>
          </CompraContextProvider>
          

        </ClienteContextProvider>

      </AuthContextProvider>
    </BrowserRouter>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
