import Footer from "../../../components/Footer"
import FormCompra from "../../../components/Forms/Compras/FormCompra"
import FormVenda from "../../../components/Forms/Vendas/FormVenda/FormVenda"
import Header from "../../../components/Header"

const PageNovaCompra = () => {
    return (
        <>
            <Header/>
            <h1>Nova Compra</h1>

            <FormCompra
                formType="compra"               
            />
            <Footer/>
        </>
    )
}

export default PageNovaCompra